import { Button, Flex, Icons } from "@heart/components";
import PropTypes from "prop-types";
import { caseManagementNewForCasePath } from "routes";

import { formatDate } from "@components/intake_portal/helper";
import ReviewLayout from "@components/shared/layout/review_layout/ReviewLayout";

import CaseActivityInvestigations from "./CaseActivityInvestigations";
import { InvestigationProvider } from "./InvestigationContext";
import InvestigationInformation from "./InvestigationInformation";
import ResearchAndFindings from "./ResearchAndFindings";

const ReviewInvestigationPage = ({
  caseData,
  caseActivity,
  currentUser,
  referralId,
  reportTimestamp,
  responseRecommendation,
  assignments,
  safetyConcerns,
  allegations,
  attachments,
}) => {
  const {
    incidentTimestamp,
    intakeRecommendation,
    workerAssigned,
    referralName,
  } = caseData || {};
  const timestamp = incidentTimestamp
    ? formatDate(new Date(incidentTimestamp))
    : reportTimestamp;

  return (
    <InvestigationProvider
      initialData={{
        referralId,
        reportTimestamp: timestamp,
        responseRecommendation: intakeRecommendation || responseRecommendation,
        assignments: {
          ...assignments,
          primaryWorkerAssigned:
            workerAssigned?.label || assignments.primaryWorkerAssigned,
        },
        safetyConcerns,
        allegations,
        attachments,
        ...caseData,
        currentUser,
      }}
    >
      <ReviewLayout
        breadcrumb={[
          {
            href: "/",
            label: "Home",
          },
          {
            href: "/investigations",
            label: "Investigations",
          },
          {
            href: "#",
            label: `Referral ${referralId}`,
          },
        ]}
        title={`${referralName}: (${referralId})`}
        pill={{ text: "In Process" }}
        headerRightAlignedContent={
          <Flex gap="100">
            <Button variant="secondary">Export as PDF</Button>
            <Button
              variant="secondary"
              icon={Icons.Plus}
              href={caseManagementNewForCasePath(referralId)}
            >
              Create a case
            </Button>
            <Button>Change status</Button>
          </Flex>
        }
        tabs={[
          {
            contents: <InvestigationInformation />,
            id: "investigation-information",
            title: "Investigation Information",
          },
          {
            contents: <ResearchAndFindings />,
            id: "research-and-findings",
            title: "Documentation & Findings",
          },
          {
            contents: (
              <CaseActivityInvestigations
                activity={caseActivity}
                referralId={referralId}
              />
            ),
            id: "case-activity",
            title: "Case Activity",
          },
        ]}
      />
    </InvestigationProvider>
  );
};

ReviewInvestigationPage.propTypes = {
  caseData: PropTypes.object,
  caseActivity: PropTypes.array,
  currentUser: PropTypes.object,
  referralId: PropTypes.string.isRequired,
  allegations: PropTypes.array.isRequired,
  reportTimestamp: PropTypes.string.isRequired,
  responseRecommendation: PropTypes.object.isRequired,
  assignments: PropTypes.object.isRequired,
  safetyConcerns: PropTypes.object.isRequired,
  attachments: PropTypes.array.isRequired,
};

export default ReviewInvestigationPage;
