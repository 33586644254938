import { Button, Flex, Surface, Text } from "@heart/components";

import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";
import NewPersonForm from "@components/shared/person/NewPersonForm";
import RelatedCases from "@components/shared/related/Related";
import Relations from "@components/shared/relations/Relations";

import { setBase64SearchParams } from "@lib/base64SearchParams";

import InvestigationInformationCard from "../InvestigationCards/InvestigationInformation";
import WorkerAssignments from "../InvestigationCards/WorkerAssignments";
import { useInvestigation } from "./InvestigationContext";
import styles from "./ReviewInvestigationPage.module.scss";

const links = [
  {
    href: "#investigation-information",
    label: "Intake & Screening Information",
  },
  {
    href: "#worker-assignments",
    label: "Worker Assignments",
  },
  {
    href: "#related-cases",
    label: "Related cases",
  },
  {
    href: "#persons",
    label: "Persons",
  },
  {
    href: "#relations",
    label: "Relations",
  },
];

const InvestigationInformation = () => {
  const { formState, updateFormState, persons } = useInvestigation();

  const handlePersonAdd = ({ personDatabaseId, personId, personData }) => {
    updateFormState({
      ...formState,
      personDatabaseIds: [
        ...(formState.personDatabaseIds || []),
        personDatabaseId,
      ],
      personIds: [...(formState.personIds || []), personId],
      persons: [...(formState.persons || []), personData],
    });
  };

  return (
    <LayoutSidebarSections links={links}>
      <InvestigationInformationCard />
      <WorkerAssignments />
      <Surface title="Persons" className={styles.surfaceContainer}>
        <NewPersonForm
          onPersonAdd={handlePersonAdd}
          formState={formState}
          buttonClass={styles.newItemButton}
          hasNotice
        />
      </Surface>
      <Surface title="Relations" hideTitle className={styles.surfaceContainer}>
        <Relations
          formState={formState}
          updateWindowsLocation="#relations"
          updateFormState={updateFormState}
          buttonClass={styles.newPersonButton}
        />
      </Surface>
      <RelatedCases buttonClass={styles.newItemButton} formState={formState} />
      <Flex justify="end">
        <Button
          variant="primary"
          onClick={() => {
            setBase64SearchParams([
              { attribute: "tab", value: "Documentation & Findings" },
            ]);
            window.scrollTo(0, 0);
          }}
        >
          Next: Documentation & Findings
        </Button>
      </Flex>
    </LayoutSidebarSections>
  );
};

export default InvestigationInformation;
