import * as PropTypes from "prop-types";
import { createContext, useContext } from "react";

import useFormState from "@components/shared/hooks/useFormState";

const PersonContext = createContext();

export const PersonProvider = ({ initialData, children }) => {
  const { formState, updateFormState } = useFormState(initialData);

  return (
    <PersonContext.Provider value={{ formState, updateFormState }}>
      {typeof children === "function"
        ? children({
            formState,
            updateFormState,
          })
        : children}
    </PersonContext.Provider>
  );
};

PersonProvider.propTypes = {
  initialData: PropTypes.object.required,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export const usePersonInformation = () => {
  const context = useContext(PersonContext);

  if (!context)
    throw Error(
      "The usePersonInformation hook should be inside the PersonContext"
    );

  return context;
};
