import { Pill } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";
import Select, { components } from "react-select";

import styles from "./StatusInput.module.scss";

// Default Status options with corresponding Pill variants - Example usage:
const statusOptions = [
  { value: "new", label: "New", variant: "alert" },
  {
    value: "in_supervisor_review",
    label: "In Supervisor Review",
    variant: "warning",
  },
  { value: "in_process", label: "In Process", variant: "info" },
  { value: "processed", label: "Processed", variant: "neutral" },
];

const CustomOption = props => (
  <components.Option {...props}>
    <Pill text={props.label} variant={props.data.variant} />
  </components.Option>
);

const CustomSingleValue = props => (
  <components.SingleValue {...props}>
    <Pill text={props.data.label} variant={props.data.variant} />
  </components.SingleValue>
);

const StatusInput = ({
  title = "Status",
  onChange,
  value,
  description,
  options = statusOptions,
}) => {
  const selectedOption = options.find(option => option.value === value);

  return (
    <div>
      <label className={styles.title}>{title}</label>

      {/* Status Select */}
      <Select
        options={options}
        isSearchable={false}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        onChange={selected => onChange(selected.value)}
        value={selectedOption}
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? "#FFA500" : "#E5E5E5",
            boxShadow: state.isFocused ? "0 0 0 2px #FFA500" : null,
            minHeight: "64px",
            padding: "2px",
          }),
          singleValue: base => ({
            ...base,
            overflow: "visible",
          }),
          dropdownIndicator: base => ({
            ...base,
            color: "#666",
            "&:hover": { color: "#000" },
          }),
          option: base => ({
            ...base,
            padding: "16px 12px",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
        }}
      />

      {description && (
        <div className={styles.description}>
          {typeof description === "string" ? (
            <span>{description}</span>
          ) : (
            description
          )}
        </div>
      )}
    </div>
  );
};

StatusInput.propTypes = {
  title: PropTypes.string.isRequired, // Title of the field
  onChange: PropTypes.func.isRequired, // Function to handle select changes
  value: PropTypes.string.isRequired, // Required value with a default option allowed
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Description can be a string or a component
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      variant: PropTypes.string.isRequired,
    })
  ),
};

export default StatusInput;
