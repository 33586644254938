import debounce from "lodash/debounce";
import { useEffect, useRef, useState } from "react";

export const getCurrentTime = format => {
  const now = new Date();
  const hours = now.getHours() % 12 || 12;
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const ampm = now.getHours() >= 12 ? "PM" : "AM";

  const formattedTime = format
    .replace("hh", hours.toString().padStart(2, "0"))
    .replace("h", hours)
    .replace("mm", minutes)
    .replace("ss", seconds)
    .replace("AM/PM", ampm);

  return formattedTime;
};

// getCurrentTime("hh:mm:ss AM/PM")); // Output: 03:45:12 PM
// getCurrentTime("h:mm AM/PM")); // Output: 3:45 PM
// getCurrentTime("hh:mm:ss")); // Output: 03:45:12
// getCurrentTime("hh:mm")); // Output: 03:45

// Custom hook to get the previous value of a prop or state
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const useAutoSave = formState => {
  const [currentTime, setCurrentTime] = useState(getCurrentTime("h:mm AM/PM"));
  const [pillInfo, setPillInfo] = useState({
    variant: "success",
    text: `Auto Saved ${currentTime}`,
  });

  const debouncedAutosave = debounce(() => {
    setPillInfo({
      variant: "info",
      text: "Saving...",
    });

    setTimeout(() => {
      const cTime = getCurrentTime("h:mm AM/PM");
      setCurrentTime(cTime);
      setPillInfo({
        variant: "success",
        text: `Auto Saved ${cTime}`,
      });
    }, 3000);
  }, 3000);

  useEffect(() => {
    debouncedAutosave();

    return () => {
      debouncedAutosave.cancel();
    };
  }, [formState]);

  return { pillInfo, currentTime };
};

export default useAutoSave;
