import {
  Text,
  Surface,
  Flex,
  Button,
  DetailsTable,
  Avatar,
  If,
} from "@heart/components";
import PropTypes from "prop-types";

import styles from "./ProfileCard.module.scss";

const ProfileCard = ({
  person = {},
  showBanner = false,
  hideMyProfileButton = false,
}) => {
  const { fullName, id, firstName, lastName } = person;
  return (
    <Surface title="Mandated Reporter Profile" hideTitle>
      <Flex column gap="200">
        <Flex justify="space-between" align="center">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Mandated Reporter Profile
          </Text>
          <If condition={!hideMyProfileButton}>
            <Button variant="secondary">View my profile</Button>
          </If>
        </Flex>
        <If condition={showBanner}>
          <Flex column gap="100" className={styles.banner}>
            <Text textStyle="emphasis-200" textColor="neutral-600">
              Success!
            </Text>
            <Text textStyle="supporting-100" textColor="neutral-600">
              Thanks for logging in! Please verify that all your information is
              accurate.
            </Text>
          </Flex>
        </If>
        <Flex align="center">
          <Flex className={styles.infoColumn} align="center">
            <Avatar
              firstName={firstName || "Gabriela"}
              lastName={lastName || "Johnson"}
              size={800}
            />
            <Flex gap="100" column className={styles.fullName}>
              <Text textStyle="emphasis-300" textColor="neutral-600">
                {fullName || "Gabriela Johnson"}
              </Text>
              <Text textStyle="body-200" textColor="neutral-600">
                ID: {id || "P3049203"}
              </Text>
            </Flex>
          </Flex>
          <Flex className={styles.infoColumn}>
            <DetailsTable
              details={[
                {
                  label: "Phone Number",
                  value: "206-435-4935",
                },
                {
                  label: "Occupation",
                  value: "Physician (Harborview Medical Center)",
                },
                {
                  label: "Address",
                  value: "325 9th Avenue Seattle, WA 98104",
                },
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
    </Surface>
  );
};

ProfileCard.propTypes = {
  showBanner: PropTypes.bool,
  hideMyProfileButton: PropTypes.bool,
  person: PropTypes.object,
};

export default ProfileCard;
