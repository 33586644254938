import {
  Surface,
  Text,
  HeartTable,
  Flex,
  Icons,
  Button,
  TableCellList,
  LabeledContent,
} from "@heart/components";
import formatAddress from "@utils/address";

import { usePersonInformation } from "../PersonContext";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const Medications = () => {
  const { formState } = usePersonInformation();

  return (
    <Surface title="Medications" hideTitle sectionId="diagnoses">
      <Flex column gap="200" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Medications
        </Text>
        {formState.prescriptions && formState.prescriptions.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>Medication Name</Th>
                <Th>Details</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {formState.prescriptions.map((prescription, i) => (
                <Tr key={`prescription-${i}`}>
                  <Td>{prescription.medicationName}</Td>
                  <Td>
                    <TableCellList
                      items={[
                        <LabeledContent
                          key="type"
                          label="Prescription or Over the Counter"
                          content={prescription.type}
                        />,
                        <LabeledContent
                          key="dosage"
                          label="Dosage"
                          content={prescription.dosage}
                        />,
                        <LabeledContent
                          key="provider"
                          label="Prescribing Provider"
                          content={prescription.providerName}
                        />,
                        <LabeledContent
                          key="dates"
                          label="Dates"
                          content={`${prescription.startDate}/${prescription.endDate}`}
                        />,
                        <LabeledContent
                          key="pharmacy"
                          label="Pharmacy Name"
                          content={prescription.pharmacyName}
                        />,
                        <LabeledContent
                          key="address"
                          label="Pharmacy Address"
                          content={
                            prescription.pharmacyAddress
                              ? formatAddress(prescription.pharmacyAddress)
                              : ""
                          }
                        />,
                        <LabeledContent
                          key="phone"
                          label="Pharmacy Phone"
                          content={prescription.pharmacyPhone}
                        />,
                        <LabeledContent
                          key="notes"
                          label="Notes"
                          content={prescription.notes}
                        />,
                      ]}
                    />
                  </Td>
                  <Td alignRight>
                    <Flex justify="end">
                      <Icons.Edit onClick={() => {}} />
                      <Icons.Trash onClick={() => {}} />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : null}
        <Button>Add Medications</Button>
      </Flex>
    </Surface>
  );
};

export default Medications;
