import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";

import useFormState from "@components/shared/hooks/useFormState";

const CaseReviewContext = createContext();

export const useCaseReview = () => useContext(CaseReviewContext);

export const CaseReviewProvider = ({ children, initialData }) => {
  const [currentUser, setCurrentUser] = useState(initialData.currentUser);
  const [referralId, setReferralId] = useState(initialData.referralId);
  const [caseData, setCaseData] = useState(initialData.caseData);
  const [caseActivity, setCaseActivity] = useState(initialData.caseActivity);

  const { formState, updateFormState } = useFormState({
    ...initialData.caseData,
  });

  return (
    <CaseReviewContext.Provider
      value={{
        formState,
        updateFormState,
        currentUser,
        setCurrentUser,
        referralId,
        setReferralId,
        caseData,
        setCaseData,
        caseActivity,
        setCaseActivity,
      }}
    >
      {children}
    </CaseReviewContext.Provider>
  );
};

CaseReviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialData: PropTypes.object,
};
