import { Surface, Flex, Text, If } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./Report.module.scss";

const getStepStatus = (currentStep, stepNumber) => {
  if (currentStep > stepNumber) return "completed";
  if (currentStep === stepNumber) return "active";
  return "idle";
};

const ReportSteps = ({ step }) => (
  <Surface title="Report steps" hideTitle gap="100">
    <div className={styles["steps-block"]} data-status={getStepStatus(step, 1)}>
      <div className={styles["steps-block-header"]}>
        <Flex justify="space-between" align="center">
          <Flex column>
            <Text textStyle="supporting-150" className={styles["step-number"]}>
              Step 1
            </Text>
            <Text textStyle="emphasis-100" className={styles["step-title"]}>
              Tell us about yourself
            </Text>
          </Flex>
          <StepStatusIcon status={getStepStatus(step, 1)} />
        </Flex>
      </div>
      <If condition={step === 1}>
        <div className={styles["steps-detailed-container"]}>
          <Flex column gap="200">
            <Text textStyle="emphasis-100" className={styles["detailed-step"]}>
              Contact Information
            </Text>
          </Flex>
        </div>
      </If>
    </div>
    <div className={styles["steps-block"]} data-status={getStepStatus(step, 2)}>
      <div className={styles["steps-block-header"]}>
        <Flex justify="space-between" align="center">
          <Flex column>
            <Text textStyle="supporting-150" className={styles["step-number"]}>
              Step 2
            </Text>
            <Text textStyle="emphasis-100" className={styles["step-title"]}>
              Incident information
            </Text>
          </Flex>
          <StepStatusIcon status={getStepStatus(step, 2)} />
        </Flex>
      </div>
      <If condition={step === 2}>
        <div className={styles["steps-detailed-container"]}>
          <Flex column gap="200">
            <Text
              as="a"
              textStyle="emphasis-100"
              className={styles["detailed-step"]}
              href="#persons-involved"
            >
              Persons
            </Text>
            <Text
              as="a"
              href="#incident-information"
              textStyle="emphasis-100"
              className={styles["detailed-step"]}
            >
              Incident Information
            </Text>
            <Text
              as="a"
              href="#attachments"
              textStyle="emphasis-100"
              className={styles["detailed-step"]}
            >
              Attachments
            </Text>
            <Text
              as="a"
              href="#work-safety-concerns"
              textStyle="emphasis-100"
              className={styles["detailed-step"]}
            >
              Worker Safety Concerns
            </Text>
          </Flex>
        </div>
      </If>
    </div>
    <div className={styles["steps-block"]} data-status={getStepStatus(step, 3)}>
      <div className={styles["steps-block-header"]}>
        <Flex justify="space-between" align="center">
          <Flex column>
            <Text textStyle="supporting-150" className={styles["step-number"]}>
              Step 3
            </Text>
            <Text textStyle="emphasis-100" className={styles["step-title"]}>
              Review and Submit
            </Text>
          </Flex>
          <StepStatusIcon status={getStepStatus(step, 3)} />
        </Flex>
      </div>
    </div>
  </Surface>
);

const StepStatusIcon = ({ status = "idle" }) => {
  if (status === "completed") {
    return (
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16.3333" cy="16.5" r="16" fill="#41991B" />
        <path
          d="M10.5 17.3335L13.8333 20.6668L22.1667 12.3335"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.3333"
        cy="16.5"
        r="15"
        stroke={status === "active" ? "#0055E3" : "#C5C6CB"}
        strokeWidth="2"
      />
      <circle
        cx="16.3333"
        cy="16.5"
        r="5"
        fill={status === "active" ? "#0055E3" : "#DCDDE1"}
      />
    </svg>
  );
};

StepStatusIcon.propTypes = {
  status: PropTypes.oneOf(["idle", "inactive", "completed"]),
};

ReportSteps.propTypes = {
  step: PropTypes.number.isRequired,
};

export default ReportSteps;
