import { Avatar, Flex, Icons, Pill, Text, If } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./WorkerCard.module.scss";

const WorkerCard = ({
  firstName,
  lastName,
  address,
  phoneNumber,
  pills,
  showEye = false,
  isFeatured = false,
}) => (
  <Flex
    column
    gap="200"
    fullWidth
    className={styles.workerCard}
    data-isfeatured={isFeatured}
    data-haspills={pills && pills.length > 0}
    justify="center"
  >
    <If condition={(pills && pills.length > 0) || showEye}>
      <Flex justify="space-between" align="center">
        <If condition={pills && pills.length > 0}>
          <Flex gap="100" wrap>
            {pills.map(pill => (
              <Pill
                key={pill.variant + pill.label}
                text={pill.label}
                variant={pill.variant}
              />
            ))}
          </Flex>
        </If>
        <If condition={showEye}>
          <Icons.Eye />
        </If>
      </Flex>
    </If>
    <Flex gap="300">
      <Avatar firstName={firstName} lastName={lastName} size={400} />
      <Flex column grow>
        <Text textStyle="emphasis-300" textColor="neutral-600">
          {firstName} {lastName}
        </Text>
        <Text textStyle="body-100" textColor="neutral-600">
          {address}
        </Text>
        <Text textStyle="body-100" textColor="neutral-600">
          <Icons.Phone st />
          &nbsp;
          {phoneNumber}
        </Text>
      </Flex>
    </Flex>
  </Flex>
);

WorkerCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  pills: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  showEye: PropTypes.bool,
  isFeatured: PropTypes.bool,
};

export default WorkerCard;
