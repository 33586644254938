import { Button, Flex, Pill, Text, toast } from "@heart/components";
import SurfaceBase from "@heart/components/surface/SurfaceBase";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Fragment, useCallback, useEffect, useState } from "react";

import styles from "./ActivityInformation.module.scss";
import TranscriptionRecorder from "./TranscriptionRecorder";
import recordingIcon from "./recording.svg";

const RecordingCard = ({ onNewTranscription, recording, referralId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState("Record");
  const [transcriptionRecorder, setTranscriptionRecorder] = useState(null);

  const startRecording = useCallback(async () => {
    if (!TranscriptionRecorder.supportsAudioRecording()) {
      toast.negative("Your browser does not support audio recording");
      return;
    }

    if (transcriptionRecorder) {
      await transcriptionRecorder.stop();
    }

    setTranscriptionRecorder(await TranscriptionRecorder.createRecorder());
  }, [transcriptionRecorder]);

  const stopRecording = useCallback(async () => {
    setSubmitting(true);
    const formData = new FormData();
    const file = await transcriptionRecorder.stop();
    formData.append("file", file);
    const response = await fetch(`/case-activity/transcribe/${referralId}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });
    setSubmitting(false);

    const { text, attachment_id: attachmentId } = await response.json();

    onNewTranscription({
      text,
      attachmentId,
      recording: URL.createObjectURL(file),
    });

    setTranscriptionRecorder(null);
  }, [transcriptionRecorder, referralId, onNewTranscription]);

  // stop recording when component unmounts
  useEffect(
    () => async () => {
      await transcriptionRecorder?.stop();
    },
    [transcriptionRecorder]
  );

  useEffect(() => {
    if (recording) {
      setRecordingStatus("Recording Stopped");
    }
  }, [recording]);

  const recordingLabel = (() => {
    switch (recordingStatus) {
      case "Recording...":
        return "Stop Recording";
      case "Recording Stopped":
        return "Listen to Recording";
      case "Record":
      default:
        return "Start Recording";
    }
  })();

  const onRecordClick = async () => {
    switch (recordingStatus) {
      case "Recording...":
        await stopRecording();
        setRecordingStatus("Recording Stopped");
        break;
      case "Record":
      default:
        await startRecording();
        setRecordingStatus("Recording...");
        break;
    }
  };

  return (
    <SurfaceBase
      title="recordingCard"
      hideTitle
      className={styles.recordingTileContainer}
    >
      <Flex column gap="300">
        <div style={{ width: "30%" }}>
          <Pill
            text={
              <Fragment>
                <img
                  src={recordingIcon}
                  alt=""
                  className={classNames(styles.recordingIcon, {
                    [styles.recordingIconActive]:
                      recordingStatus === "Recording...",
                  })}
                />
                <Text textColor="danger-500">{recordingStatus}</Text>
              </Fragment>
            }
            variant="neutral"
          />
        </div>
        <If condition={recording}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio controls src={recording} />
        </If>
        <If condition={recordingStatus === "Record"}>
          <Text textColor="neutral-600" textStyle="emphasis-200">
            Click to record
          </Text>
        </If>
        <Flex mobileColumn row gap="100">
          <Button
            variant="secondary"
            onClick={onRecordClick}
            disabled={recordingStatus === "Recording Stopped"}
            submitting={submitting}
          >
            {recordingLabel}
          </Button>
          <Button
            variant="tertiary"
            onClick={e => {
              e.preventDefault();
              document
                .getElementById("recordingTranscript")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            View Live Transcript
          </Button>
        </Flex>
      </Flex>
    </SurfaceBase>
  );
};

RecordingCard.propTypes = {
  onNewTranscription: PropTypes.func,
  recording: PropTypes.object,
  referralId: PropTypes.string.isRequired,
};

export default RecordingCard;
