export const faceToFaceActivityOptions = [
  { label: "Court Hearing", value: "court_hearing" },
  { label: "Supervisor Meeting", value: "supervisor_meeting" },
  {
    label: "Child-Family-Team/Family Partnership Meeting",
    value: "child_family_team_meeting",
  },
  { label: "Provider Meeting/Appointment", value: "provider_meeting" },
  { label: "Other Meeting/Appointment", value: "other_meeting" },
  { label: "Sibling(s) Visitation", value: "siblings_visitation" },
  { label: "Parent(s) Visitation", value: "parents_visitation" },
  { label: "Relative(s) Visitation", value: "relatives_visitation" },
  { label: "Interview", value: "interview" },
  { label: "Worker Visit- to the Child", value: "worker_visit_child" },
  {
    label: "Worker Visit- to the Family of Origin",
    value: "worker_visit_family_origin",
  },
  {
    label: "Worker Visit- to the Placement Family",
    value: "worker_visit_placement_family",
  },
  {
    label: "Other Face-to-Face Interaction",
    value: "other_face_to_face_interaction",
  },
];

export const videoCallActivityOptions = [
  { label: "Court Hearing", value: "court_hearing" },
  { label: "Supervisor Meeting", value: "supervisor_meeting" },
  {
    label: "Child-Family-Team/Family Partnership Meeting",
    value: "child_family_team_meeting",
  },
  { label: "Provider Meeting/Appointment", value: "provider_meeting" },
  { label: "Other Meeting/Appointment", value: "other_meeting" },
  { label: "Other Video Call", value: "other_video_call" },
];

export const methodOfEngagementOptions = [
  { label: "Face-to-Face", value: "face_to_face" },
  { label: "Telephone", value: "telephone" },
  { label: "Video Call", value: "video_call" },
  { label: "Email", value: "email" },
  { label: "Text/SMS", value: "text_sms" },
  { label: "Fax", value: "fax" },
  { label: "Social Media", value: "social_media" },
  { label: "Other", value: "other" },
];

export const locationOptions = [
  {
    label: "Child Welfare Office",
    value: "child_welfare_office",
  },
  {
    label: "Court",
    value: "court",
  },
  {
    label: "In-Home",
    value: "in_home",
  },
  {
    label: "Out of Home",
    options: [
      {
        label: "Foster Home",
        value: "foster_home",
      },
      {
        label: "Group Home",
        value: "group_home",
      },
      {
        label: "Residential Facility",
        value: "residential_facility",
      },
      {
        label: "Detention",
        value: "detention",
      },
      {
        label: "Jail or Prison",
        value: "jail_or_prison",
      },
    ],
  },
  {
    label: "Medical Facility",
    value: "medical_facility",
  },
  {
    label: "School",
    value: "school",
  },
  {
    label: "Therapy or Counselor Center",
    value: "therapy_or_counselor_center",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const medicalScreeningDropDownOptions = [
  {
    label: "Child was diagnosed with a condition(s)",
    value: "diagnosed_condition",
  },
  {
    label: "Exam or assessment conducted and there were no resulting diagnoses",
    value: "no_diagnoses",
  },
  {
    label: "Exam or assessment conducted and awaiting results",
    value: "awaiting_results",
  },
  { label: "No exam or assessment conducted", value: "no_exam" },
];

export const diagnosedConditionOptions = [
  { label: "Intellectual disability", value: "intellectual_disability" },
  { label: "Autism Spectrum", value: "autism_spectrum" },
  {
    label: "Visual impairment and blindness",
    value: "visual_impairment_blindness",
  },
  {
    label: "Hearing impairment and deafness",
    value: "hearing_impairment_deafness",
  },
  {
    label: "Orthopedic Impairment or Other Physical Condition",
    value: "orthopedic_impairment",
  },
  {
    label: "Mental/Emotional disorders",
    value: "mental_emotional_disorders",
  },
  {
    label: "Gender dysphoria",
    value: "gender_dysphoria",
  },
  {
    label: "Serious mental dysphorias (i.e. schizophrenia)",
    value: "serious_mental_dysphorias",
  },
  {
    label: "Developmental delay",
    value: "developmental_delay",
  },
  {
    label: "Developmental disability",
    value: "developmental_disability",
  },
  {
    label: "ADD/ADHD",
    value: "add_adhd",
  },
  {
    label: "Other diagnosed conditions",
    value: "other_diagnosed_conditions",
  },
];

export const intellectualDisabilitiesOptions = [
  {
    label: "Mild intellectual disabilities",
    value: "mild_intellectual_disabilities",
  },
  {
    label: "Moderate intellectual disabilities",
    value: "moderate_intellectual_disabilities",
  },
  {
    label: "Severe intellectual disabilities",
    value: "severe_intellectual_disabilities",
  },
  {
    label: "Profound intellectual disabilities",
    value: "profound_intellectual_disabilities",
  },
  {
    label: "Other intellectual disabilities",
    value: "other_intellectual_disabilities",
  },
];

export const autismSpectrumOptions = [
  { label: "Autistic Disorder", value: "autistic_disorder" },
  { label: "Rett syndrome", value: "rett_syndrome" },
  {
    label: "Social pragmatic communication disorder",
    value: "social_pragmatic_communication_disorder",
  },
];

export const visualImpairmentBlindnessOptions = [
  { label: "Blindness and low vision", value: "blindness_low_vision" },
  { label: "Visual disturbances", value: "visual_disturbances" },
  {
    label: "Retinal detachment and defects",
    value: "retinal_detachment_defects",
  },
  { label: "Non-senile cataract NOS", value: "non_senile_cataract_nos" },
];

export const hearingImpairmentDeafnessOptions = [
  {
    label: "Conductive and sensorineural hearing loss",
    value: "conductive_sensorineural_hearing_loss",
  },
  { label: "Unspecified hearing loss", value: "unspecified_hearing_loss" },
];

export const orthopedicImpairmentOptions = [
  {
    label: "Diseases of the musculoskeletal system and connective tissue",
    value: "diseases_musculoskeletal_system",
  },
];

export const mentalEmotionalDisordersOptions = [
  {
    label: "Obsessive-compulsive disorders",
    value: "obsessive_compulsive_disorders",
  },
  {
    label: "Post-traumatic stress disorder",
    value: "post_traumatic_stress_disorder",
  },
  {
    label: "Reactive attachment disorder",
    value: "reactive_attachment_disorder",
  },
  {
    label: "Disruptive mood dysregulation disorder",
    value: "disruptive_mood_dysregulation_disorder",
  },
  {
    label: "Oppositional defiant disorder",
    value: "oppositional_defiant_disorder",
  },
  {
    label: "Generalized anxiety disorder",
    value: "generalized_anxiety_disorder",
  },
  {
    label: "Borderline personality disorder",
    value: "borderline_personality_disorder",
  },
  {
    label: "Gender dysphoria in adolescents",
    value: "gender_dysphoria_in_adolescents",
  },
];

export const seriousMentalDysphoriasOptions = [
  { label: "Schizophrenia", value: "schizophrenia" },
  { label: "Schizoaffective disorder", value: "schizoaffective_disorder" },
  {
    label: "Schizotypal personality disorder",
    value: "schizotypal_personality_disorder",
  },
  {
    label: "Bipolar disorder, most recent episode manic",
    value: "bipolar_disorder_manic",
  },
  {
    label: "Bipolar disorder, most recent episode depressed",
    value: "bipolar_disorder_depressed",
  },
  { label: "Major depressive disorder", value: "major_depressive_disorder" },
];

export const developmentalDelayOptions = [
  {
    label: "Specific developmental disorders or speech and language",
    value: "specific_developmental_disorders_speech_language",
  },
  {
    label: "Specific developmental disorders of scholastic skills",
    value: "specific_developmental_disorders_scholastic_skills",
  },
  {
    label: "Specific developmental disorder to motor function",
    value: "specific_developmental_disorder_motor_function",
  },
];

export const addAdhdOptions = [
  {
    label: "ADHD predominately hyperactive type",
    value: "adhd_hyperactive_type",
  },
  {
    label: "ADHD predominately inattentive type",
    value: "adhd_inattentive_type",
  },
];

export const activityValueMappings = {
  court_hearing: "Court Hearing",
  supervisor_meeting: "Supervisor Meeting",
  child_family_team_meeting: "Child-Family-Team/Family Partnership Meeting",
  provider_meeting: "Provider Meeting/Appointment",
  other_meeting: "Other Meeting/Appointment",
  siblings_visitation: "Sibling(s) Visitation",
  parents_visitation: "Parent(s) Visitation",
  relatives_visitation: "Relative(s) Visitation",
  interview: "Interview",
  worker_visit_child: "Worker Visit- to the Child",
  worker_visit_family_origin: "Worker Visit- to the Family of Origin",
  worker_visit_placement_family: "Worker Visit- to the Placement Family",
  other_face_to_face_interaction: "Other Face-to-Face Interaction",
  other_video_call: "Other Video Call",
  face_to_face: "Face-to-Face",
  telephone: "Telephone",
  video_call: "Video Call",
  email: "Email",
  text_sms: "Text/SMS",
  fax: "Fax",
  social_media: "Social Media",
  other: "Other",
  child_welfare_office: "Child Welfare Office",
  court: "Court",
  in_home: "In-Home",
  foster_home: "Foster Home",
  group_home: "Group Home",
  residential_facility: "Residential Facility",
  detention: "Detention",
  jail_or_prison: "Jail or Prison",
  medical_facility: "Medical Facility",
  school: "School",
  therapy_or_counselor_center: "Therapy or Counselor Center",
  diagnosed_condition: "Child was diagnosed with a condition(s)",
  no_diagnoses:
    "Exam or assessment conducted and there were no resulting diagnoses",
  awaiting_results: "Exam or assessment conducted and awaiting results",
  no_exam: "No exam or assessment conducted",
  intellectual_disability: "Intellectual disability",
  autism_spectrum: "Autism Spectrum",
  visual_impairment_blindness: "Visual impairment and blindness",
  hearing_impairment_deafness: "Hearing impairment and deafness",
  orthopedic_impairment: "Orthopedic Impairment or Other Physical Condition",
  mental_emotional_disorders: "Mental/Emotional disorders",
  gender_dysphoria: "Gender dysphoria",
  serious_mental_dysphorias: "Serious mental dysphorias (i.e. schizophrenia)",
  developmental_delay: "Developmental delay",
  developmental_disability: "Developmental disability",
  add_adhd: "ADD/ADHD",
  other_diagnosed_conditions: "Other diagnosed conditions",
  mild_intellectual_disabilities: "Mild intellectual disabilities",
  moderate_intellectual_disabilities: "Moderate intellectual disabilities",
  severe_intellectual_disabilities: "Severe intellectual disabilities",
  profound_intellectual_disabilities: "Profound intellectual disabilities",
  other_intellectual_disabilities: "Other intellectual disabilities",
  autistic_disorder: "Autistic Disorder",
  rett_syndrome: "Rett syndrome",
  social_pragmatic_communication_disorder:
    "Social pragmatic communication disorder",
  blindness_low_vision: "Blindness and low vision",
  visual_disturbances: "Visual disturbances",
  retinal_detachment_defects: "Retinal detachment and defects",
  non_senile_cataract_nos: "Non-senile cataract NOS",
  conductive_sensorineural_hearing_loss:
    "Conductive and sensorineural hearing loss",
  unspecified_hearing_loss: "Unspecified hearing loss",
  diseases_musculoskeletal_system:
    "Diseases of the musculoskeletal system and connective tissue",
  obsessive_compulsive_disorders: "Obsessive-compulsive disorders",
  post_traumatic_stress_disorder: "Post-traumatic stress disorder",
  reactive_attachment_disorder: "Reactive attachment disorder",
  disruptive_mood_dysregulation_disorder:
    "Disruptive mood dysregulation disorder",
  oppositional_defiant_disorder: "Oppositional defiant disorder",
  generalized_anxiety_disorder: "Generalized anxiety disorder",
  borderline_personality_disorder: "Borderline personality disorder",
  gender_dysphoria_in_adolescents: "Gender dysphoria in adolescents",
  schizophrenia: "Schizophrenia",
  schizoaffective_disorder: "Schizoaffective disorder",
  schizotypal_personality_disorder: "Schizotypal personality disorder",
  bipolar_disorder_manic: "Bipolar disorder, most recent episode manic",
  bipolar_disorder_depressed: "Bipolar disorder, most recent episode depressed",
  major_depressive_disorder: "Major depressive disorder",
  specific_developmental_disorders_speech_language:
    "Specific developmental disorders or speech and language",
  specific_developmental_disorders_scholastic_skills:
    "Specific developmental disorders of scholastic skills",
  specific_developmental_disorder_motor_function:
    "Specific developmental disorder to motor function",
  adhd_hyperactive_type: "ADHD predominately hyperactive type",
  adhd_inattentive_type: "ADHD predominately inattentive type",
};

export const eventOptionsMappings = {
  Safety: {
    Safety_Assessment: "Safety Assessment",
    Risk_Assessment: "Risk Assessment",
    Court_Safety: "Court (Safety)",
    Critical_Incident_Review: "Critical Incident Review",
    Interview: "Interview",
    Other: "Other",
  },
  Permanency: {
    Case_Planning: "Case Planning",
    Case_Review: "Case Review",
    Case_Staffing: "Case Staffing",
    Family_Strengths: "Family Strengths and Needs Assessments",
    Family_Reunification: "Family Reunification",
    Reunification_Assessment: "Family Reunification Assessment",
    Permanency_Planning: "Permanency Planning",
    Placement_Stability: "Placement Stability",
    Court_Permanency: "Court (Permanency)",
    Preserving_Connections: "Preserving Connections",
    Transition_Planning: "Transition Planning",
    Tribal_Inquiry: "Tribal Inquiry",
    Other_Permanency: "Other",
  },
  "Well-being": {
    Behavioral_Health: "Behavioral Health",
    Dental: "Dental",
    Education: "Education",
    Medical: "Medical",
    Mental_Health: "Mental Health",
    Service_Referrals: "Service Referrals",
    Other_Wellbeing: "Other",
  },
};
