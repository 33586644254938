import { Modal, Flex, Text, Pill, InputRadioGroup } from "@heart/components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";

import {
  findings as findingsVariants,
  allegationsLabel,
} from "@components/shared/allegations_and_findings/AllegationsAndFindings";

import styles from "./ReviewInvestigationPage.module.scss";

const SupervisorApprovalModal = ({
  onSubmit,
  currentUser,
  open = false,
  onCancel,
  allegationMade,
  finding,
  detailsFinding,
}) => {
  const isSupervisor = currentUser?.roles?.includes("supervisor") ?? false;

  const [approval, setApproval] = useState(null);

  return (
    <Modal
      hidden={!open}
      title="Supervisor Approval - Allegation Finding"
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitDisabled={isSupervisor && approval === null}
      cancelText="Cancel"
      submitText="Submit for Review"
      className={styles.modal}
    >
      <Flex column gap="300">
        <Flex column gap="100">
          <Flex gap="300" align="center">
            <div className={styles.leftContent}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Allegation
              </Text>
            </div>
            <Text
              textStyle="body-100"
              textColor="neutral-600"
              className={styles.rightContent}
            >
              {allegationsLabel[allegationMade]}
            </Text>
          </Flex>
          <Flex gap="300" align="center">
            <div className={styles.leftContent}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Finding
              </Text>
            </div>
            <Flex gap="100" className={styles.rightContent}>
              {finding ? (
                <React.Fragment>
                  <Pill
                    text={findingsVariants[finding]?.label}
                    variant={findingsVariants[finding]?.pillVariant}
                  />
                </React.Fragment>
              ) : null}
            </Flex>
          </Flex>

          <Flex gap="300" align="start">
            <div className={styles.leftContent}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Finding Details
              </Text>
            </div>
            <Text
              textStyle="body-100"
              textColor="neutral-600"
              className={styles.rightContent}
            >
              {detailsFinding}
            </Text>
          </Flex>
          {isSupervisor && (
            <InputRadioGroup
              id="supervisor-response"
              label="Supervisor Response"
              labelStyle="emphasis-100"
              labelColor="neutral-600"
              onChange={value => setApproval(value)}
              required
              value={approval}
              values={[
                {
                  label: "I approve the recommendation",
                  value: true,
                },
                {
                  label: "I do not approve the recommendation",
                  value: false,
                },
              ]}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

SupervisorApprovalModal.propTypes = {
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  finding: PropTypes.string,
  onCancel: PropTypes.func,
  allegationMade: PropTypes.string,
  currentUser: PropTypes.object,
  detailsFinding: PropTypes.string,
};

export default SupervisorApprovalModal;
