import { Surface, Text, Flex, Button, Icons } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./IntakeReview.module.scss";

const CaseActivity = ({ activity }) => (
  <Surface title="Case Activity" hideTitle className={styles.surfaceContainer}>
    {console.log(activity)}
    <Flex align="center" justify="space-between">
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Case Activity
      </Text>
      <Button icon={Icons.Plus} variant="secondary">
        New Case Activity
      </Button>
    </Flex>
  </Surface>
);
CaseActivity.propTypes = {
  referralId: PropTypes.string,
  activity: PropTypes.array,
};

export default CaseActivity;
