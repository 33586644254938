import PropTypes from "prop-types";

const NewPlacement = ({ currentUser }) => (
  <h1>hello {currentUser.fullName}!</h1>
);
NewPlacement.propTypes = {
  currentUser: PropTypes.object,
};

export default NewPlacement;
