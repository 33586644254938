import {
  Flex,
  InputCheckboxGroup,
  InputDate,
  InputFilterable,
  InputPhone,
  InputRadioGroup,
  InputText,
  InputTextarea,
  InputTextWithAddressAutocomplete,
  Text,
} from "@heart/components";
import SurfaceBase from "@heart/components/surface/SurfaceBase";
import React, { useEffect, useState } from "react";

import useSearchParam from "@lib/react-use/useSearchParam";

import { useCaseActivity } from "../CaseActivityContext";
import PROVIDERS from "../providers.json";
import {
  addAdhdOptions,
  autismSpectrumOptions,
  developmentalDelayOptions,
  diagnosedConditionOptions,
  hearingImpairmentDeafnessOptions,
  intellectualDisabilitiesOptions,
  medicalScreeningDropDownOptions,
  mentalEmotionalDisordersOptions,
  orthopedicImpairmentOptions,
  seriousMentalDysphoriasOptions,
  visualImpairmentBlindnessOptions,
} from "./ActivityOptions";
import MedicalAttachments from "./MedicalAttachments";
import NewProviderForm from "./NewProviderForm";

const EventInformation = () => {
  const redirectToInvestigationsCase = useSearchParam("interviewForCase");
  const { formState, updateFormState } = useCaseActivity();
  const [selectedProvider, setSelectedProvider] = useState();

  const [showPrescriptionInfo, setShowPrescriptionInfo] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState(
    redirectToInvestigationsCase
      ? ["Safety", "Interview"]
      : formState.eventInformation || []
  );

  const baseOptions = [
    {
      label: "Safety",
      value: "Safety",
    },
    {
      label: "Permanency",
      value: "Permanency",
    },
    {
      label: "Well-being",
      value: "Well-being",
    },
  ];

  const filteredSelectedOptions = selectedOptions.filter(option =>
    baseOptions.some(baseOption => baseOption.value === option)
  );

  const parentSet = new Set(filteredSelectedOptions);

  const conditionalOptions = {
    Safety: {
      label: (
        <Flex row gap="50">
          <Text textStyle="supporting-50" textColor="neutral-500">
            Please Select One
          </Text>
          <Text textStyle="supporting-50" textColor="danger-500">
            *
          </Text>
        </Flex>
      ),
      options: [
        { label: "Safety Assessment", value: "Safety_Assessment" },
        { label: "Risk Assessment", value: "Risk_Assessment" },
        { label: "Court (Safety)", value: "Court_Safety" },
        {
          label: "Critical Incident Review",
          value: "Critical_Incident_Review",
        },
        { label: "Interview", value: "Interview" },
        { label: "Other", value: "Other" },
      ],
    },

    Permanency: {
      label: (
        <Flex row gap="50">
          <Text textStyle="supporting-50" textColor="neutral-500">
            Please Select One
          </Text>
          <Text textStyle="supporting-50" textColor="danger-500">
            *
          </Text>
        </Flex>
      ),
      options: [
        { label: "Case Planning", value: "Case_Planning" },
        { label: "Case Review", value: "Case_Review" },
        { label: "Case Staffing", value: "Case_Staffing" },
        {
          label: "Family Strengths and Needs Assessments",
          value: "Family_Strengths",
        },
        { label: "Family Reunification", value: "Family_Reunification" },
        {
          label: "Family Reunification Assessment",
          value: "Reunification_Assessment",
        },
        { label: "Permanency Planning", value: "Permanency_Planning" },
        { label: "Placement Stability", value: "Placement_Stability" },
        { label: "Court (Permanency)", value: "Court_Permanency" },
        { label: "Preserving Connections", value: "Preserving_Connections" },
        { label: "Transition Planning", value: "Transition_Planning" },
        { label: "Tribal Inquiry", value: "Tribal_Inquiry" },
        { label: "Other", value: "Other_Permanency" },
      ],
    },

    "Well-being": {
      label: (
        <Flex row gap="50">
          <Text textStyle="supporting-50" textColor="neutral-500">
            Please Select One
          </Text>
          <Text textStyle="supporting-50" textColor="danger-500">
            *
          </Text>
        </Flex>
      ),
      options: [
        { label: "Behavioral Health", value: "Behavioral_Health" },
        { label: "Dental", value: "Dental" },
        { label: "Education", value: "Education" },
        { label: "Medical", value: "Medical" },
        { label: "Mental Health", value: "Mental_Health" },
        { label: "Service Referrals", value: "Service_Referrals" },
        { label: "Other", value: "Other_Wellbeing" },
      ],
    },
  };

  const [eventOptions, setEventOptions] = useState(baseOptions);

  const handleCheckBoxChange = value => {
    const updatedOptions = [...eventOptions];
    setSelectedOptions(value);
    updateFormState({ eventInformation: value });

    if (value.length === 0) {
      updateFormState({
        medicalScreening: {},
        medicalAttachments: [],
        prescriptionForm: {
          address: {
            type: [],
            addressLine1: "",
            countryCode: "US",
            postalCode: "",
            primary: false,
            inactive: false,
            city: "",
            primarySubdivisionCode: "",
            primarySubdivisionName: "",
            secondarySubdivision: "",
          },
        },
      });

      setShowPrescriptionInfo(false);
    }

    const updateOptions = (key, options) => {
      const index = updatedOptions.findIndex(option => option.value === key);
      if (value.includes(key)) {
        if (!parentSet.has(key)) {
          updatedOptions.splice(index + 1, 0, options);
          parentSet.add(key);
        }
      } else if (parentSet.has(key)) {
        updatedOptions.splice(index + 1, 1);
        parentSet.delete(key);
      }
    };

    const removeFormState = () => {
      if (!value.some(condition => screenableConditions.includes(condition))) {
        updateFormState({
          medicalScreening: {},
          prescriptionForm: {
            address: {
              type: [],
              addressLine1: "",
              countryCode: "US",
              postalCode: "",
              primary: false,
              inactive: false,
              city: "",
              primarySubdivisionCode: "",
              primarySubdivisionName: "",
              secondarySubdivision: "",
            },
          },
          medicalAttachments: [],
        });
      }
      setShowPrescriptionInfo(false);
    };

    updateOptions("Safety", conditionalOptions.Safety);
    updateOptions("Permanency", conditionalOptions.Permanency);
    updateOptions("Well-being", conditionalOptions["Well-being"]);

    setEventOptions(updatedOptions);
    removeFormState();
  };

  // on load to open up all suboptions
  useEffect(() => {
    if (redirectToInvestigationsCase) {
      updateFormState({
        ...formState,
        eventInformation: ["Safety", "Interview"],
      });
    }
    const updatedOptions = [...eventOptions];
    const updateOptions = (key, options) => {
      const index = updatedOptions.findIndex(option => option.value === key);
      if (selectedOptions.includes(key)) {
        updatedOptions.splice(index + 1, 0, options);
        parentSet.add(key);
      }
    };
    updateOptions("Safety", conditionalOptions.Safety);
    updateOptions("Permanency", conditionalOptions.Permanency);
    updateOptions("Well-being", conditionalOptions["Well-being"]);

    setEventOptions(updatedOptions);
  }, []);

  const prescriptionForm = () => (
    <Flex column gap={200}>
      {showPrescriptionInfo && (
        <SurfaceBase hideTitle title="prescriptionForm">
          <Flex column gap={100}>
            <InputText
              id="medication-name"
              label="Name of Medication"
              value={formState.prescription?.medicationName || ""}
              onChange={value =>
                updateFormState({
                  prescription: {
                    ...formState.prescription,
                    medicationName: value,
                  },
                })
              }
            />
            <InputFilterable
              labelStyle="emphasis-100"
              id="prescription-type"
              label="Prescription or over-the-counter"
              value={formState.prescription?.prescriptionType || ""}
              onChange={value =>
                updateFormState({
                  prescription: {
                    ...formState.prescription,
                    prescriptionType: value,
                  },
                })
              }
              values={[
                { label: "Prescription", value: "Prescription" },
                { label: "Over-the-counter", value: "Over-the-counter" },
              ]}
            />
            <InputText
              id="dosage"
              label="Dosage"
              value={formState.prescription?.dosage || ""}
              onChange={value =>
                updateFormState({
                  prescription: {
                    ...formState.prescription,
                    dosage: value,
                  },
                })
              }
            />
            <Flex mobileColumn row gap={300}>
              <InputDate
                id="start-date"
                label="Start Date"
                value={formState.prescription?.startDate || ""}
                onChange={date =>
                  updateFormState({
                    prescription: {
                      ...formState.prescription,
                      startDate: date,
                    },
                  })
                }
              />
              <InputDate
                id="end-date"
                label="End Date"
                value={formState.prescription?.endDate || ""}
                onChange={date =>
                  updateFormState({
                    prescription: {
                      ...formState.prescription,
                      endDate: date,
                    },
                  })
                }
                minDate={formState.prescription?.startDate}
                disabled={!formState.prescription?.startDate}
              />
            </Flex>
            <InputText
              id="pharmacy-name"
              label="Pharmacy Name"
              value={formState.prescription?.pharmacyName || ""}
              onChange={value =>
                updateFormState({
                  prescription: {
                    ...formState.prescription,
                    pharmacyName: value,
                  },
                })
              }
            />
            <InputPhone
              id="pharmacy-phone"
              label="Pharmacy Phone Number"
              value={formState.prescription?.pharmacyPhone || ""}
              onChange={value =>
                updateFormState({
                  prescription: {
                    ...formState.prescription,
                    pharmacyPhone: value,
                  },
                })
              }
            />
            <InputTextWithAddressAutocomplete
              id="pharmacy-address"
              label="Pharmacy Address"
              type="text"
              name={"addressLine1"}
              value={[
                formState.prescriptionForm.address.addressLine1,
                formState.prescriptionForm.address.city,
                formState.prescriptionForm.address.primarySubdivisionCode,
                formState.prescriptionForm.address.postalCode,
              ]
                .filter(n => n)
                .join(" ")}
              onAddressPicked={pickedAddress =>
                updateFields({
                  ...formState.prescriptionForm.address,
                  ...pickedAddress,
                })
              }
              autocompleteEnabled={true}
              onChange={val => updateFields(val)}
            />
            <InputTextarea
              id="medication-notes"
              label="Notes about Medication"
              value={formState.prescription?.medicationNotes || ""}
              onChange={value =>
                updateFormState({
                  prescription: {
                    ...formState.prescription,
                    medicationNotes: value,
                  },
                })
              }
            />
          </Flex>
        </SurfaceBase>
      )}
    </Flex>
  );

  const onDiagnosisChange = value => {
    updateFormState({
      medicalScreening: {
        ...formState.medicalScreening,
        diagnosis: value,
      },
    });
  };

  const onMedicalScreeningChange = value => {
    updateFormState({
      medicalScreening: {
        ...formState.medicalScreening,
        medicalScreening: value,
      },
    });
  };

  const onPrescriptionChange = value => {
    setShowPrescriptionInfo(value === "Yes");
    updateFormState({
      medicalScreening: {
        ...formState.medicalScreening,
        prescription: value,
      },
    });
  };

  const handleStartDateChange = date => {
    updateFormState({
      medicalScreening: {
        ...formState.medicalScreening,
        diagnosisStartDate: date,
      },
    });
  };

  const handleEndDateChange = date => {
    updateFormState({
      medicalScreening: {
        ...formState.medicalScreening,
        diagnosisEndDate: date,
      },
    });
  };

  const updateFields = updates => {
    updateFormState({
      prescriptionForm: {
        ...formState.prescriptionForm,
        address: { ...formState.prescriptionForm.address, ...updates },
      },
    });
  };

  const renderInputFilterable = key => {
    const config = inputFilterableConfig[key];
    if (!config) return null;

    return (
      <InputFilterable
        id={config.id}
        label={
          <React.Fragment>
            <Text textStyle="supporting-100" textColor="neutral-600">
              {config.label}
            </Text>
          </React.Fragment>
        }
        value={formState.medicalScreening?.[key] || ""}
        onChange={value =>
          updateFormState({
            medicalScreening: {
              ...formState.medicalScreening,
              [key]: value,
            },
          })
        }
        values={config.values}
      />
    );
  };

  const screenableConditions = [
    "Behavioral_Health",
    "Mental_Health",
    "Dental",
    "Medical",
  ];

  const hasMedicalConditions = formState.eventInformation.some(condition =>
    screenableConditions.includes(condition)
  );

  return (
    <SurfaceBase hideTitle title="eventInformation">
      <Flex column gap={200}>
        <InputCheckboxGroup
          id="event-information"
          selectAll={{ type: "clearAll" }}
          label="What was the event about?"
          onChange={handleCheckBoxChange}
          value={selectedOptions}
          required
          values={eventOptions}
        />
        {formState.eventInformation.includes("Medical") && (
          <Flex column gap={200}>
            <InputRadioGroup
              labelStyle="emphasis-100"
              orientation="row"
              id={"medical-screening"}
              label={"Was this a medical initial screening?"}
              onChange={onMedicalScreeningChange}
              required
              value={formState.medicalScreening?.medicalScreening || ""}
              values={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
            />
          </Flex>
        )}

        <If condition={hasMedicalConditions}>
          <InputFilterable
            id="providers"
            label={
              <React.Fragment>
                <Text textStyle="supporting-100" textColor="neutral-600">
                  Search Providers or Add New
                </Text>
              </React.Fragment>
            }
            name="providers"
            value={selectedProvider}
            onChange={setSelectedProvider}
            values={[
              {
                label: "Add New Provider",
                value: "add",
              },
              ...PROVIDERS.map(prov => ({
                label: prov.name,
                value: prov.id,
              })),
            ]}
          />

          <If condition={selectedProvider}>
            <NewProviderForm
              selectedProvider={
                selectedProvider.value !== "add" ? selectedProvider.value : null
              }
            />
          </If>
        </If>

        {hasMedicalConditions && (
          <Flex column gap={200}>
            <InputFilterable
              id={"medical-details"}
              label={
                <React.Fragment>
                  <Text textStyle="supporting-100" textColor="neutral-600">
                    Screened for Diagnosis?
                  </Text>
                </React.Fragment>
              }
              required
              value={formState.medicalScreening?.diagnosis || ""}
              onChange={onDiagnosisChange}
              values={medicalScreeningDropDownOptions}
            />

            {formState.medicalScreening?.diagnosis?.value ===
              "diagnosed_condition" && (
              <InputFilterable
                id={"diagnosed-condition"}
                label={
                  <React.Fragment>
                    <Text textStyle="supporting-100" textColor="neutral-600">
                      Name of Diagnosis
                    </Text>
                  </React.Fragment>
                }
                required
                value={formState.medicalScreening?.diagnosedCondition || ""}
                onChange={value =>
                  updateFormState({
                    medicalScreening: {
                      ...formState.medicalScreening,
                      diagnosedCondition: value,
                    },
                  })
                }
                values={diagnosedConditionOptions}
              />
            )}

            {formState.medicalScreening?.diagnosedCondition?.value ===
              "intellectual_disability" &&
              renderInputFilterable("intellectual_disability")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "autism_spectrum" && renderInputFilterable("autism_spectrum")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "visual_impairment_blindness" &&
              renderInputFilterable("visual_impairment_blindness")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "hearing_impairment_deafness" &&
              renderInputFilterable("hearing_impairment_deafness")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "orthopedic_impairment" &&
              renderInputFilterable("orthopedic_impairment")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "mental_emotional_disorders" &&
              renderInputFilterable("mental_emotional_disorders")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "serious_mental_dysphorias" &&
              renderInputFilterable("serious_mental_dysphorias")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "developmental_delay" &&
              renderInputFilterable("developmental_delay")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "add_adhd" && renderInputFilterable("add_adhd")}
            {formState.medicalScreening?.diagnosedCondition?.value ===
              "other_diagnosed_conditions" &&
              renderInputFilterable("other_diagnosed_conditions")}

            {formState.medicalScreening?.diagnosedCondition?.value ===
              "other_diagnosed_conditions" && (
              <InputTextarea
                name="other-diagnosed-conditions"
                label="Enter name of diagnosed condition"
                required
                onChange={value =>
                  updateFormState({
                    medicalScreening: {
                      ...formState.medicalScreening,
                      otherDiagnosedConditions: value,
                    },
                  })
                }
              />
            )}

            {formState.medicalScreening?.diagnosis?.value ===
              "diagnosed_condition" && (
              <Flex mobileColumn row gap={300}>
                <InputDate
                  value={formState.medicalScreening?.diagnosisStartDate}
                  required
                  label="Start Date of Diagnosis"
                  name="start-date-diagnosis"
                  onChange={handleStartDateChange}
                />
                <InputDate
                  value={formState.medicalScreening?.diagnosisEndDate}
                  label="End Date of Diagnosis"
                  name="diagnosisDate"
                  minDate={formState.medicalScreening?.diagnosisStartDate}
                  disabled={!formState.medicalScreening?.diagnosisStartDate}
                  onChange={handleEndDateChange}
                />
              </Flex>
            )}

            {formState.medicalScreening?.diagnosis?.value ===
              "diagnosed_condition" && (
              <MedicalAttachments
                formState={formState}
                updateFormState={updateFormState}
              />
            )}

            <InputRadioGroup
              labelStyle="emphasis-100"
              orientation="row"
              id={"medical-screening-prescription"}
              label={"Were any medications prescribed?"}
              onChange={onPrescriptionChange}
              required
              value={formState.medicalScreening?.prescription || ""}
              values={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
            />
            {prescriptionForm()}
          </Flex>
        )}
      </Flex>
    </SurfaceBase>
  );
};

export default EventInformation;

export const inputFilterableConfig = {
  intellectual_disability: {
    id: "intellectual-disability",
    label: "DSM Diagnosis for Intellectual Disability",
    values: intellectualDisabilitiesOptions,
  },
  autism_spectrum: {
    id: "autism-spectrum",
    label: "DSM Diagnosis for Autism Spectrum",
    values: autismSpectrumOptions,
  },
  visual_impairment_blindness: {
    id: "visual-impairment-blindness",
    label: "DSM Diagnosis for Visual Impairment and Blindness",
    values: visualImpairmentBlindnessOptions,
  },
  hearing_impairment_deafness: {
    id: "hearing-impairment-deafness",
    label: "DSM Diagnosis for Hearing Impairment and Deafness",
    values: hearingImpairmentDeafnessOptions,
  },
  orthopedic_impairment: {
    id: "orthopedic-impairment",
    label:
      "DSM Diagnosis for Orthopedic Impairment or Other Physical Condition",
    values: orthopedicImpairmentOptions,
  },
  mental_emotional_disorders: {
    id: "mental-emotional-disorders",
    label: "DSM Diagnosis for Mental/Emotional disorders",
    values: mentalEmotionalDisordersOptions,
  },
  serious_mental_dysphorias: {
    id: "serious-mental-dysphorias",
    label: "DSM Diagnosis for Serious mental dysphorias",
    values: seriousMentalDysphoriasOptions,
  },
  developmental_delay: {
    id: "developmental-delay",
    label: "DSM Diagnosis for Developmental delay",
    values: developmentalDelayOptions,
  },
  add_adhd: {
    id: "add-adhd",
    label: "DSM Diagnosis for ADD/ADHD",
    values: addAdhdOptions,
  },
};
