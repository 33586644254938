import PropTypes from "prop-types";

import CreateDashboardLayout from "@components/shared/layout/create_dashboard_layout/CreateDashboardLayout";

const CaseManagementPage = ({ cases }) => {
  console.log(cases);
  return (
    <CreateDashboardLayout
      pageTitle={"Case Management"}
      breadcrumb={[
        {
          href: "/",
          label: "Home",
        },
        {
          href: "/case-management",
          label: "Case Management",
        },
      ]}
    ></CreateDashboardLayout>
  );
};
CaseManagementPage.propTypes = {
  cases: PropTypes.array.isRequired,
};

export default CaseManagementPage;
