import { Flex } from "@heart/components";
import * as classNames from "classnames";
import { uniqueId } from "lodash";
import noScroll from "no-scroll";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

import styles from "./ModalWrapper.module.scss";

const ModalWrapper = ({
  children,
  hidden,
  isAlert = false,
  onCancel,
  className = "",
  "data-testid": testId,
  ...wrapperProps
}) => {
  const generatedId = uniqueId("modal");

  return (
    <ReactModal
      isOpen={!hidden}
      className={styles.reactModal}
      overlayClassName={styles.modalOverlay}
      onAfterOpen={noScroll.on}
      onAfterClose={noScroll.off}
      onRequestClose={onCancel}
      role={isAlert ? "alertdialog" : "dialog"}
      aria={{ labelledby: `modal-header-${generatedId}` }}
    >
      <Flex
        row
        justify="center"
        align="center"
        className={styles.modalContainer}
        data-testid={testId}
        data-heart-component="Modal"
        {...wrapperProps}
      >
        <div className={classNames(styles.modal, className)}>{children}</div>
      </Flex>
    </ReactModal>
  );
};

ModalWrapper.propTypes = {
  /** Modal contents */
  children: PropTypes.node.isRequired,
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** Whether the modal is an alert, as opposed to informational or
   * interactive
   */

  isAlert: PropTypes.bool,
  /** Additional classes to customize the appearance of this Modal. */
  className: PropTypes.string,
  /** Action to take when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default ModalWrapper;
