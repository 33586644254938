import PropTypes from "prop-types";

import styles from "./StickyBottomBar.module.scss";

const StickyBottomBar = ({ children }) => (
  <div className={styles.bar}>{children}</div>
);

StickyBottomBar.propTypes = {
  children: PropTypes.node,
};

export default StickyBottomBar;
