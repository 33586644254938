import {
  Flex,
  Text,
  InputTextarea,
  Surface,
  InputFilterable,
} from "@heart/components";
import React from "react";

const WorkerSafetyConcern = ({ formState, updateFormState }) => {
  const handleConcernType = selectedConcerns => {
    updateFormState({
      workerSafety: {
        ...formState.workerSafety,
        concernType: selectedConcerns,
      },
    });
  };

  const handleComments = event => {
    updateFormState({
      workerSafety: {
        ...formState.workerSafety,
        comments: event,
      },
    });
  };

  return (
    <Surface
      title="Worker Safety Concerns"
      hideTitle
      sectionId="work-safety-concerns"
    >
      <Flex gap="300" column>
        <Flex gap="100" column>
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Worker Safety Concerns
          </Text>

          <Text textStyle="supporting-150" textColor="neutral-500">
            Please indicate safety issues in the event that workers will be on
            site
          </Text>
        </Flex>

        <InputFilterable
          id="concernType"
          label={
            <>
              <Text textStyle="supporting-100" textColor="neutral-600">
                Concern Type
              </Text>
              <Text textStyle="supporting-100" textColor="danger-500">
                *
              </Text>
            </>
          }
          name="concernType"
          description={
            <Text textStyle="supporting-50" textColor="neutral-600">
              Please select the most relevant categories
            </Text>
          }
          isMulti
          showSelectAll
          value={formState.workerSafety.concernType}
          onChange={handleConcernType}
          values={[
            { label: "Weapons", value: "weapons" },
            { label: "Animals", value: "animals" },
            {
              label: "Violence or Aggression",
              value: "violence-or-aggression",
            },
            {
              label: "Environmental hazards",
              value: "environmental-hazards",
            },
          ]}
        />
        <InputTextarea
          label={
            <Text textStyle="supporting-100" textColor="neutral-600">
              Comments
            </Text>
          }
          name="safetyComments"
          value={formState.workerSafety.comments}
          onChange={handleComments}
        />
      </Flex>
    </Surface>
  );
};

export default WorkerSafetyConcern;
