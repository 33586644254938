import {
  Flex,
  InputFilterable,
  InputPhone,
  InputText,
  InputTextWithAddressAutocomplete,
  Surface,
  If,
} from "@heart/components";
import * as PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useCaseActivity } from "../CaseActivityContext";
import PROVIDERS from "../providers.json";

const PROVIDER_TYPE_OPTIONS = {
  "medical-doctor": "Medical Doctor",
  "other-medical": "Other Medical",
  dentist: "Dentist",
  "pediatric-dentist": "Pediatric Dentist",
  orthodontist: "Orthodontist",
  periodontist: "Periodontist",
  "other-dental": "Other Dental",
  "therapist-psychologist": "Therapist/Psychologist",
  psychiatrist: "Psychiatrist",
  "other-mental": "Other Behavioral/Mental Health",
};

const PROVIDER_TYPE_GROUPS = [
  {
    label: "Medical",
    options: [
      {
        value: "medical-doctor",
        label: PROVIDER_TYPE_OPTIONS["medical-doctor"],
      },
      { value: "other-medical", label: PROVIDER_TYPE_OPTIONS["other-medical"] },
    ],
  },
  {
    label: "Dental",
    options: [
      { value: "dentist", label: PROVIDER_TYPE_OPTIONS.dentist },
      {
        value: "pediatric-dentist",
        label: PROVIDER_TYPE_OPTIONS["pediatric-dentist"],
      },
      { value: "orthodontist", label: PROVIDER_TYPE_OPTIONS.orthodontist },
      { value: "periodontist", label: PROVIDER_TYPE_OPTIONS.periodontist },
      { value: "other-dental", label: PROVIDER_TYPE_OPTIONS["other-dental"] },
    ],
  },
  {
    label: "Behavioral/Mental Health",
    options: [
      {
        value: "therapist-psychologist",
        label: PROVIDER_TYPE_OPTIONS["therapist-psychologist"],
      },
      { value: "psychiatrist", label: PROVIDER_TYPE_OPTIONS.psychiatrist },
      { value: "other-mental", label: PROVIDER_TYPE_OPTIONS["other-mental"] },
    ],
  },
];

const NewProviderForm = ({ selectedProvider }) => {
  const { formState, updateFormState } = useCaseActivity();
  const provider = selectedProvider
    ? PROVIDERS.find(p => p.id === selectedProvider)
    : null;

  const [providerType, setProviderType] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    if (!provider) {
      setProviderType(null);

      updateFormState({ provider: null });
    } else {
      setProviderType({
        label: PROVIDER_TYPE_OPTIONS[provider.providerType],
        value: provider.providerType,
      });

      handleChangeProvider(provider);
    }
  }, [provider]);

  const handleChangeProvider = newProvider => {
    updateFormState({
      provider: {
        ...formState.provider,
        ...newProvider,
      },
    });
  };

  return (
    <Surface title="New Provider" hideTitle>
      <Flex column gap="100">
        <InputText
          name="name"
          required
          label="Name"
          value={provider?.name}
          disabled={provider}
          onChange={name => handleChangeProvider({ name })}
        />
        <InputPhone
          label="Phone number"
          name="phoneNumber"
          value={provider?.phoneNumber}
          disabled={provider}
          onChange={phone => handleChangeProvider({ phone })}
        />
        <InputText
          label="Email"
          name="email"
          type="email"
          value={provider?.email || ""}
          disabled={provider}
          onChange={email => handleChangeProvider({ email })}
        />
        <InputTextWithAddressAutocomplete
          id="address"
          label="Address"
          type="text"
          name="addressLine1"
          value={
            provider
              ? provider.address
              : [
                  address?.addressLine1,
                  address?.city,
                  address?.primarySubdivisionCode,
                  address?.postalCode,
                ]
                  .filter(n => n)
                  .join(" ")
          }
          autocompleteEnabled={true}
          disabled={provider}
          onAddressPicked={pickedAddress => {
            setAddress({ ...address, ...pickedAddress });
            handleChangeProvider({ address: { ...address, ...pickedAddress } });
          }}
        />
        <InputFilterable
          id="providerType"
          isMulti
          label="Provider Type"
          name="providerType"
          values={PROVIDER_TYPE_GROUPS}
          value={providerType}
          onChange={type => {
            setProviderType(type);
            handleChangeProvider({ type });
          }}
          disabled={provider}
        />
        <If
          condition={
            providerType &&
            Array.isArray(providerType) &&
            providerType.some(type => type.value.includes("other-medical"))
          }
        >
          <InputText
            label="Other medical"
            name="otherMedical"
            onChange={otherMedical => handleChangeProvider({ otherMedical })}
          />
        </If>
        <If
          condition={
            providerType &&
            Array.isArray(providerType) &&
            providerType.some(type => type.value.includes("other-dental"))
          }
        >
          <InputText
            label="Other Dental"
            name="otherDental"
            onChange={otherDental => handleChangeProvider({ otherDental })}
          />
        </If>
        <If
          condition={
            providerType &&
            Array.isArray(providerType) &&
            providerType.some(type => type.value.includes("other-mental"))
          }
        >
          <InputText
            label="Other Behavioral/Mental Health"
            name="otherMental"
            onChange={otherMental => handleChangeProvider({ otherMental })}
          />
        </If>
      </Flex>
    </Surface>
  );
};

NewProviderForm.propTypes = {
  selectedProvider: PropTypes.string,
};

export default NewProviderForm;
