import { Button, Flex } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import IntakeRecommendation from "@components/shared/IntakeRecommendation";
import Allegations from "@components/shared/allegations/Allegations";
import IntakeScreeningTool from "@components/shared/intake_screening_tool/IntakeScreeningTool";
import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";
import RelatedCases from "@components/shared/related/Related";

import styles from "./IntakeReview.module.scss";

const links = [
  {
    href: "#related-incidents",
    label: "Related incidents",
  },
  {
    href: "#allegations",
    label: "Allegations",
  },
  {
    href: "#intake-screening-tool",
    label: "Intake Screening Tool",
  },
  {
    href: "#intake-recommendation",
    label: "Intake Recommendation",
  },
];

const ReviewRecommendation = ({ setOpenModal, formState, updateFormState }) => (
  <LayoutSidebarSections links={links}>
    <RelatedCases
      buttonClass={styles.relatedCasesButton}
      formState={formState}
    />
    <Allegations
      value={formState.allegations}
      onChange={newAllegations =>
        updateFormState({ allegations: [...newAllegations] })
      }
      abuserOptions={formState.persons
        .filter(person => (person.role || []).includes("alleged-abuser"))
        .map(p => ({ label: p.name, value: p.name }))}
      victimOptions={formState.persons
        .filter(person => (person.role || []).includes("victim"))
        .map(p => ({ label: p.name, value: p.name }))}
    />
    <div id={links[2].href.split("#")[1]}>
      <IntakeScreeningTool
        formState={formState}
        updateFormState={updateFormState}
      />
    </div>
    <div id={links[3].href.split("#")[1]}>
      <IntakeRecommendation
        formState={formState}
        updateFormState={updateFormState}
      />
    </div>
    <Flex justify="end">
      <Button onClick={() => setOpenModal(true)}>
        Save & Review Submission
      </Button>
    </Flex>
  </LayoutSidebarSections>
);

ReviewRecommendation.propTypes = {
  formState: PropTypes.object.isRequired,
  updateFormState: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};

export default ReviewRecommendation;
