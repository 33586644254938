import {
  Surface,
  Flex,
  InputFilterable,
  InputRadioGroup,
  InputDateTime,
} from "@heart/components";
import React from "react";

import sharedStyles from "@components/shared/styles/SharedStyles.module.scss";

import { useCaseActivity } from "../CaseActivityContext";
import styles from "./ActivityInformation.module.scss";

const ActivityInformation = () => {
  const { formState, updateFormState, currentUser } = useCaseActivity();

  const handleDateChange = date => {
    updateFormState({
      ...formState,
      activityDate: date,
    });
  };

  const handleCompletedChange = value => {
    updateFormState({
      ...formState,
      caseCompleted: value,
    });
  };

  return (
    <React.Fragment>
      <Surface
        title="Activity Information"
        className={sharedStyles.surfaceContainer}
      >
        <Flex column gap="300" className={styles.inputContainer}>
          <InputDateTime
            value={formState.activityDate}
            required
            label="Date"
            name="date-acitivty"
            onChange={handleDateChange}
          />
          <InputFilterable
            defaultValue={{
              label: `${currentUser.firstName} ${currentUser.lastName}`,
              value: `${currentUser.firstName}-${currentUser.lastName}`,
            }}
            id="caseworker-activity"
            label="Caseworker"
            name="caseworker-activity"
            onChange={() => {}}
            values={[
              {
                label: `${currentUser.firstName} ${currentUser.lastName}`,
                value: `${currentUser.firstName}-${currentUser.lastName}`,
              },
            ]}
          />

          <InputRadioGroup
            orientation="row"
            id="completed-activity"
            label="Completed or Attempted?"
            onChange={handleCompletedChange}
            required
            value={formState.caseCompleted}
            values={[
              {
                label: "Completed",
                value: true,
              },
              {
                label: "Attempted",
                value: false,
              },
            ]}
          />
        </Flex>
      </Surface>
    </React.Fragment>
  );
};
export default ActivityInformation;
