/* eslint-disable no-nested-ternary */
import { Pill, Button, Flex, Text } from "@heart/components";

import { formatFamilyNames, formatDate, addMinutes } from "./helper";

// ------------------- Mock Data for Cards ------------------- //

const getStatusVariant = status => {
  if (status.includes("60+")) {
    return "warning";
  }
  if (status.includes("25")) {
    return "alert";
  }
  return "neutral";
};

export const fakePerson = {
  role: ["victim", "caretaker"],
  name: "John",
  middleName: "Doe",
  lastName: "Smith",
  isNameUnknown: false,
  dob: "2000-01-01",
  isDobEstimate: false,
  phoneNumber: "123-456-7890",
  email: "john.smith@example.com",
  sex: "male",
  isVictimInSchool: "Yes",
  tribalAffiliation: "No",
  physicalDescriptor: "Tall, with brown hair and blue eyes",
  personIsDeceased: false,
};

// ------------------- Mock Data for Table ------------------- //

export const columnsTabOne = [
  {
    cell: ({ referralId }) => (
      <a href={`/intake/review/${referralId}`}>{referralId}</a>
    ),
    columnName: {
      name: "Referral ID",
    },
    columnSort: () => {},
    id: "referralId",
  },
  {
    cell: "reportTimestamp",
    columnName: {
      name: "Report Timestamp",
    },
    columnSort: () => {},
    id: "report_timestamp",
  },
  {
    cell: ({ status }) => (
      <Pill variant={getStatusVariant(status)} text={status} />
    ),
    columnName: {
      name: "Status",
    },
    id: "status",
  },
  {
    cell: ({ reporterType }) => {
      let variant;
      switch (reporterType) {
        case "Anonymous":
          variant = "neutral";
          break;
        case "Non Mandated":
          variant = "info";
          break;
        case "Mandated":
        case "Agency Staff":
          variant = "success";
          break;
        default:
          variant = "neutral";
      }
      return <Pill variant={variant} text={reporterType} />;
    },
    columnName: {
      name: "Reporter Type",
    },
    id: "reporter_type",
  },
  {
    cell: ({ familyInstitutionNames }) =>
      formatFamilyNames(familyInstitutionNames),
    columnName: {
      name: "Family/Institution Name",
    },
    id: "familyInstitutionNames",
  },
  {
    cell: "allegationType",
    columnName: {
      name: "Allegation Types",
    },
    id: "allegationType",
  },
  {
    columnName: { name: "Actions", justify: "center" },
    id: "actions",
    cell: ({ referralId }) => (
      <Button variant="secondary" href={`/intake/accept/${referralId}`}>
        Accept
      </Button>
    ),
  },
];

export const dataTabOne = [
  // {
  //   referralId: "INT-03941-001",
  //   reportTimestamp: formatDate(addMinutes(new Date(), -16)),
  //   status: "16 min",
  //   reporterType: "Mandated",
  //   familyInstitutionNames: [
  //     "Larson (23)",
  //     "Reyes (4)",
  //     "Reyes (35)",
  //     "Reyes (6)",
  //   ],
  //   allegationType: "Physical Neglect",
  // },
  // {
  //   referralId: "INT-10922-001",
  //   reportTimestamp: formatDate(addMinutes(new Date(), -9)),
  //   status: "9 min",
  //   reporterType: "Mandated",
  //   familyInstitutionNames: ["Abby (6)", "Johnson (30)"],
  //   allegationType: "Human Trafficking",
  // },
  // {
  //   referralId: "INT-03999-001",
  //   reportTimestamp: formatDate(addMinutes(new Date(), -6)),
  //   status: "6 min",
  //   reporterType: "Non-Mandated",
  //   familyInstitutionNames: ["Lopez (34)", "Lopez (3)", "Chan (30)"],
  //   allegationType: "Physical Abuse",
  // },
];

export const columnsTabTwo = [
  {
    cell: ({ referralId }) => (
      <a href={`/intake/review/${referralId}`}>{referralId}</a>
    ),
    columnName: {
      name: "Referral ID",
    },
    columnSort: () => {},
    id: "referralId",
  },
  {
    cell: "reportTimestamp",
    columnName: {
      name: "Report Timestamp",
    },
    columnSort: () => {},
    id: "report_timestamp",
  },
  {
    cell: ({ status }) => (
      <Pill variant={getStatusVariant(status)} text={status} />
    ),
    columnName: {
      name: "Status",
    },
    id: "status",
  },
  {
    cell: ({ familyInstitutionNames }) =>
      formatFamilyNames(familyInstitutionNames),
    columnName: {
      name: "Family/Institution Name(s)",
    },
    id: "familyInstitutionNames",
  },
  {
    cell: "allegationType",
    columnName: {
      name: "Allegation Types",
    },
    id: "allegationType",
  },
  {
    cell: ({ priorityRecommendation }) =>
      priorityRecommendation && (
        <Flex row>
          <If condition={priorityRecommendation.priority}>
            <Pill
              variant={
                priorityRecommendation.priority === "R1"
                  ? "warning"
                  : priorityRecommendation.priority === "R2"
                  ? "alert"
                  : "success"
              }
              text={priorityRecommendation.priority}
            />
          </If>
          <If condition={priorityRecommendation.type}>
            <Pill
              variant={
                priorityRecommendation.type === "Investigation"
                  ? "warning"
                  : priorityRecommendation.type === "Differential Response"
                  ? "alert"
                  : "neutral"
              }
              text={priorityRecommendation.type}
            />
          </If>
        </Flex>
      ),
    columnName: {
      name: "Response/Recommendation",
    },

    id: "priority_recommendation",
  },
  {
    cell: ({ approvals }) => {
      let variant = "warning";
      if (approvals === "Under Review") {
        variant = "alert";
      } else if (approvals === "Pending Region & Worker Assignment") {
        variant = "info";
      }
      return approvals && <Pill variant={variant} text={approvals} />;
    },
    columnName: {
      name: "Approvals",
    },
    id: "approvals",
  },
];

export const dataTabTwo = [
  // {
  //   referralId: "INT-04921-001",
  //   reportTimestamp: formatDate(addMinutes(new Date(), -20)),
  //   status: "20 min",
  //   familyInstitutionNames: [
  //     "Montgomery (56)",
  //     "Montgomery (4)",
  //     "Montgomery (6)",
  //     "Montgomery (8)",
  //   ],
  //   allegationType: "Physical Neglect",
  //   priorityRecommendation: {
  //     priority: "R1",
  //     type: "Investigation",
  //   },
  //   approvals: "Under Review",
  // },
  // {
  //   referralId: "INT-04959-001",
  //   reportTimestamp: formatDate(addMinutes(new Date(), -5)),
  //   status: "15 min",
  //   familyInstitutionNames: ["Smith (22)", "Sylas (18)", "Smith (3)"],
  //   allegationType: "Physical Neglect",
  // },
];

export const columnsTabThree = [
  {
    cell: ({ referralId }) => (
      <a href={`/intake/review/${referralId}`}>{referralId}</a>
    ),
    columnName: {
      name: "Referral ID",
    },
    columnSort: () => {},
    id: "referralId",
  },
  {
    cell: "reportTimestamp",
    columnName: {
      name: "Report Timestamp",
    },
    columnSort: () => {},
    id: "report_timestamp",
  },
  {
    cell: ({ status }) => (
      <Pill variant={getStatusVariant(status)} text={status} />
    ),
    columnName: {
      name: "Status",
    },
    id: "status",
  },
  {
    cell: ({ familyInstitutionNames }) =>
      formatFamilyNames(familyInstitutionNames),
    columnName: {
      name: "Family/Institution Name(s)",
    },
    id: "familyInstitutionNames",
  },
  {
    cell: "allegationType",
    columnName: {
      name: "Allegation Types",
    },
    id: "allegationType",
  },
  {
    cell: ({ priorityRecommendation }) =>
      priorityRecommendation && (
        <Flex row>
          <If condition={priorityRecommendation.priority}>
            <Pill
              variant={
                priorityRecommendation.priority === "R1"
                  ? "warning"
                  : priorityRecommendation.priority === "R2"
                  ? "alert"
                  : "success"
              }
              text={priorityRecommendation.priority}
            />
          </If>
          <If condition={priorityRecommendation.type}>
            <Pill
              variant={
                priorityRecommendation.type === "Investigation"
                  ? "warning"
                  : priorityRecommendation.type === "Differential Response"
                  ? "alert"
                  : "neutral"
              }
              text={priorityRecommendation.type}
            />
          </If>
        </Flex>
      ),
    columnName: {
      name: "Response/Recommendation",
    },

    id: "priority_recommendation",
  },
  {
    cell: ({ workerAssigned }) => (
      <Flex row>
        <Text>{workerAssigned}</Text>
      </Flex>
    ),
    columnName: { name: "Worker", justify: "start" },
    id: "workerAssigned",
  },
];

export const dataTabThree = [
  // {
  //   referralId: "INT-04450-001",
  //   reportTimestamp: formatDate(addMinutes(new Date(), -20)),
  //   status: "20 min",
  //   familyInstitutionNames: [
  //     "Allison (56)",
  //     "Denver (4)",
  //     "Allison (4)",
  //     "Allison (8)",
  //   ],
  //   allegationType: "Physical Neglect",
  //   priorityRecommendation: {
  //     priority: "R2",
  //     type: "Investigation",
  //   },
  //   workerAssigned: "Ethan Brooks",
  // },
  // {
  //   referralId: "INT-04921-001",
  //   reportTimestamp: formatDate(addMinutes(new Date(), -20)),
  //   status: "20 min",
  //   familyInstitutionNames: [
  //     "Montgomery (56)",
  //     "Montgomery (4)",
  //     "Montgomery (6)",
  //     "Montgomery (8)",
  //   ],
  //   allegationType: "Physical Neglect",
  //   priorityRecommendation: {
  //     priority: "R1",
  //     type: "Investigation",
  //   },
  //   workerAssigned: "Ava Johnson",
  // },
  // {
  //   referralId: "INT-04963-001",
  //   reportTimestamp: formatDate(addMinutes(new Date(), -5)),
  //   status: "5 min",
  //   workerAssigned: "Olivia Smith",
  //   familyInstitutionNames: ["Parker (19)", "Lee (8)"],
  //   allegationType: "Physical Abuse",
  //   priorityRecommendation: {
  //     priority: "R2",
  //     type: "Differential Response",
  //   },
  // },
];

export const columnsTabFour = [
  {
    cell: ({ referralId }) => (
      <a href={`/intake/review/${referralId}`}>{referralId}</a>
    ),
    columnName: {
      name: "Referral ID",
    },
    columnSort: () => {},
    id: "referralId",
  },
  {
    cell: "processedTimestamp",
    columnName: {
      name: "Processed Timestamp",
    },
    columnSort: () => {},
    id: "processed_timestamp",
  },
  {
    cell: ({ workerAssigned }) => <a href="/">{workerAssigned}</a>,
    columnName: {
      name: "Worker Assigned",
    },
    id: "worker_assigned",
  },
  {
    cell: ({ familyInstitutionNames }) =>
      formatFamilyNames(familyInstitutionNames),
    columnName: {
      name: "Family/Institution Name(s)",
    },
    id: "familyInstitutionNames",
  },
  {
    cell: "reportTimestamp",
    columnName: {
      name: "Report Timestamp",
    },
    columnSort: () => {},
    id: "report_timestamp",
  },
  {
    cell: ({ screeningResult }) => (
      <Flex row>
        <Pill
          variant={
            screeningResult.priority === "R1"
              ? "alert"
              : screeningResult.priority === "R2"
              ? "warning"
              : "success"
          }
          text={screeningResult.priority}
        />
        <Pill
          variant={
            screeningResult.type === "Immediate Response"
              ? "alert"
              : screeningResult.type === "Differential Response"
              ? "warning"
              : "success"
          }
          text={screeningResult.type}
        />
      </Flex>
    ),
    columnName: {
      name: "Screening Result",
    },
    id: "screening_result",
  },
  {
    cell: ({ allegations }) => (
      <Flex row>
        <Text>{allegations}</Text>
      </Flex>
    ),
    columnName: {
      name: "Allegations",
    },
    id: "allegations",
  },
];

export const dataTabFour = [
  // {
  //   referralId: "0293847",
  //   processedTimestamp: "07/25/20 8:00 AM",
  //   workerAssigned: "Jordan Smith",
  //   familyInstitutionNames: ["Taylor (15)", "Parker (20)", "Johnson (6)"],
  //   reportTimestamp: "07/26/20 4:30 PM",
  //   screeningResult: {
  //     priority: "R1",
  //     type: "Immediate Response",
  //   },
  //   allegations: "Neglect - Lack of Supervision",
  // },
  // {
  //   referralId: "0482950",
  //   processedTimestamp: "07/27/20 10:12 AM",
  //   workerAssigned: "Emily Carter",
  //   familyInstitutionNames: ["Harris (40)", "Miller (32)"],
  //   reportTimestamp: "07/27/20 11:45 AM",
  //   screeningResult: {
  //     priority: "R3",
  //     type: "Non-Immediate Response",
  //   },
  //   allegations: "Emotional Abuse - DV",
  // },
  // {
  //   referralId: "0583921",
  //   processedTimestamp: "07/27/20 9:15 AM",
  //   workerAssigned: "Chris Johnson",
  //   familyInstitutionNames: ["Garcia (18)", "Lee (10)"],
  //   reportTimestamp: "07/27/20 8:30 AM",
  //   screeningResult: {
  //     priority: "R2",
  //     type: "Differential Response",
  //   },
  //   allegations: "Physical Abuse - Injuries",
  // },
  // {
  //   referralId: "0738192",
  //   processedTimestamp: "07/28/20 7:45 AM",
  //   workerAssigned: "Sophia White",
  //   familyInstitutionNames: ["Brown (22)", "Davis (8)", "Martinez (12)"],
  //   reportTimestamp: "07/28/20 9:34 PM",
  //   screeningResult: {
  //     priority: "R1",
  //     type: "Immediate Response",
  //   },
  //   allegations: "Emotional Abuse - DV",
  // },
  // {
  //   referralId: "0398320",
  //   processedTimestamp: "07/29/20 11:20 AM",
  //   workerAssigned: "Alex Nguyen",
  //   familyInstitutionNames: ["Clark (14)", "Young (19)"],
  //   reportTimestamp: "07/29/20 1:00 PM",
  //   screeningResult: {
  //     priority: "R3",
  //     type: "Non-Immediate Response",
  //   },
  //   allegations: "Neglect - Medical Needs Not Met",
  // },
];
