import { Flex, Icons, Text } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, createContext } from "react";

import { translationWithRoot } from "@components/T";

import { useGeneratedId } from "@lib/generateId";

import ResolutionOnly from "../layout/ResolutionOnly";
import styles from "./Surface.module.scss";
import useSurfaceContext from "./useSurfaceContext";

const { t } = translationWithRoot("surface.collapsible");

export const SurfaceContext = createContext({
  title: null,
  titleId: null,
  headingLevel: 1,
  onSurface: false,
});

export const SurfaceContextProvider = ({
  children,
  title,
  titleId,
  headingLevel,
}) => (
  <SurfaceContext.Provider
    value={{ onSurface: true, title, titleId, headingLevel }}
  >
    {children}
  </SurfaceContext.Provider>
);

SurfaceContextProvider.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  titleId: PropTypes.string.isRequired,
  headingLevel: PropTypes.number.isRequired,
};

const SurfaceTitle = ({ title, hideTitle, titleStyle, titleColor }) => {
  const { headingLevel, titleId } = useSurfaceContext();

  return (
    <Text
      as={`h${headingLevel}`}
      textStyle={titleStyle || "emphasis-100"}
      textColor={titleColor || "neutral-600"}
      className={classNames(styles.headerTitle, {
        [styles.hidden]: hideTitle,
      })}
      id={titleId}
    >
      {title}
    </Text>
  );
};
SurfaceTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  hideTitle: PropTypes.bool,
};

/**
 * A "raised" surface to put controls on top of, breaking up the
 * page into multiple sections.
 */
const SurfaceBase = ({
  id,
  title,
  subtitle,
  secondary,
  collapsible,
  defaultCollapsed = false,
  hideTitle,
  bonusContent,
  children,
  sectionId,
  titleStyle,
  titleColor,
  variant: variantProp = "raised",
  rootElement: RootComponent = "div",
  rootElementProps = {},
  "data-testid": testId,
  className,
}) => {
  const [collapsed, setCollapsed] = useState(collapsible && defaultCollapsed);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  const { onSurface, headingLevel } = useSurfaceContext();
  const variant = onSurface ? "outline" : variantProp;

  const titleId = useGeneratedId();
  const headerId = useGeneratedId();
  const generatedSurfaceId = useGeneratedId();
  const surfaceId = id || generatedSurfaceId;

  const renderHeader = () => (
    <Flex column fullWidth>
      <Flex
        row
        justify="space-between"
        fullWidth
        align="center"
        className={styles.title}
      >
        <SurfaceTitle
          title={title}
          titleId={titleId}
          hideTitle={hideTitle}
          titleStyle={titleStyle}
          titleColor={titleColor}
        />
        <If condition={Boolean(bonusContent)}>{bonusContent}</If>
      </Flex>
      <If condition={Boolean(subtitle)}>
        <Text as="div" textStyle="supporting-100" textColor="neutral-500">
          {subtitle}
        </Text>
      </If>
    </Flex>
  );

  const renderToggle = () =>
    collapsible ? (
      <span className={collapsed ? styles.caretClosed : styles.caretOpen}>
        <Icons.ChevronDown
          aria-expanded={!collapsed}
          aria-controls={sectionId || surfaceId}
          description={collapsed ? t("open", { title }) : t("close", { title })}
          onClick={toggleCollapsed}
        />
      </span>
    ) : null;

  const renderSecondary = () => (
    <span
      className={collapsed ? styles.hidden : styles.visible}
      aria-hidden={collapsed}
    >
      {secondary}
    </span>
  );

  return (
    <SurfaceContextProvider
      title={title}
      titleId={titleId}
      headingLevel={headingLevel + 1}
    >
      <RootComponent
        className={classNames(styles.container, styles[variant], className, {
          [styles.hideTitle]: hideTitle,
        })}
        id={sectionId || surfaceId}
        aria-labelledby={headerId}
        data-testid={testId}
        {...rootElementProps}
      >
        <div
          className={classNames(styles.header, {
            [styles.hidden]: hideTitle && !secondary,
          })}
          id={headerId}
        >
          <ResolutionOnly small over>
            <Flex justify="space-between" fullWidth>
              {renderHeader()}
              <Flex align="center" gap="300" className={styles.reverse}>
                {renderToggle()}
                {renderSecondary()}
              </Flex>
            </Flex>
          </ResolutionOnly>
          {/* Reordering for smaller screens to have secondary in a column under
          the title and collapsible toggle */}
          <ResolutionOnly small under>
            <Flex column>
              <Flex>
                {renderHeader()}
                {renderToggle()}
              </Flex>
              <Flex align="center" gap="300">
                {renderSecondary()}
              </Flex>
            </Flex>
          </ResolutionOnly>
        </div>
        <If condition={children}>
          <div
            aria-hidden={collapsed}
            className={collapsed ? styles.hidden : styles.visible}
          >
            {children}
          </div>
        </If>
      </RootComponent>
    </SurfaceContextProvider>
  );
};

SurfaceBase.propTypes = {
  /** ID for the surface, generally not necessary to provide as it will be autogenerated
   * unless we need to reference the ID elsewhere
   */
  id: PropTypes.string,
  /** Title of the Surface, displayed in the top left.  This is always required
   * for accessibility, but if you want to hide it, use the `hideTitle` prop. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Whether or not the title is hidden.  If the title is hidden and there is no
   * content in `secondary`, the entire header will be hidden. */
  hideTitle: PropTypes.bool,
  /** Subtitle of the Surface, displayed in the top left */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Secondary content - usually a call-to-action or a filter input,
   * displayed in the top right */
  secondary: PropTypes.node,
  /** Whether the surface should be collapsible using a toggle in the top right
   * corner of the surface
   */
  collapsible: PropTypes.bool,
  /** Whether the surface should default to being collapsed on first load. Defaults
   * to false, and only usable in conjunction with `collapsible`
   */
  defaultCollapsed: PropTypes.bool,
  /** Surface contents */
  children: PropTypes.node,
  /** The id for the section of the surface, this can be useful for anchoring
   *  purposes
   */
  sectionId: PropTypes.string,
  /** Which type of Surface this is.  Defaults to `raised`. */
  variant: PropTypes.oneOf(["raised", "recessed", "outline", "ai"]),
  /** Which HTML tag should be at the root element of this Surface */
  rootElement: PropTypes.string,
  /** Additional props to pass to the root element */
  rootElementProps: PropTypes.object,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,

  titleStyle: PropTypes.string,
  titleColor: PropTypes.string,
};
export default SurfaceBase;
