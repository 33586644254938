import {
  Flex,
  HeartTable,
  InputDropdown,
  InputFilterable,
  InputText,
  InputTextarea,
  Link,
  Pill,
  Surface,
  Text,
} from "@heart/components";
import patch from "@utils/patch";
import PropTypes from "prop-types";
import React from "react";
import { investigationPath } from "routes";

import AutoSaveStickyFooter from "@components/shared/AutoSaveStickyFooter";
import workers from "@components/shared/data/workers.json";
import useFormState from "@components/shared/hooks/useFormState";
import CreateViewLayout from "@components/shared/layout/create_view_layout/CreateViewLayout";

import {
  formatDateTimeAsShortDate,
  formatDateTimeAsShortTime,
} from "@lib/dates";

import addedWorkers from "../../utils/addedWorkers.json";
import WorkerCard from "../shared/WorkerCard";
import FireworksIcon from "./FireworksIcon";
import styles from "./ReferralAssignment.module.scss";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const allWorkers = [
  ...workers.recommendedWorkers,
  ...workers.onCallWorkers,
  ...workers.regionalWorkers,
  ...addedWorkers.workers,
];

const sortedWorkers = allWorkers.sort((a, b) =>
  a.firstName.localeCompare(b.firstName)
);

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const ReferralAssignmentPage = ({ caseData, referralId, reportTimestamp }) => {
  const {
    referralName,
    incidentTimestamp,
    intakeRecommendation: {
      recommendedResponseLevel,
      recommendedResponse,
      reasoning,
    } = {
      recommendedResponseLevel: "R1",
      recommendedResponse: "Investigation",
      reasoning:
        "Case included significant signs of distress and we have already had 2 prior reports on this family calling for a visit.",
    },
  } = caseData || {};
  const { formState, updateFormState } = useFormState({
    ...caseData,
    incidentInfo: {
      referralId,
      ...(caseData?.incidentInfo || {}),
    },
    region: "Region 4",
  });

  return (
    <CreateViewLayout
      breadcrumb={[
        {
          href: "/",
          label: "Home",
        },
        {
          href: "/intake",
          label: "Intake",
        },
        {
          href: `/intake/review/${referralId}`,
          label: caseData?.intakeCaseReferralId || referralId,
        },
        {
          href: "#",
          label: "Referral Assignment",
        },
      ]}
      links={[
        {
          href: "#referral-information",
          label: "Referral Information",
        },
        {
          href: "#assignment-history",
          label: "Assignment History",
        },
        { href: "#assignments", label: "Assignments" },
      ]}
      sidebarTitle="Recommended workers"
      sidebarSubtitle="AI supported recommendation of workers"
      hideSidebarTitle
      pageTitle="Referral Assignment"
      sidebar={
        <Flex column className={styles.workerSidebar}>
          <Flex column gap="100">
            <Flex gap="100" align="center">
              <FireworksIcon />
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Recommended Workers
              </Text>
            </Flex>
            <Text as="div" textStyle="supporting-50" textColor="neutral-600">
              AI supported recommendation of workers
            </Text>
          </Flex>
          {workers.recommendedWorkers.map(worker => (
            <WorkerCard
              key={worker.firstName + worker.lastName}
              firstName={worker.firstName}
              lastName={worker.lastName}
              address={worker.address}
              phoneNumber={worker.phoneNumber}
              pills={worker.pills}
              showEye={worker.showEye}
              isFeatured={worker.isFeatured}
            />
          ))}
          <Flex column gap="100">
            <Text textStyle="emphasis-200" textColor="neutral-600">
              On Call Workers
            </Text>
            <Text as="div" textStyle="supporting-50" textColor="neutral-600">
              Currently listed as on call during off hours. Call (403)040-3405
              for general support
            </Text>
          </Flex>
          <Flex column gap="300">
            {workers.onCallWorkers.map(worker => (
              <WorkerCard
                key={worker.firstName + worker.lastName}
                firstName={worker.firstName}
                lastName={worker.lastName}
                address={worker.address}
                phoneNumber={worker.phoneNumber}
                pills={worker.pills}
                showEye={worker.showEye}
                isFeatured={worker.isFeatured}
              />
            ))}
          </Flex>
          <Flex column gap="100">
            <Text textStyle="emphasis-200" textColor="neutral-600">
              Regional Workers
            </Text>
            <Text as="div" textStyle="supporting-50" textColor="neutral-600">
              Workers recommended based on Jursidiction selected
            </Text>
          </Flex>
          <Flex column gap="300">
            {workers.regionalWorkers.map(worker => (
              <WorkerCard
                key={worker.firstName + worker.lastName}
                firstName={worker.firstName}
                lastName={worker.lastName}
                address={worker.address}
                phoneNumber={worker.phoneNumber}
                pills={worker.pills}
                showEye={worker.showEye}
                isFeatured={worker.isFeatured}
              />
            ))}
          </Flex>
        </Flex>
      }
      footer={
        <AutoSaveStickyFooter
          saveText="Save"
          cancelText="Cancel"
          submitText="Save & Assign"
          onSubmit={async () => {
            await patch(investigationPath(referralId), formState);
          }}
          formState={formState}
          text={`Referral ID: ${referralId}`}
        />
      }
    >
      {({ sectionRefs, handleDivClick }) => (
        <React.Fragment>
          <div
            id="referral-information"
            ref={el => (sectionRefs.current["referral-information"] = el)}
            onClick={() => handleDivClick("referral-information")}
          >
            <Surface title="Referral Information" hideTitle>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Referral Information
              </Text>
              <Flex className={styles.inputWidth}>
                <InputText
                  label="Referral ID"
                  required
                  value={referralId}
                  disabled
                />
              </Flex>
              <Flex className={styles.inputWidth}>
                <InputText
                  label="Referral Name"
                  required
                  value={referralName}
                  disabled
                />
              </Flex>
              <Flex className={styles.inputWidth}>
                <InputText
                  label="Report timestamp"
                  required
                  value={
                    incidentTimestamp
                      ? `${formatDateTimeAsShortDate(
                          incidentTimestamp
                        )} ${formatDateTimeAsShortTime(incidentTimestamp)}`
                      : reportTimestamp
                  }
                  disabled
                />
              </Flex>
              <Flex column gap="300" align="start">
                <Text textStyle="body-100" textColor="neutral-600">
                  Response/Recommendation
                </Text>
                <Flex>
                  <If condition={recommendedResponseLevel}>
                    <Pill
                      text={recommendedResponseLevel}
                      variant={
                        recommendedResponseLevel === "R1" ? "warning" : "alert"
                      }
                    />
                  </If>
                  <If condition={recommendedResponse}>
                    <Pill
                      text={recommendedResponse}
                      variant={
                        recommendedResponse === "Screened out"
                          ? "info"
                          : "warning"
                      }
                    />
                  </If>
                </Flex>
              </Flex>
              <InputTextarea
                label="Recommendation reasoning"
                required
                value={reasoning}
                disabled
              />
            </Surface>
          </div>
          <div
            id="assignment-history"
            ref={el => (sectionRefs.current["assignment-history"] = el)}
            onClick={() => handleDivClick("assignment-history")}
          >
            <Surface title="History of Worker Assignments" hideTitle>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                History of Worker Assignments
              </Text>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Start Date</Th>
                    <Th>End Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Link href="#">Ava Johnson</Link>
                    </Td>
                    <Td>{dateFormatter.format(new Date())}</Td>
                    <Td alignRight>{dateFormatter.format(new Date())}</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Link href="#">Linda Rhodes</Link>
                    </Td>
                    <Td>{dateFormatter.format(new Date())}</Td>
                    <Td alignRight>{dateFormatter.format(new Date())}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Surface>
          </div>
          <div
            id="assignments"
            ref={el => (sectionRefs.current["assignments"] = el)}
            onClick={() => handleDivClick("assignments")}
          >
            <Surface title="Region and Worker Assignments" hideTitle>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Region and Worker Assignments
              </Text>
              <Flex className={styles.inputWidth}>
                <InputDropdown
                  id="region"
                  label="Region"
                  value={formState.region}
                  onChange={v => updateFormState({ region: v })}
                  values={[
                    "Region 1",
                    "Region 2",
                    "Region 3",
                    "Region 4",
                    "Region 5",
                    "Region 6",
                  ]}
                />
              </Flex>

              <InputFilterable
                id="primaryWorkerAssigned"
                label="Primary Worker Assigned"
                name="primaryWorkerAssigned"
                values={sortedWorkers.map(worker => ({
                  label: `${worker.firstName} ${worker.lastName}`,
                  value: `${worker.firstName} ${worker.lastName}`,
                  ...worker,
                }))}
                onChange={workerAssigned => updateFormState({ workerAssigned })}
              />
              <InputFilterable
                id="secondaryWorkerAssigned"
                label="Secondary Worker Assigned"
                name="secondaryWorkerAssigned"
                values={[]}
              />
              <InputFilterable
                id="supervisorAssigned"
                label="Supervisor Assigned"
                name="supervisorAssigned"
                values={[]}
              />
            </Surface>
          </div>
        </React.Fragment>
      )}
    </CreateViewLayout>
  );
};

ReferralAssignmentPage.propTypes = {
  caseData: PropTypes.object,
  referralId: PropTypes.string.isRequired,
  reportTimestamp: PropTypes.string.isRequired,
};

export default ReferralAssignmentPage;
