import {
  Button,
  Icons,
  Surface,
  Flex,
  Text,
  InputDropdown,
  HeartTable,
  Link,
} from "@heart/components";
import PropTypes from "prop-types";
import { caseActivityEditPath, caseActivityNewPath } from "routes";

import {
  activityValueMappings,
  eventOptionsMappings,
} from "@components/case_activity/Cards/ActivityOptions";
import {
  formatDate,
  formatFamilyNamesAsLinks,
} from "@components/intake_portal/helper";

import styles from "./PersonProfilePage.module.scss";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const mapToLabel = value => (value ? activityValueMappings[value] : "");

// each item in event is a string
const processEventInformation = (events, mapping) => {
  const result = {};
  events.forEach(event => {
    if (mapping[event] !== undefined) {
      result[event] = [];
    }
  });
  events.forEach(event => {
    // not in result yet therefore not a parent key
    if (result[event] === undefined) {
      const parentKey = Object.keys(mapping).find(key => mapping[key][event]);

      if (parentKey) {
        result[parentKey].push(mapping[parentKey][event]);
      }
    }
  });
  return result;
};

const EventBody = (activityType, hashedEvents) => (
  <Td>
    <Text textStyle="body-100" textColor="neutral-600">
      {mapToLabel(activityType)}
    </Text>

    {Object.keys(hashedEvents).map(event => (
      <Flex key={activityType} gap="100" align="center">
        <Text textStyle="body-100" textColor="neutral-600">
          {event}:
        </Text>
        <Text textStyle="body-100" textColor="neutral-600">
          {hashedEvents[event].join(", ")}
        </Text>
      </Flex>
    ))}
  </Td>
);

const CaseActivity = ({ caseActivity }) => (
  <div className={styles.caseActivityLayout}>
    <Surface title="Case Activity" hideTitle>
      <Flex align="center" justify="space-between">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Case Activity
        </Text>
        <Button
          icon={Icons.Plus}
          variant="primary"
          href={caseActivityNewPath()}
        >
          New Case Activity
        </Button>
      </Flex>
      <Flex justify="center" align="center">
        <Flex gap="300" align="center">
          <Text textStyle="body-100" textColor="neutral-600">
            Filter by:
          </Text>
          <Flex gap="100">
            <InputDropdown
              id="filter"
              blankOptionText="Select Filter"
              values={[
                ["The Thing", "the-thing-value"],
                ["The Other Thing", "the-other-thing-value"],
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th> Timestamp </Th>
            <Th> Case </Th>
            <Th> Caseworker </Th>
            <Th> Method of Engagement </Th>
            <Th> Activity Type/Details </Th>
            <Th> Person(s) Engaged </Th>
            <Th> Actions </Th>
          </Tr>
        </Thead>
        <Tbody>
          {caseActivity &&
            caseActivity.map((item, i) => (
              <Tr key={i}>
                <Td>
                  {formatDate(new Date(item.formState?.activityDate || ""))}
                </Td>
                <Td>
                  {item.case.referralName.join(",")} ({item.case.id})
                </Td>
                <Td>
                  {item.worker?.firstName
                    ? `${item.worker?.firstName ?? ""} ${
                        item.worker?.lastName ?? ""
                      }`
                    : "Jackson Dirk"}
                </Td>
                <Td>{mapToLabel(item.formState?.methodOfEngagement)}</Td>
                {EventBody(
                  item.formState?.activityType,
                  processEventInformation(
                    item.formState?.eventInformation,
                    eventOptionsMappings
                  )
                )}
                <Td>{formatFamilyNamesAsLinks(item.personsEngaged)}</Td>
                <Td>
                  <Link href={caseActivityEditPath(item.case.id, item.id)}>
                    View/Edit
                  </Link>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Surface>
  </div>
);

CaseActivity.propTypes = {
  caseActivity: PropTypes.array.isRequired,
};

export default CaseActivity;
