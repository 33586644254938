/* eslint-disable react/prop-types */
import { Upload, HeartTable, Icons } from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import SurfaceBase from "@heart/components/surface/SurfaceBase";
import React from "react";

import IconUploadImage from "../../shared/attachments/icon-image-plus.svg";
import styles from "./ActivityInformation.module.scss";

// @Mario -  make sure your formState and updateFormState points to your function that updates the form state with your page if you're re-using
// see /components/intake_portal/NewMaltreatmentReportPage.js for an example of how I'm using it

const MedicalAttachments = ({
  formState,
  updateFormState,
  review = false,
  title = "Supporting Documentation of the Diagnosis",
}) => {
  const { Table, Thead, Tbody, Tr, Th, Td } = HeartTable;
  const fileNames = formState.medicalAttachments;

  const customIcon = (
    <img src={IconUploadImage} alt="Custom Icon" height={32} width={32} />
  );

  const handleAttachmentUpload = files => {
    const names = files.map(file => file.name);
    updateFormState({
      medicalAttachments: [...formState.medicalAttachments, ...names],
    });
  };

  const handleAttachmentDelete = nameToDelete => {
    updateFormState({
      medicalAttachments: formState.medicalAttachments.filter(
        name => name !== nameToDelete
      ),
    });
  };

  return (
    <React.Fragment>
      <SurfaceBase
        title={title}
        subtitle={
          review
            ? "Review your attachments"
            : "Include any attachments related to the diagnosis"
        }
        className={styles.medicalAttachmentContainer}
      >
        {fileNames.length > 0 && (
          <Table>
            <Thead>
              <Tr>
                <Th>File Name</Th>
                <Th>Controls</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fileNames.map((name, index) => (
                <Tr key={index}>
                  <Td>{name}</Td>
                  <Td alignRight={true}>
                    <Icons.CloudDownload
                      description="Download"
                      onClick={() => {}}
                    />
                    <Icons.Trash
                      description="Delete"
                      onClick={() => handleAttachmentDelete(name)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        {!review && (
          <React.Fragment>
            <ResolutionOnly medium over>
              <div style={{ width: "60%" }}>
                <Upload
                  multiple
                  onUpload={handleAttachmentUpload}
                  customIcon={customIcon}
                />
              </div>
            </ResolutionOnly>
            <ResolutionOnly medium under>
              <div style={{ width: "100%" }}>
                <Upload
                  multiple
                  onUpload={handleAttachmentUpload}
                  customIcon={customIcon}
                />
              </div>
            </ResolutionOnly>
          </React.Fragment>
        )}
      </SurfaceBase>
    </React.Fragment>
  );
};

export default MedicalAttachments;
