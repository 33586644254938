import {
  Surface,
  Text,
  HeartTable,
  Flex,
  Button,
  Icons,
  InputText,
  Link,
  InputDropdown,
} from "@heart/components";
import PropTypes from "prop-types";
import { newCaseActivityPath } from "routes";

import styles from "./ReviewInvestigationPage.module.scss";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const CaseActivity = ({ referralId, activity }) => (
  <div className={styles.caseActivityLayout}>
    {console.log(activity)}
    <Surface title="Attachments" hideTitle className={styles.surfaceContainer}>
      <Flex align="center" justify="space-between">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Case Activity
        </Text>
        <Button
          icon={Icons.Plus}
          variant="primary"
          href={newCaseActivityPath(referralId)}
        >
          New Case Activity
        </Button>
      </Flex>
      <Flex justify="space-between" align="center">
        <div className={styles.searchInputContainer}>
          <Icons.Search />
          <InputText
            labeledExternally="searchLabel"
            id="searchInput"
            placeholder="Search by keywords"
            className={styles.searchInput}
          />
        </div>
        <Flex gap="300" align="center">
          <Text textStyle="body-100" textColor="neutral-600">
            Filter by:
          </Text>
          <Flex gap="100">
            <InputDropdown
              id="filter"
              blankOptionText="Select Filter"
              values={[
                ["The Thing", "the-thing-value"],
                ["The Other Thing", "the-other-thing-value"],
              ]}
            />
            <InputDropdown
              id="name"
              blankOptionText="A-Z"
              values={[
                ["The Thing", "the-thing-value"],
                ["The Other Thing", "the-other-thing-value"],
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>Activity Type</Th>
            <Th>Timestamp</Th>
            <Th>Agent</Th>
            <Th>Done by</Th>
            <Th>Controls</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Case Assignment</Td>
            <Td>07/27/24 09:39:34</Td>
            <Td>
              <Link href="#">Layla Albright</Link>
            </Td>
            <Td>
              Case Status changed to In Process <br />
              Assigned Case to <Link href="#">Layla Albright</Link>
            </Td>
            <Td></Td>
          </Tr>
          <Tr>
            <Td>Report Created</Td>
            <Td>07/27/24 09:39:34</Td>
            <Td>
              <Link href="#">Gabriela Johnson</Link>
            </Td>
            <Td>Created via Reporter Portal</Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
    </Surface>
  </div>
);
CaseActivity.propTypes = {
  referralId: PropTypes.string,
  activity: PropTypes.array,
};

export default CaseActivity;
