import { Modal, Flex, InputText } from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import styles from "../HomePage.module.scss";

const ResourceModal = ({ open = false, onCancel, setResources }) => {
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");

  const handleSave = () => {
    setResources(resources => [...resources, { href: url, text: title }]);
    closeForm();
  };

  const closeForm = () => {
    setUrl("");
    setTitle("");
    onCancel?.();
  };

  return (
    <Modal
      hidden={!open}
      title="Add New Resource"
      onCancel={closeForm}
      onSubmit={handleSave}
      cancelText="Cancel"
      submitText="Save"
      className={styles.modal}
    >
      <Flex column gap="300">
        <InputText label="URL" onChange={setUrl} value={url} />
        <InputText
          label="Title of Resource"
          onChange={setTitle}
          value={title}
        />
      </Flex>
    </Modal>
  );
};

ResourceModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  setResources: PropTypes.func.isRequired,
};

export default ResourceModal;
