import { Button, Flex, Icons, Link, Surface } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import styles from "../HomePage.module.scss";

const HomePageResources = ({ setOpenModal, resources = [] }) => (
  <Surface
    title="Resources"
    className={styles.surfaceFill}
    gap="200"
    bonusContent={<Icons.Pencil onClick={() => {}} />}
  >
    <Flex column justify="start">
      {resources.map((link, index) => (
        <Link key={index} href={link.href} target="_blank">
          {link.text}
        </Link>
      ))}
    </Flex>
    <Button className={styles.addNewButton} onClick={() => setOpenModal(true)}>
      Add New
    </Button>
  </Surface>
);

export default HomePageResources;

HomePageResources.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};
