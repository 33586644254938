import I18n from "i18n-js";
import PropTypes from "prop-types";

import NestedMultiFormInput from "@components/inputs/nested_multi/NestedMultiFormInput";

import { COMPLETE_ETHNICITY_OPTIONS } from "@root/constants";

import LiveRegion from "../live_region/LiveRegion";
import { useInputError, useInputId } from "./common";

/** An input for ethnicities! This input uses our complete list of ethnicity
 * options by default, and renders a `NestedMultiFormInput`.
 */
const InputEthnicities = ({
  title,
  ethnicityOptions = COMPLETE_ETHNICITY_OPTIONS,
  selectedOptions,
  onChange,
  error,
  ...props
}) => {
  // const result = JSON.stringify(ethnicityOptions, null, 2);
  // console.log(result);
  const errorMessage = useInputError({ error });
  const id = useInputId(props);
  return (
    <LiveRegion>
      <NestedMultiFormInput
        label={title || I18n.t("views.common.ethnicity")}
        id={id}
        options={ethnicityOptions}
        selectedOptions={selectedOptions}
        onSelectedOptionsChange={onChange}
      />
      {errorMessage}
    </LiveRegion>
  );
};
InputEthnicities.propTypes = {
  /** A list of ethnicities with which to populate the input. By default
   * the component will use our complete set of ethnicity options
   */
  ethnicityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        children: PropTypes.array,
      }),
    })
  ),
  /** The options that have been selected from the list. Should be a
   * variable held in state that gets updated in the onChange
   */
  selectedOptions: PropTypes.object,
  /** What should happen when a user checks or unchecks options */
  onChange: PropTypes.func.isRequired,
  /** An error message which will be displayed if present */
  error: PropTypes.string,
  title: PropTypes.string,
};

export default InputEthnicities;
