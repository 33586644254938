import {
  Surface,
  Text,
  Button,
  Flex,
  If,
  SurfaceForm,
  Actions,
  InputFilterable,
  InputCheckbox,
  HeartTable,
  Icons,
} from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

const { Table, Thead, Tbody, Tr, Th, Td } = HeartTable;

const allegationsLabel = {
  physicalAbuse: "Physical Abuse",
  emotionalAbuse: "Emotional Abuse",
  verbalAbuse: "Verbal abuse",
  publicExposure: "Humiliation or public exposure",
  corruptionOrExploitation: "Corruption or Exploitation",
  isolation: "Isolation or Emotional neglect",
  sexualAbuse: "Sexual Abuse",
  neglect: "Neglect",
};

const Allegations = ({
  value,
  onChange,
  abuserOptions = [],
  victimOptions = [],
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [allegations, setAllegations] = useState(value || []);
  const [showEmotionalAbuseOpts, setShowEmotionalAbuseOpts] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const allegationData = {
      allegedAbuser: formData.get("allegedAbuser"),
      allegedVictim: formData.get("allegedVictim"),
      allegationsMade: formData.getAll("allegationsMade"),
    };

    onChange?.([...allegations, allegationData]);
    setAllegations(a => [...a, allegationData]);

    setIsAdding(false);
    setShowEmotionalAbuseOpts(false);
  };

  const resetFormStates = () => {
    setIsAdding(false);
    setShowEmotionalAbuseOpts(false);
    setAllegations(value || []);
  };

  return (
    <Surface hideTitle title="Allegations" sectionId="allegations">
      <Flex column gap="300" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Allegations
        </Text>
        <If condition={allegations.length > 0}>
          <Table>
            <Thead>
              <Tr>
                <Th>Alleged Abuser(s)</Th>
                <Th>Alleged Victim(s)</Th>
                <Th>Allegations</Th>
                <Th>Controls</Th>
              </Tr>
            </Thead>
            <Tbody>
              {allegations.map((allegation, i) => (
                <Tr key={i}>
                  <Td>{allegation.allegedAbuser}</Td>
                  <Td>{allegation.allegedVictim}</Td>
                  <Td>
                    {allegation.allegationsMade
                      .map(a => allegationsLabel[a])
                      .join(", ")}
                  </Td>
                  <Td alignRight>
                    <Flex justify="end">
                      <Icons.Edit onClick={() => {}} />
                      <Icons.Trash
                        onClick={() => {
                          const newAllegations = [...allegations];
                          newAllegations.splice(i, 1);
                          setAllegations([...newAllegations]);
                          onChange?.([...newAllegations]);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </If>
        <If condition={isAdding}>
          <SurfaceForm
            title="New Person"
            hideTitle
            onSubmit={onSubmit}
            actions={
              <Actions primaryText="Save" cancelAction={resetFormStates} />
            }
          >
            <Flex column gap="200" fullWidth>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                New Allegation
              </Text>
              <InputFilterable
                id="allegedAbuser"
                label="Alleged Abuser"
                name="allegedAbuser"
                required
                fullWidth
                values={abuserOptions}
              />
              <InputFilterable
                id="allegedVictim"
                label="Alleged Victim"
                name="allegedVictim"
                required
                fullWidth
                values={victimOptions}
              />
              <Flex column>
                <InputCheckbox
                  id="physicalAbuse"
                  name="allegationsMade"
                  htmlValue="physicalAbuse"
                  label="Physical Abuse"
                />
                <InputCheckbox
                  id="emotionalAbuse"
                  name="allegationsMade"
                  htmlValue="emotionalAbuse"
                  label="Emotional Abuse"
                  onChange={val => setShowEmotionalAbuseOpts(val)}
                />
                <If condition={showEmotionalAbuseOpts}>
                  <Flex column style={{ paddingLeft: 35 }}>
                    <InputCheckbox
                      id="verbalAbuse"
                      name="allegationsMade"
                      htmlValue="verbalAbuse"
                      label="Verbal abuse"
                    />
                    <InputCheckbox
                      id="publicExposure"
                      name="allegationsMade"
                      htmlValue="publicExposure"
                      label="Humiliation or public exposure"
                    />
                    <InputCheckbox
                      id="corruptionOrExploitation"
                      name="allegationsMade"
                      htmlValue="corruptionOrExploitation"
                      label="Corruption or Exploitation"
                    />
                    <InputCheckbox
                      id="isolation"
                      name="allegationsMade"
                      htmlValue="isolation"
                      label="Isolation or Emotional neglect"
                    />
                  </Flex>
                </If>
                <InputCheckbox
                  id="sexualAbuse"
                  name="allegationsMade"
                  htmlValue="sexualAbuse"
                  label="Sexual Abuse"
                />
                <InputCheckbox
                  id="neglect"
                  name="allegationsMade"
                  htmlValue="neglect"
                  label="Neglect"
                />
              </Flex>
            </Flex>
          </SurfaceForm>
        </If>
        <Button
          variant="secondary"
          disabled={isAdding}
          onClick={() => setIsAdding(true)}
        >
          Add Allegation
        </Button>
      </Flex>
    </Surface>
  );
};

Allegations.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  abuserOptions: PropTypes.array,
  victimOptions: PropTypes.array,
};

export default Allegations;
