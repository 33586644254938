import { Surface, Upload, HeartTable, Icons } from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import styles from "../../intake_portal/MaltreatmentReport.module.scss";
import IconUploadImage from "./icon-image-plus.svg";

// @Mario -  make sure your formState and updateFormState points to your function that updates the form state with your page if you're re-using
// see /components/intake_portal/NewMaltreatmentReportPage.js for an example of how I'm using it

const Attachments = ({
  formState,
  updateFormState,
  review = false,
  title = "Attachments",
}) => {
  const { Table, Thead, Tbody, Tr, Th, Td } = HeartTable;
  const fileNames = formState.attachments;

  const customIcon = (
    <img src={IconUploadImage} alt="Custom Icon" height={32} width={32} />
  );

  const handleAttachmentUpload = files => {
    const names = files.map(file => file.name);
    updateFormState({
      attachments: [...formState.attachments, ...names],
    });
  };

  const handleAttachmentDelete = nameToDelete => {
    updateFormState({
      attachments: formState.attachments.filter(name => name !== nameToDelete),
    });
  };

  return (
    <React.Fragment>
      <Surface
        title={title}
        subtitle={
          review
            ? "Review your attachments"
            : "Include any attachments related to the incident"
        }
        className={styles.surfaceContainer}
      >
        {fileNames.length > 0 && (
          <Table>
            <Thead>
              <Tr>
                <Th>File Name</Th>
                <Th>Controls</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fileNames.map((name, index) => (
                <Tr key={index}>
                  <Td>{name}</Td>
                  <Td alignRight={true}>
                    <Icons.CloudDownload
                      description="Download"
                      onClick={() => {}}
                    />
                    <Icons.Trash
                      description="Delete"
                      onClick={() => handleAttachmentDelete(name)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        {!review && (
          <React.Fragment>
            <ResolutionOnly medium over>
              <div style={{ width: "60%" }}>
                <Upload
                  multiple
                  onUpload={handleAttachmentUpload}
                  customIcon={customIcon}
                />
              </div>
            </ResolutionOnly>
            <ResolutionOnly medium under>
              <div style={{ width: "100%" }}>
                <Upload
                  multiple
                  onUpload={handleAttachmentUpload}
                  customIcon={customIcon}
                />
              </div>
            </ResolutionOnly>
          </React.Fragment>
        )}
      </Surface>
    </React.Fragment>
  );
};

Attachments.propTypes = {
  formState: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  updateFormState: PropTypes.func.isRequired,
  review: PropTypes.bool,
  title: PropTypes.string,
};

export default Attachments;
