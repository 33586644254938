import { flatten } from "lodash";
import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";

import useFormState from "@components/shared/hooks/useFormState";

const InvestigationContext = createContext();

export const useInvestigation = () => useContext(InvestigationContext);

export const InvestigationProvider = ({ children, initialData }) => {
  const { referralName } = initialData;
  const [currentUser, setCurrentUser] = useState(initialData.currentUser);
  const [referralId, setReferralId] = useState(initialData.referralId);
  const [reportTimestamp, setReportTimestamp] = useState(
    initialData.reportTimestamp
  );
  const [responseRecommendation, setResponseRecommendation] = useState(
    initialData.responseRecommendation
  );
  const [assignments, setAssignments] = useState(initialData.assignments);
  const [safetyConcerns, setSafetyConcerns] = useState(
    initialData.safetyConcerns
  );
  const [allegationsAndFindings, setAllegationsAndFindings] = useState(
    initialData.allegationsAndFindings ||
      flatten(
        (initialData.allegations || []).map(
          ({ allegationsMade, allegedAbuser, allegedVictim }) =>
            (allegationsMade || []).map(allegationMade => ({
              allegationMade,
              allegedAbuser,
              allegedVictim,
            }))
        )
      )
  );
  const { formState, updateFormState } = useFormState({
    persons: initialData.persons || [
      {
        role: ["victim"],
        name: "Liam",
        middleName: "",
        lastName: "Brown",
        suffix: "",
        isNameUnknown: false,
        dob: "2019-04-19",
        isDobEstimate: false,
        phoneNumber: "",
        email: "",
        sex: "male",
        isVictimInSchool: "Yes",
        tribalAffiliation: "Unknown",
        physicalDescriptor: "3'7\" 45lb Dark blonde hair missing 1 front tooth",
        personIsDeceased: false,
      },
      {
        role: ["caretaker", "alleged-abuser"],
        name: "Rachel",
        middleName: "",
        lastName: "Brown",
        suffix: "",
        isNameUnknown: false,
        dob: "1991-10-13",
        isDobEstimate: false,
        phoneNumber: "(304)304-3688",
        email: "",
        sex: "female",
        isVictimInSchool: "No",
        tribalAffiliation: "Unknown",
        physicalDescriptor:
          "5'1\" 130lbs dark hair pulled back sunspots on face",
        personIsDeceased: false,
      },
    ],
    relations: initialData.relations || [],
    relatedCases: [],
    attachments: initialData.attachments,
    allegationsAndFindings,
    ...initialData,
  });

  return (
    <InvestigationContext.Provider
      value={{
        formState,
        updateFormState,
        currentUser,
        setCurrentUser,
        referralName,
        referralId,
        setReferralId,
        reportTimestamp,
        setReportTimestamp,
        responseRecommendation,
        setResponseRecommendation,
        assignments,
        setAssignments,
        safetyConcerns,
        setSafetyConcerns,
        allegationsAndFindings,
        setAllegationsAndFindings,
      }}
    >
      {children}
    </InvestigationContext.Provider>
  );
};

InvestigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialData: PropTypes.shape({
    persons: PropTypes.arrayOf(PropTypes.object),
    relations: PropTypes.array,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      role_description: PropTypes.string,
    }),
    referralName: PropTypes.string,
    referralId: PropTypes.string,
    reportTimestamp: PropTypes.string,
    responseRecommendation: PropTypes.shape({
      response_level: PropTypes.string,
      response: PropTypes.string,
      reasoning: PropTypes.string,
    }),
    assignments: PropTypes.shape({
      region: PropTypes.string,
      primary_worker_assigned: PropTypes.string,
      secondary_worker_assigned: PropTypes.string,
      reviewer_assigned: PropTypes.string,
    }),
    safetyConcerns: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string),
      comments: PropTypes.string,
    }),
    allegations: PropTypes.arrayOf(
      PropTypes.shape({
        allegedAbuser: PropTypes.string,
        allegedVictim: PropTypes.string,
        allegationsMade: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    allegationsAndFindings: PropTypes.arrayOf(
      PropTypes.shape({
        allegedAbuser: PropTypes.string,
        allegedVictim: PropTypes.string,
        allegationMade: PropTypes.string,
      })
    ),
    attachments: PropTypes.arrayOf(PropTypes.string),
  }),
};
