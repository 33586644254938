import {
  ContentTabs,
  Surface,
  Breadcrumbs,
  Button,
  Flex,
  Text,
  Icons,
} from "@heart/components";
import React from "react";

import styles from "./CreateDashboardLayout.module.scss";

const CreateDashboardLayout = ({
  children,
  breadcrumb,
  pageTitle,
  newButton = <></>,
}) => (
  <div className={styles.container}>
    <Flex column>
      <Flex row gap="300">
        <Breadcrumbs pages={breadcrumb} />
      </Flex>

      <Flex row justify="space-between" align="center" className={styles.title}>
        <Text textColor="neutral-600" textStyle="emphasis-300">
          {pageTitle}
        </Text>

        {newButton}
      </Flex>

      <Surface
        hideTitle
        title={"Dashboard Surface"}
        className={styles.surfaceContainer}
      >
        {children}
      </Surface>
    </Flex>
  </div>
);

export default CreateDashboardLayout;
