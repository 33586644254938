/**
 * Parses the ethnicity JSON and returns a comma-separated string of selected races
 * @param {string} selectedRacesStr - The stringified object of selected races
 * @returns {string} A comma-separated string of selected ethnic labels
 */
export const displaySelectedRaces = selectedRacesStr => {
  // Parse selected races string
  const selectedRaces = JSON.parse(selectedRacesStr);

  // Get all selected paths
  const selectedPaths = Object.keys(selectedRaces).filter(
    key => selectedRaces[key] !== undefined
  );

  // Filter out parent paths when children are selected
  const deepestPaths = selectedPaths.filter(
    path =>
      !selectedPaths.some(
        otherPath => otherPath !== path && otherPath.startsWith(`${path}.`)
      )
  );

  // Recursive function to find label for a given path
  const findLabelByPath = path => {
    // Special handling for "other" with custom value
    if (path === "other" && selectedRaces[path]) {
      return `Not Listed Above: ${selectedRaces[path]}`;
    }

    const pathParts = path.split(".");
    let label = null;

    const findInChildren = (items, parts) => {
      if (!items || parts.length === 0) return null;

      const currentPart = parts[0];
      const found = items.find(item => item.key === currentPart);

      if (!found) return null;

      if (parts.length === 1) {
        return found.label;
      }

      return findInChildren(found.children, parts.slice(1));
    };

    label = findInChildren(window.Binti.RACE_OPTIONS, pathParts);
    return label;
  };

  // Map deepest paths to their labels
  const selectedLabels = deepestPaths
    .map(findLabelByPath)
    .filter(label => label !== null);

  // Remove duplicates while preserving order
  const uniqueLabels = [...new Set(selectedLabels)];

  // Special handling for 'Decline to state' and 'Unknown'
  if (uniqueLabels.length === 0) return "";
  if (uniqueLabels.includes("Decline to state")) return "Decline to state";
  if (uniqueLabels.includes("Unknown")) return "Unknown";

  // Return the labels joined by comma
  return uniqueLabels.join(", ");
};

/**
 * Parses the ethnicity JSON and returns a comma-separated string of selected ethnicities
 * @param {string} selectedRacesStr - The stringified object of selected ethnicities
 * @returns {string} A comma-separated string of selected ethnic labels
 */
export const displaySelectedEthnicities = selectedEthnicitiesStr => {
  // Parse selected ethnicities string
  const selectedEthnicities = JSON.parse(selectedEthnicitiesStr);

  // Get all selected paths
  const selectedPaths = Object.keys(selectedEthnicities).filter(
    key => selectedEthnicities[key] !== undefined
  );

  // Filter out parent paths when children are selected
  const deepestPaths = selectedPaths.filter(
    path =>
      !selectedPaths.some(
        otherPath => otherPath !== path && otherPath.startsWith(`${path}.`)
      )
  );

  // Recursive function to find label for a given path
  const findLabelByPath = path => {
    const pathParts = path.split(".");
    let label = null;

    const findInChildren = (items, parts) => {
      if (!items || parts.length === 0) return null;

      const currentPart = parts[0];
      const found = items.find(item => item.key === currentPart);

      if (!found) return null;

      if (parts.length === 1) {
        const lab = found.label;
        return lab.startsWith("Yes - ") ? lab.substring(6) : lab;
      }

      return findInChildren(found.children, parts.slice(1));
    };

    label = findInChildren(window.Binti.NEW_ETHNICITY_OPTIONS, pathParts);
    return label;
  };

  // Map deepest paths to their labels
  const selectedLabels = deepestPaths
    .map(findLabelByPath)
    .filter(label => label !== null);

  // Remove duplicates while preserving order
  const uniqueLabels = [...new Set(selectedLabels)];

  // Special handling for 'Decline to state' and 'Unknown'
  if (uniqueLabels.length === 0) return "";
  if (uniqueLabels.includes("Decline to state")) return "Decline to state";
  if (uniqueLabels.includes("Unknown")) return "Unknown";

  // Return the labels joined by comma
  return uniqueLabels.join(", ");
};
