import { Flex, Text } from "@heart/components";
import * as RxSwitch from "@radix-ui/react-switch";
import PropTypes from "prop-types";
import { forwardRef, useState } from "react";

import { useGeneratedId } from "@lib/generateId";

import styles from "./Switch.module.scss";

const Switch = forwardRef(
  (
    {
      label,
      name,
      required,
      value,
      onChange,
      textColor = "supporting-100",
      textStyle = "neutral-600",
    },
    ref
  ) => {
    const switchId = useGeneratedId();
    const labelId = useGeneratedId();
    const [val, setVal] = useState(value || false);

    const onCheckedChange = v => {
      setVal(v);
      onChange?.(v);
    };

    return (
      <Flex gap="100" align="center">
        <RxSwitch.Root
          checked={val}
          onCheckedChange={onCheckedChange}
          ref={ref}
          id={switchId}
          className={styles.switchContainer}
          name={name}
          required={required}
          aria-labelledby={labelId}
        >
          <RxSwitch.Thumb className={styles.switchThumb} />
        </RxSwitch.Root>
        <Text
          as="label"
          textStyle={textStyle}
          textColor={textColor}
          htmlFor={switchId}
          id={labelId}
        >
          {label}
        </Text>
      </Flex>
    );
  }
);

Switch.displayName = "Switch";

Switch.propTypes = {
  /** Label that will render at the right of the switch, it's required for accessibility purposes
   */
  label: PropTypes.string.isRequired,
  /** HTML input name attribute */
  name: PropTypes.string,
  /** The initial value (uncontrolled) of this switch, or the current value (controlled). */
  value: PropTypes.bool,
  /** `onChange` is invoked with the switch value as the only argument */
  onChange: PropTypes.func,
  /** To indicate if this input is required */
  required: PropTypes.bool,
};

export default Switch;
