import { useState } from "react";

const useFormState = initialValue => {
  const [formState, setFormState] = useState(initialValue);
  const updateFormState = newState => {
    // console.log("Updating form state with:", newState); // Debugging log
    setFormState(prevState => {
      const updatedState = {
        ...prevState,
        ...newState,
      };
      // console.log("Updated form state:", updatedState); // Debugging log
      return updatedState;
    });
  };

  return {
    updateFormState,
    formState,
  };
};

export default useFormState;
