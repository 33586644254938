import { InputDropdown, InputTextarea, Surface } from "@heart/components";
import React from "react";

import HtmlEditor from "@components/inputs/HtmlEditor";
import sharedStyles from "@components/shared/styles/SharedStyles.module.scss";

import { useCaseActivity } from "../CaseActivityContext";
import {
  faceToFaceActivityOptions,
  locationOptions,
  methodOfEngagementOptions,
  videoCallActivityOptions,
} from "./ActivityOptions";
import EventInformation from "./EventInformation";

const ActivityDetails = () => {
  const { formState, updateFormState } = useCaseActivity();

  const handleMethodOfEngagementSelect = selectedOption => {
    updateFormState({
      methodOfEngagement: selectedOption,
    });

    if (selectedOption !== "face_to_face") {
      updateFormState({
        location: "",
      });
    }

    if (selectedOption !== "face_to_face" && selectedOption !== "video_call") {
      updateFormState({
        activityType: "",
      });
    }
  };

  const handleLocationSelect = selectedOption => {
    updateFormState({
      location: selectedOption,
    });
  };

  const handleActivityTypeSelect = selectedOption => {
    updateFormState({
      activityType: selectedOption,
    });
  };

  const handleActivityDetailsChange = value => {
    updateFormState({
      activityDetails: value,
    });
  };

  const getActivityTypeOptions = () => {
    if (formState.methodOfEngagement === "face_to_face") {
      return faceToFaceActivityOptions;
    }
    if (formState.methodOfEngagement === "video_call") {
      return videoCallActivityOptions;
    }
    return [];
  };

  return (
    <Surface
      title={"Activity Details"}
      className={sharedStyles.surfaceContainer}
    >
      <InputDropdown
        label={"Method of Engagement"}
        name="methodOfEngagement"
        values={methodOfEngagementOptions}
        value={formState.methodOfEngagement}
        onChange={handleMethodOfEngagementSelect}
        required
        hideBlank
      />

      {formState.methodOfEngagement === "face_to_face" && (
        <InputDropdown
          label={"Location"}
          name="location"
          values={locationOptions}
          value={formState.location}
          onChange={handleLocationSelect}
          required={formState.methodOfEngagement === "face_to_face"}
          hideBlank
        />
      )}

      {(formState.methodOfEngagement === "face_to_face" ||
        formState.methodOfEngagement === "video_call") && (
        <InputDropdown
          label={"What was the activity?"}
          name="activityType"
          values={getActivityTypeOptions()}
          value={formState.activityType}
          onChange={handleActivityTypeSelect}
          required
          hideBlank
        />
      )}

      <EventInformation />

      <HtmlEditor
        name="activityDetails"
        label="Notes about Activity"
        required
        value={formState.activityDetails}
        onChange={handleActivityDetailsChange}
      />

      <InputTextarea
        id="recordingTranscript"
        name="recordingTranscript"
        label="Recording Transcript"
        readOnly
        value={formState.recordings.map(({ text }) => text).join("\n\n")}
      />
    </Surface>
  );
};

export default ActivityDetails;
