import { InputCheckbox } from "@heart/components";
import classNames from "classnames";
import { compact, isEmpty, isObject } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";

import {
  inputCommonPropTypes,
  InputGroupLayout,
  inputGroupCommonPropTypes,
} from "./common";
import styles from "./common/RadioAndCheckboxCommonStyles.module.scss";
import {
  htmlTrueFalseToJavascript,
  javascriptTrueFalseToHtml,
} from "./common/trueFalseConversion";

/**
 * A collection of radio inputs to allow the user to choose one of many values.
 * This input is similar in function to a dropdown, but it is especially useful
 * when the user is served by seeing all options before choosing one, and when the
 * options' descriptions are too long to fit in a dropdown.
 */
const InputRadioGroup = props => {
  const { onChange, value, values, required, overrideCheckbox } = props;

  if (required && !props.name && !onChange) {
    // This is an uncontrolled component, but required was specified without
    // a name. Be noisy about this.
    throw new Error(
      "no name provided! browser validation is not enforced on required radio inputs without a name"
    );
  }

  const [radioValue, setRadioValue] = useState(value);
  useEffect(() => {
    setRadioValue(value);
    if (value && overrideCheckbox) overrideCheckbox.setEnabled(true);
  }, [value, overrideCheckbox]);

  const handleChange = e => {
    const javascriptValue = htmlTrueFalseToJavascript(e.target.value);
    setRadioValue(javascriptValue);
    if (onChange) onChange(javascriptValue);
  };

  if (isEmpty(values) && overrideCheckbox)
    return <InputCheckbox label={overrideCheckbox.label} disabled />;

  return (
    <InputGroupLayout
      spacing="roomy"
      {...props}
      inputsComponent={({ disabled, name }) => (
        <Fragment>
          <If condition={overrideCheckbox}>
            <InputCheckbox
              label={overrideCheckbox.label}
              value={overrideCheckbox.enabled}
              onChange={checked => {
                overrideCheckbox.setEnabled(checked);
                /** Structuring our data to match an event so we can reuse handleChange */
                if (!checked) handleChange({ target: { value: "" } });
              }}
            />
          </If>
          {/** required, data-testid, and ID are applicable for a single input;
           * since this component is a collection of inputs, we discard them */}
          {values.map(option => {
            const optionValue = isObject(option) ? option.value : option;
            const subOptions = isObject(option) ? option.subOptions || [] : [];
            return (
              <Fragment key={optionValue}>
                <li>
                  <label className={styles.radioCheckContainer}>
                    <input
                      type="radio"
                      name={name}
                      checked={radioValue === optionValue}
                      value={javascriptTrueFalseToHtml(optionValue)}
                      onChange={handleChange}
                      disabled={
                        (overrideCheckbox && !overrideCheckbox?.enabled) ||
                        disabled ||
                        option.disabled
                      }
                      className={
                        /** only adding class if it's truthy for a cleaner DOM */
                        classNames({
                          [styles.indentAllInputs]: overrideCheckbox,
                        }) || undefined
                      }
                      // only render required if it's truthy for a cleaner DOM
                      {...compact({ required })}
                    />
                    {option.label || option}
                  </label>
                </li>
                {subOptions.map(subOption => (
                  <li key={subOption.value}>
                    <label className={styles.radioCheckContainer}>
                      <input
                        type="radio"
                        name={name}
                        checked={radioValue === subOption.value}
                        value={javascriptTrueFalseToHtml(subOption.value)}
                        onChange={handleChange}
                        disabled={
                          (overrideCheckbox && !overrideCheckbox?.enabled) ||
                          disabled ||
                          subOption.disabled
                        }
                        className={styles.indentSubOptions}
                        // only render required if it's truthy for a cleaner DOM
                        {...compact({ required })}
                      />
                      {subOption.label || subOption}
                    </label>
                  </li>
                ))}
              </Fragment>
            );
          })}
        </Fragment>
      )}
    />
  );
};

InputRadioGroup.propTypes = {
  ...inputCommonPropTypes,
  ...inputGroupCommonPropTypes,
  /** The initial value (uncontrolled) of this input, or the current value (controlled). */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Available options to generate radio options */
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        label: PropTypes.string,
        disabled: PropTypes.bool,
      })
    ),
  ]).isRequired,
  /** `onChange` is invoked with the value selected (just the value) */
  onChange: PropTypes.func,
  /** When provided, adds a checkbox that enables/disables the radio group. The checkbox
   * should be controlled by a state variable in the parent component creating the InputRadioGroup
   */
  overrideCheckbox: PropTypes.shape({
    label: PropTypes.string,
    enabled: PropTypes.bool,
    setEnabled: PropTypes.func,
  }),
};

export default InputRadioGroup;
