import { Flex, Icons, Text } from "@heart/components";
import SurfaceBase from "@heart/components/surface/SurfaceBase";
import PropTypes from "prop-types";

const CollateralCard = ({
  firstName,
  middleName,
  lastName,
  id,
  handleDelete,
}) => (
  <SurfaceBase hideTitle>
    <Flex gap="100" row justify="space-between" align="center">
      <Flex gap="100" row justify="center" align="center">
        <Text textStyle={"emphasis-200"} textColor={"neutral-600"}>
          {firstName} {middleName} {lastName}
        </Text>
      </Flex>

      <Flex gap="100" row justify="space-between" align="center">
        <Icons.Trash
          onClick={() => handleDelete(id)}
          description={"Delete Collateral"}
        />
      </Flex>
    </Flex>
  </SurfaceBase>
);

export default CollateralCard;

CollateralCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleDelete: PropTypes.func,
};
