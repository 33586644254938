import { Surface } from "@heart/components";

import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";
import sharedStyles from "@components/shared/styles/SharedStyles.module.scss";

const links = [
  {
    href: "#case-summary",
    label: "Case Summary",
  },
  {
    href: "#persons",
    label: "Persons",
  },
  {
    href: "#related-cases",
    label: "Related Cases",
  },
  {
    href: "#audit-log",
    label: "Audit Log",
  },
];

const CaseInfo = () => (
  <LayoutSidebarSections links={links}>
    <Surface
      title="Case Summary"
      className={sharedStyles.surfaceContainer}
    ></Surface>
    <Surface
      title="Persons"
      className={sharedStyles.surfaceContainer}
    ></Surface>
    <Surface
      title="Related Cases"
      className={sharedStyles.surfaceContainer}
    ></Surface>
    <Surface
      title="Audit Log"
      className={sharedStyles.surfaceContainer}
    ></Surface>
  </LayoutSidebarSections>
);

export default CaseInfo;
