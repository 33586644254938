import {
  Surface,
  Flex,
  Text,
  InputTextarea,
  Icons,
  InputDropdown,
  Switch,
  Button,
  Pill,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { useInvestigation } from "../investigation_review/InvestigationContext";
import styles from "../investigation_review/ReviewInvestigationPage.module.scss";

const getPillColor = responseLevel => {
  const pillColor =
    responseLevel === "R1"
      ? "warning"
      : responseLevel === "R2"
      ? "alert"
      : "success";

  if (!responseLevel) {
    return "neutral";
  }

  return pillColor;
};

const InvestigationInformationCard = () => {
  const { reportTimestamp, responseRecommendation, referralId, referralName } =
    useInvestigation();

  const pillColor = getPillColor(
    responseRecommendation.recommendedResponseLevel
  );
  return (
    <Fragment>
      <Surface
        title="Intake & Screening Information"
        className={styles.surfaceContainer}
        bonusContent={<Button variant="secondary"> Edit </Button>}
      >
        <Flex column gap="300">
          <Flex row gap="300" align="center">
            <Text
              className={styles.investigationKey}
              textStyle="emphasis-100"
              textColor="neutral-600"
            >
              Referral Name:
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {referralName}
            </Text>
          </Flex>
          <Flex row gap="300" align="center">
            <Text
              className={styles.investigationKey}
              textStyle="emphasis-100"
              textColor="neutral-600"
            >
              Report ID:
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {referralId}
            </Text>
          </Flex>

          <Flex row gap="300" align="center">
            <Text
              className={styles.investigationKey}
              textStyle="emphasis-100"
              textColor="neutral-600"
            >
              Report Timestamp:
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {reportTimestamp}
            </Text>
          </Flex>

          <Flex row gap="300" align="center">
            <Text
              className={styles.investigationKey}
              textStyle="emphasis-100"
              textColor="neutral-600"
            >
              Response
            </Text>
            <Flex row gap="100">
              <If condition={responseRecommendation?.recommendedResponseLevel}>
                <Pill
                  text={responseRecommendation.recommendedResponseLevel}
                  variant={pillColor}
                />
              </If>
              <If condition={responseRecommendation?.recommendedResponse}>
                <Pill
                  text={responseRecommendation?.recommendedResponse}
                  variant={pillColor}
                />
              </If>
            </Flex>
          </Flex>

          <Flex row gap="300" align="center">
            <Text
              className={styles.investigationKey}
              textStyle="emphasis-100"
              textColor="neutral-600"
            >
              Recommendation Reasoning
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {responseRecommendation?.reasoning}
            </Text>
          </Flex>
        </Flex>
      </Surface>
    </Fragment>
  );
};

InvestigationInformationCard.propTypes = {
  formState: PropTypes.object,
  updateFormState: PropTypes.func,
};

export default InvestigationInformationCard;
