import { Flex, Button, Icons } from "@heart/components";
import PropTypes from "prop-types";
import * as React from "react";
import { casesPath, newCaseActivityPath } from "routes";

import CaseActivityInvestigations from "@components/investigations/investigation_review/CaseActivityInvestigations";
import ReviewLayout from "@components/shared/layout/review_layout/ReviewLayout";

import CaseInfo from "./case_review/CaseInfo";
import {
  useCaseReview,
  CaseReviewProvider,
} from "./case_review/CaseReviewContext";
import Court from "./case_review/Court";
import Placements from "./case_review/Placements";

const CaseReviewPage = ({
  currentUser,
  referralId,
  caseData,
  caseActivity,
}) => (
  <CaseReviewProvider
    initialData={{
      currentUser,
      referralId,
      caseData,
      caseActivity,
    }}
  >
    <CaseReviewContent />
  </CaseReviewProvider>
);

const CaseReviewContent = () => {
  const { referralId, caseData, caseActivity } = useCaseReview();

  return (
    <ReviewLayout
      pill={{ text: "In Process" }}
      title={`${caseData.referralName}: ${referralId}`}
      breadcrumb={[
        {
          href: "/",
          label: "Home",
        },
        {
          href: casesPath(),
          label: "Case Management",
        },
        {
          href: "#",
          label: referralId,
        },
      ]}
      headerLeftAlignedContent={
        <Flex gap="100">
          <Button variant="secondary">Change Status</Button>
          <Button variant="secondary">Export PDF of Case</Button>
          <Button icon={Icons.Plus} href={newCaseActivityPath(referralId)}>
            Create Case Activity
          </Button>
        </Flex>
      }
      tabs={[
        {
          contents: <CaseInfo />,
          id: "case-info",
          title: "Case Info",
        },
        {
          contents: <Placements />,
          id: "placements",
          title: "Placements",
        },
        {
          contents: <Court />,
          id: "court",
          title: "Court",
        },
        {
          contents: (
            <CaseActivityInvestigations
              activity={caseActivity}
              referralId={referralId}
            />
          ),
          id: "case-activity",
          title: "Case Activity",
        },
      ]}
    />
  );
};
CaseReviewPage.propTypes = {
  currentUser: PropTypes.object,
  referralId: PropTypes.string,
  caseData: PropTypes.object,
  caseActivity: PropTypes.array,
};

export default CaseReviewPage;
