import {
  Surface,
  Text,
  If,
  Flex,
  InputTextarea,
  InputFilterable,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Attachments from "@components/shared/attachments/Attachments";
import NewPersonForm from "@components/shared/person/NewPersonForm";

const IncidentInformation = ({
  step,
  userAuthenticated,
  formState,
  updateFormState,
}) => {
  const [concernType, setConcernType] = useState([]);

  const handleInputChange = (name, value) => {
    updateFormState({
      incidentInfo: {
        ...formState.incidentInfo,
        [name]: value,
      },
    });
  };

  const handlePersonAdd = ({ personDatabaseId, personId, personData }) => {
    updateFormState({
      personDatabaseIds: [
        ...(formState.personDatabaseIds || []),
        personDatabaseId,
      ],
      personIds: [...(formState.personIds || []), personId],
      persons: [...(formState.persons || []), personData],
    });
  };

  return (
    <React.Fragment>
      <If condition={step === 1 && !userAuthenticated}>
        <Surface title="Incident Information" hideTitle>
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Step 2 Incident Information
          </Text>
        </Surface>
      </If>
      <If condition={step === 2 && userAuthenticated}>
        <Surface
          title="Persons Involved"
          hideTitle
          sectionId="persons-involved"
        >
          <Flex gap="200" column align="start">
            <Flex gap="100" column>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Persons Involved
              </Text>
              <Text textStyle="supporting-150" textColor="neutral-500">
                Please list the names and contact information for people
                involved in the incident, including contact information of other
                people that may be able to provide additional information about
                what has happened.
              </Text>
            </Flex>
            <NewPersonForm
              onPersonAdd={handlePersonAdd}
              formState={formState}
              updateWindowsLocation={"#persons-involved"}
              askMilitaryPersonnelInfo
            />
          </Flex>
        </Surface>
        <Surface
          title="Incident Information"
          hideTitle
          sectionId="incident-information"
        >
          <Flex gap="300" column>
            <Flex gap="100" column>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Incident Information
              </Text>
              <Text textStyle="supporting-150" textColor="neutral-500">
                Please fill out the following questions to the best of your
                ability
              </Text>
            </Flex>

            <InputTextarea
              name="childStatus"
              label="Description of Incident(s). What specific incident(s) or behavior(s) raised your concern? Can you describe the time, date and location of the incident(s)? How often have you observed these concerns (on-time occurrence, repeated, ongoing)?"
              description="If the child is in immediate danger, please call 911"
              required
              onChange={value => handleInputChange("childStatus", value)}
            />
            <InputTextarea
              name="abuseDescription"
              label="Have you noticed any physical evidence (e.g., bruises, poor hygiene, lack of food) or heard any concerning statements from the child? What specific behaviors or statements from the child(ren) or others have led to your concerns?"
              description="If the child sustained injuries, include size, color, description, and severity level"
              required
              onChange={value => handleInputChange("abuseDescription", value)}
            />
            <InputTextarea
              name="familyHistory"
              label="How long have you known the family and have you had concerns before?"
              description="If yes, please note specifics as to participants and timeline"
              required
              onChange={value => handleInputChange("familyHistory", value)}
            />
            <InputTextarea
              name="familyStructure"
              label="Please describe how the child or children you are reporting are related to the people in their life. For example, tell us who their parents, siblings, grandparents, or other family members are and how they are connected."
              required
              onChange={value => handleInputChange("familyStructure", value)}
            />
            <InputTextarea
              name="witnesses"
              label="Were there any other children or adults present during the incident(s)? If so, who?"
              required
              onChange={value => handleInputChange("witnesses", value)}
            />
            <InputTextarea
              name="extraInfo"
              label="Is there anything else you would like to share that might be relevant to the child's or children's situation?"
              required
              onChange={value => handleInputChange("extraInfo", value)}
            />
          </Flex>
        </Surface>

        <Attachments formState={formState} updateFormState={updateFormState} />

        <Surface
          title="Worker Safety Concerns"
          hideTitle
          sectionId="work-safety-concerns"
        >
          <Flex gap="300" column>
            <Flex gap="100" column>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Worker Safety Concerns
              </Text>
              <Text textStyle="supporting-150" textColor="neutral-500">
                If a member of DCFS staff were to visit the child(ren)'s home
                are there specific concerns they should be aware of?
              </Text>
            </Flex>
            <InputFilterable
              id="concernType"
              label="Concern Type"
              name="concernType"
              isMulti
              showSelectAll
              value={concernType}
              onChange={value => {
                setConcernType(value);
                handleInputChange("concernType", value);
              }}
              values={[
                { label: "Weapons", value: "weapons" },
                { label: "Animals", value: "animals" },
                {
                  label: "Violence or Aggression",
                  value: "violence-or-aggression",
                },
                {
                  label: "Environmental hazards",
                  value: "environmental-hazards",
                },
              ]}
            />
            <InputTextarea
              label="Comments"
              name="safetyComments"
              onChange={value => handleInputChange("safetyComments", value)}
            />
          </Flex>
        </Surface>
      </If>
    </React.Fragment>
  );
};

IncidentInformation.propTypes = {
  userAuthenticated: PropTypes.bool,
  step: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  updateFormState: PropTypes.func.isRequired,
};

export default IncidentInformation;
