import { Flex, Icons, Link, Pill, Surface, Text } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import ButtonIcon from "@components/shared/ButtonIcon";
import IconPlus from "@components/shared/assets/icon_plus";

import styles from "../HomePage.module.scss";
import CaseItems from "./CaseItems.png";
import CasePieChart from "./GroupDesign.png";

const CaseRowItem = ({ children }) => (
  <Flex
    row
    align="center"
    justify="space-between"
    className={styles.caseRowItem}
  >
    {children}
  </Flex>
);

const CaseHealthMetric = () => (
  <Surface
    title="Case Health Metrics"
    className={styles.surfaceFillRed}
    gap="200"
    bonusContent={<Icons.ExternalLink onClick={() => {}} />}
  >
    <Flex column>
      <Flex column align="center">
        <img src={CasePieChart} alt="Case Health Metrics" />
        <img src={CaseItems} alt="Case Health Items" />
      </Flex>
      <CaseRowItem>
        <Pill text={"Safety"} variant={"warning"} />
        <Text textStyle="body-100" textColor="neutral-600">
          WA-57067 30 days since last visit
        </Text>
        <ButtonIcon svg={IconPlus} />
      </CaseRowItem>
      <CaseRowItem>
        <Pill text={"Data issue"} variant={"alert"} />
        <Text textStyle="body-100" textColor="neutral-600">
          WA-57045 ICWA not asked
        </Text>
        <ButtonIcon svg={IconPlus} />
      </CaseRowItem>
      <Flex row justify="start">
        <Link>See all suggestions... (6)</Link>
      </Flex>
    </Flex>
  </Surface>
);

export default CaseHealthMetric;

CaseRowItem.propTypes = {
  children: PropTypes.node.isRequired,
};
