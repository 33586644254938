import { Flex, Text, Surface, Link } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./Confirmation.module.scss";

const ConfirmationPage = ({ referralId }) => (
  <div className={styles.wrapper}>
    <div className={styles.background}></div>
    <div className={styles.circles}></div>
    <Flex column gap="300" className={styles.mainContainer}>
      <Surface title="Thank you confirmation" hideTitle>
        <Flex column align="center" gap="300">
          <Flex
            align="center"
            justify="center"
            className={styles.iconContainer}
          >
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="CheckCircle">
                <path
                  id="Vector"
                  d="M53.75 32.5L35.4063 50L26.25 41.25"
                  stroke="#59AE33"
                  strokeWidth="3.80952"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70Z"
                  stroke="#59AE33"
                  strokeWidth="3.80952"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </Flex>
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Thank you
          </Text>
          <Text as="h1" textStyle="emphasis-200" textColor="neutral-600">
            Referral ID: {referralId}
          </Text>
          <Text
            textStyle="emphasis-200"
            textColor="neutral-500"
            style={{ textAlign: "center" }}
          >
            Please save this Referral ID number for your records. If you have
            any questions or need any additional information please call the
            Child Abuse hotline at 1-866-363-4276
          </Text>
        </Flex>
      </Surface>
      <Surface title="Help information" hideTitle gap="200">
        <Text textColor="neutral-600" className={styles.resourcesTitle} as="p">
          Resources
        </Text>
        <Text textColor="neutral-500" textStyle="supporting-150" as="p">
          Need more information? Please review our resources below for any
          additional support
        </Text>
        <ul className={styles.resourcesList}>
          <li>
            <Text style={{ color: "#38393D" }} textStyle="body-100" as="p">
              Support youth in your community by Volunteering as a Court
              Appointed Special Advocate&nbsp;
              <Link href="#">Learn more.</Link>
            </Text>
          </li>
          <li>
            <Text textStyle="body-100">
              <Link href="#">Domestic Violence recovery guide</Link>
            </Text>
          </li>
          <li>
            <Text textStyle="body-100">
              <Link href="#">Mandated Reporter Handbook</Link>
            </Text>
          </li>
          <li>
            <Text textStyle="body-100">
              <Link href="#">Mandated Reporter upcoming training schedule</Link>
            </Text>
          </li>
        </ul>
      </Surface>
    </Flex>
  </div>
);

ConfirmationPage.propTypes = {
  referralId: PropTypes.string,
};

export default ConfirmationPage;
