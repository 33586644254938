import PropTypes from "prop-types";

import RecordingCard from "./RecordingCard";

const RecordingCards = ({ recordings, onNewTranscription, referralId }) => (
  <ul>
    {recordings.map(({ recording }) => (
      <li key={recording}>
        <RecordingCard recording={recording} referralId={referralId} />
      </li>
    ))}
    <li key={`new-recording-${recordings.length}`}>
      <RecordingCard
        onNewTranscription={onNewTranscription}
        referralId={referralId}
      />
    </li>
  </ul>
);

RecordingCards.propTypes = {
  referralId: PropTypes.string.isRequired,
  recordings: PropTypes.arrayOf(PropTypes.string),
  onNewTranscription: PropTypes.func,
};

export default RecordingCards;
