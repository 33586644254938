import {
  ContentTabs,
  Surface,
  Breadcrumbs,
  Button,
  Flex,
  Text,
  Icons,
} from "@heart/components";
import propTypes from "prop-types";
import React from "react";

import styles from "./MaltreatmentReport.module.scss";
import IncidentReportTable from "./MaltreatmentReportArray";
import {
  columnsTabOne,
  columnsTabThree,
  columnsTabTwo,
  dataTabOne,
  dataTabThree,
  dataTabTwo,
} from "./mockData";

const MaltreatmentReports = ({ currentUser, reported, assigned }) => {
  const isSupervisor = currentUser?.roles?.includes("supervisor") ?? false;
  const reportedRows = dataTabOne.concat(reported);
  const assignedRows = isSupervisor
    ? dataTabThree.concat(
        assigned.filter(a =>
          ["intake_supervisor_review", "intake_supervisor_approved"].includes(
            a.caseStatus
          )
        )
      )
    : dataTabTwo.concat(assigned);
  return (
    <div className={styles.container}>
      <Flex column>
        <Flex row gap="300">
          <Breadcrumbs
            pages={[
              {
                href: "#some-url-1",
                label: "Home",
              },
              {
                href: "#some-url-2",
                label: "Children and Youth",
              },
              {
                href: "#some-url-3",
                label: "Maltreatment Reports",
              },
            ]}
          />
        </Flex>

        <Flex
          row
          justify="space-between"
          align="center"
          className={styles.title}
        >
          <Text textColor="neutral-600" textStyle="emphasis-300">
            Maltreatment Reports
          </Text>

          <Button
            badge={0}
            icon={Icons.Plus}
            href="/intake/new"
            variant="primary"
            className={styles.buttonIcon}
          >
            New Maltreatment Report
          </Button>
        </Flex>

        <Surface
          title={"intake-content"}
          hideTitle
          className={styles.surfaceContainer}
        >
          <ContentTabs
            className={styles.customTab}
            tabs={[
              {
                contents: (
                  <IncidentReportTable
                    columns={columnsTabOne}
                    data={reportedRows}
                  />
                ),
                count: reportedRows.length,
                id: "first-tab",
                title: "Portal Reported",
              },
              {
                contents: (
                  <IncidentReportTable
                    columns={isSupervisor ? columnsTabThree : columnsTabTwo}
                    data={assignedRows}
                  />
                ),
                count: assignedRows.length,
                id: "second-tab",
                title: "Assignments",
              },
            ]}
          />
        </Surface>
      </Flex>
    </div>
  );
};
export default MaltreatmentReports;

MaltreatmentReports.propTypes = {
  currentUser: propTypes.object,
  caseStatus: propTypes.string,
  reported: propTypes.array,
  assigned: propTypes.array,
};
