import { Flex, Text, Icons, If } from "@heart/components";
import SurfaceBase from "@heart/components/surface/SurfaceBase";
import PropTypes from "prop-types";

const RelationCard = ({
  person1,
  parentalLineage,
  lineageType,
  relationship,
  paternityEstablished,
  putativeFather,
  id,
  person2,
  handleDelete = () => {},
}) => (
  <SurfaceBase hideTitle>
    <Flex gap="100" row justify="space-between" align="end">
      <Flex gap="50" row justify="center" align="end">
        <Text textStyle={"emphasis-100"} textColor={"neutral-600"}>
          {person1}
        </Text>
        <Text textStyle={"body-100"} textColor={"neutral-500"}>
          is the
        </Text>
        <Text textStyle={"emphasis-100"} textColor={"neutral-600"}>
          {parentalLineage}
        </Text>
        <Text textStyle={"emphasis-100"} textColor={"neutral-600"}>
          {lineageType}
        </Text>
        <If condition={paternityEstablished && paternityEstablished === "Yes"}>
          <Text textStyle={"emphasis-100"} textColor={"neutral-600"}>
            established
          </Text>
        </If>
        <If condition={putativeFather && putativeFather === "Yes"}>
          <Text textStyle={"emphasis-100"} textColor={"neutral-600"}>
            putative
          </Text>
        </If>
        <Text textStyle={"emphasis-100"} textColor={"neutral-600"}>
          {relationship}
        </Text>

        <Text textStyle={"body-100"} textColor={"neutral-500"}>
          to
        </Text>
        <Text textStyle={"emphasis-100"} textColor={"neutral-600"}>
          {person2}
        </Text>
      </Flex>

      <Flex gap="100" row justify="space-between" align="center">
        <Icons.Edit onClick={() => {}} />
        <Icons.Trash onClick={() => handleDelete(id)} />
      </Flex>
    </Flex>
  </SurfaceBase>
);

RelationCard.propTypes = {
  person1: PropTypes.string.isRequired,
  parentalLineage: PropTypes.string.isRequired,
  lineageType: PropTypes.string.isRequired,
  relationship: PropTypes.string.isRequired,
  paternityEstablished: PropTypes.string,
  putativeFather: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  person2: PropTypes.string.isRequired,
  handleDelete: PropTypes.func,
};

export default RelationCard;
