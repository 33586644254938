import {
  Flex,
  ArrayDataTable,
  Button,
  InputText,
  Icons,
  Text,
  Link,
} from "@heart/components";
import React from "react";

import styles from "./MaltreatmentReport.module.scss";
import TextEditIcon from "./assets/icon-text-edit.svg";

const IncidentReportTable = ({ columns, data }) => (
  <React.Fragment>
    {data?.length === 0 ? (
      <Flex column justify="center" align="center" gap="400">
        <img src={TextEditIcon} alt="Text Edit Icon" width="48" height="48" />
        <Flex column gap="200" justify="center" align="center">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            You&apos;re all caught up!
          </Text>
          <Text textStyle="body-100" textColor="neutral-600">
            There are currently no unaddressed reports from the public portal.
          </Text>
        </Flex>
      </Flex>
    ) : (
      <Flex column>
        <Flex row justify="space-between" align="center">
          <InputText
            label="search-incidents"
            hideLabel
            placeholder="Search incidents"
            onChange={() => {}}
            type="search"
          />

          <Button
            icon={Icons.Filter}
            variant="secondary"
            className={styles.customButton}
          >
            Filter
          </Button>
        </Flex>
        <Flex column justify="center" align="center" gap="200">
          <ArrayDataTable columns={columns} data={data} />
          <Text textStyle="body-100">
            <Link href="#">Download CSV</Link>
          </Text>
        </Flex>
      </Flex>
    )}
  </React.Fragment>
);

export default IncidentReportTable;
