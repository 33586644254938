import {
  Surface,
  Flex,
  InputText,
  Switch,
  Text,
  Link,
} from "@heart/components";
import React from "react";

import styles from "../MaltreatmentReport.module.scss";

const ReporterInformation = ({ formState, updateFormState }) => {
  const handleSwitchChange = () => {
    updateFormState({
      reporter: {
        ...formState.reporter,
        anonymous: !formState.reporter.anonymous,
      },
    });
  };

  const handleSearchChange = event => {
    updateFormState(prevState => ({
      reporter: {
        ...prevState.reporter,
        search: event,
      },
    }));
  };

  return (
    <React.Fragment>
      <Surface title="Reporter Information" className={styles.surfaceContainer}>
        <Flex column gap="300">
          <Switch
            label="Reporter is anonymous"
            value={formState.reporter.anonymous}
            onChange={handleSwitchChange}
          />
          {!formState.reporter.anonymous && (
            <Flex column>
              <Text textStyle="supporting-100" textColor="neutral-600">
                Search for Registered Reporters
                <Text textStyle="supporting-100" textColor="danger-500">
                  *
                </Text>
              </Text>
              <Text
                variant="caption"
                textStyle="supporting-50"
                textColor="neutral-600"
              >
                Search using name or unique ID
              </Text>

              <InputText
                placeholder="Search"
                onChange={handleSearchChange}
                type="search"
              />

              <Flex row justify="center" align="center">
                <Link>Add New Person</Link>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Surface>
    </React.Fragment>
  );
};
export default ReporterInformation;
