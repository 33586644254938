const LANGUAGES_OPTIONS = {
  english: "English",
  spanish: "Spanish",
  afrikaans: "Afrikaans",
  albanian: "Albanian",
  american_sign_language: "American Sign Language",
  amharic: "Amharic",
  arabic: "Arabic",
  arapaho: "Arapaho",
  armenian: "Armenian",
  azerbaijani: "Azerbaijani",
  bantu: "Bantu",
  basque: "Basque",
  belarusian: "Belarusian",
  bengali: "Bengali",
  bosnian: "Bosnian",
  bulgarian: "Bulgarian",
  cantonese: "Cantonese",
  cape_verdean_creole: "Cape Verdean Creole",
  carolinian: "Carolinian",
  catalan: "Catalan",
  cebuano: "Cebuano",
  chamorro: "Chamorro",
  cherokee: "Cherokee",
  chichewa: "Chichewa",
  chinese_mandarin: "Chinese (Mandarin)",
  chuukese: "Chuukese",
  corsican: "Corsican",
  croatian: "Croatian",
  czech: "Czech",
  danish: "Danish",
  dutch: "Dutch",
  esperanto: "Esperanto",
  estonian: "Estonian",
  filipino: "Filipino",
  finnish: "Finnish",
  french: "French",
  frisian: "Frisian",
  galician: "Galician",
  georgian: "Georgian",
  german: "German",
  greek: "Greek",
  gujarati: "Gujarati",
  haitian_creole: "Haitian Creole",
  hausa: "Hausa",
  hawaiian: "Hawaiian",
  hebrew: "Hebrew",
  hindi: "Hindi",
  hmong: "Hmong",
  hungarian: "Hungarian",
  icelandic: "Icelandic",
  igbo: "Igbo",
  ilocano: "Ilocano",
  indonesian: "Indonesian",
  irish: "Irish",
  italian: "Italian",
  japanese: "Japanese",
  javanese: "Javanese",
  kannada: "Kannada",
  kazakh: "Kazakh",
  khmer: "Khmer",
  korean: "Korean",
  kurdish_kurmanji: "Kurdish (Kurmanji)",
  kyrgyz: "Kyrgyz",
  lao: "Lao",
  latin: "Latin",
  latvian: "Latvian",
  lithuanian: "Lithuanian",
  luxembourgish: "Luxembourgish",
  macedonian: "Macedonian",
  malagasy: "Malagasy",
  malay: "Malay",
  malayalam: "Malayalam",
  maltese: "Maltese",
  maori: "Maori",
  marathi: "Marathi",
  mongolian: "Mongolian",
  montenegrin: "Montenegrin",
  myanmar_burmese: "Myanmar (Burmese)",
  navajo: "Navajo",
  nepali: "Nepali",
  norwegian: "Norwegian",
  pashto: "Pashto",
  persian_farsi: "Persian/Farsi",
  polish: "Polish",
  portuguese: "Portuguese",
  punjabi: "Punjabi",
  romanian: "Romanian",
  russian: "Russian",
  samoan: "Samoan",
  scots_gaelic: "Scots Gaelic",
  serbian: "Serbian",
  serbo_croatian: "Serbo-Croatian",
  sesotho: "Sesotho",
  shona: "Shona",
  sindhi: "Sindhi",
  sinhala: "Sinhala",
  slovak: "Slovak",
  slovenian: "Slovenian",
  somali: "Somali",
  sundanese: "Sundanese",
  swahili: "Swahili",
  swedish: "Swedish",
  tagalog: "Tagalog",
  tai_kadai: "Tai-Kadai",
  tajik: "Tajik",
  tamil: "Tamil",
  telugu: "Telugu",
  thai: "Thai",
  tigrinya: "Tigrinya",
  tongan: "Tongan",
  turkish: "Turkish",
  ukrainian: "Ukrainian",
  urdu: "Urdu",
  uzbek: "Uzbek",
  vietnamese: "Vietnamese",
  welsh: "Welsh",
  xhosa: "Xhosa",
  yiddish: "Yiddish",
  yoruba: "Yoruba",
  zulu: "Zulu",
};

export default LANGUAGES_OPTIONS;
