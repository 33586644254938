import { Flex, InputRadioGroup, InputTextarea } from "@heart/components";
import * as PropTypes from "prop-types";

const SafetyFactorInputs = ({ formState, handleSafetyFactorChange }) => (
  <Flex column gap="200">
    <InputRadioGroup
      id="physicalHarm"
      label="Caretaker caused serious physical harm to the child and/or made a plausible threat to cause physical harm in the current Investigation/Family Assessment"
      orientation="row"
      required
      name="physicalHarm"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("physicalHarm", value)}
      value={formState.safetyFactors?.physicalHarm}
    />
    <InputTextarea
      label="Comments"
      name="commentsPhysicalHarm"
      onChange={value =>
        handleSafetyFactorChange("commentsPhysicalHarm", value)
      }
      value={formState.safetyFactors?.commentsPhysicalHarm}
    />
    <InputRadioGroup
      id="injuryExplanation"
      label="Caretaker’s explanation for the injury to the child is questionable or inconsistent with the type of injury, and the nature of the injury suggests that the child’s safety may be of immediate concern."
      orientation="row"
      required
      name="injuryExplanation"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("injuryExplanation", value)}
      value={formState.safetyFactors?.injuryExplanation}
    />
    <InputTextarea
      label="Comments"
      name="commentsInjuryExplanation"
      onChange={value =>
        handleSafetyFactorChange("commentsInjuryExplanation", value)
      }
      value={formState.safetyFactors?.commentsInjuryExplanation}
    />
    <InputRadioGroup
      id="alcoholOrDrugs"
      label="There is evidence that the mother used alcohol or other drugs during pregnancy, AND current circumstances suggest the infant’s safety is of immediate concern."
      orientation="row"
      required
      name="alcoholOrDrugs"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("alcoholOrDrugs", value)}
      value={formState.safetyFactors?.alcoholOrDrugs}
    />
    <InputTextarea label="Comments" name="commentsAlcoholOrDrugs" />

    <InputRadioGroup
      id="refusingAccess"
      label="The family is refusing access to the child or there is reason to believe that the family is about to flee, AND available information suggests that child safety is of immediate concern."
      orientation="row"
      required
      name="refusingAccess"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("refusingAccess", value)}
      value={formState.safetyFactors?.refusingAccess}
    />
    <InputTextarea label="Comments" name="commentsRefusingAccess" />

    <InputRadioGroup
      id="lackOfSupervision"
      label="Caretaker does not provide supervision necessary to protect child from potentially serious harm."
      orientation="row"
      required
      name="lackOfSupervision"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("lackOfSupervision", value)}
      value={formState.safetyFactors?.lackOfSupervision}
    />
    <InputTextarea
      label="Comments"
      name="commentsLackOfSupervision"
      onChange={value =>
        handleSafetyFactorChange("commentsLackOfSupervision", value)
      }
      value={formState.safetyFactors?.commentsLackOfSupervision}
    />
    <InputRadioGroup
      id="failureToProtect"
      label="Caretaker fails to protect child from serious physical harm or threatened harm by others."
      orientation="row"
      required
      name="failureToProtect"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("failureToProtect", value)}
      value={formState.safetyFactors?.failureToProtect}
    />
    <InputTextarea label="Comments" name="commentsFailureToProtect" />

    <InputRadioGroup
      id="domesticViolence"
      label="Domestic violence exists in the home, AND circumstances suggest that child safety (physical and/or emotional) is of immediate concern."
      orientation="row"
      required
      name="domesticViolence"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("domesticViolence", value)}
      value={formState.safetyFactors?.domesticViolence}
    />
    <InputTextarea label="Comments" name="commentsDomesticViolence" />

    <InputRadioGroup
      id="basicNeeds"
      label="Caretaker is unwilling or unable to meet the child’s most basic needs (food, clothing, shelter, and/or medical/dental/mental health care), AND this causes the child to be in imminent danger."
      orientation="row"
      required
      name="basicNeeds"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("basicNeeds", value)}
      value={formState.safetyFactors?.basicNeeds}
    />
    <InputTextarea label="Comments" name="commentsBasicNeeds" />

    <InputRadioGroup
      id="hazardousLivingConditions"
      label="Child’s physical living conditions are hazardous and immediately threatening, based on the child’s age and developmental status."
      orientation="row"
      required
      name="hazardousLivingConditions"
      values={["Yes", "No"]}
      onChange={value =>
        handleSafetyFactorChange("hazardousLivingConditions", value)
      }
      value={formState.safetyFactors?.hazardousLivingConditions}
    />
    <InputTextarea
      label="Comments"
      name="commentsHazardousLivingConditions"
      onChange={value =>
        handleSafetyFactorChange("commentsHazardousLivingConditions", value)
      }
      value={formState.safetyFactors?.commentsHazardousLivingConditions}
    />
    <InputRadioGroup
      id="emotionalDistress"
      label="Caretaker actions cause significant and excessive emotional distress for the child, AND available information suggests that child safety is of immediate concern."
      orientation="row"
      required
      name="emotionalDistress"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("emotionalDistress", value)}
      value={formState.safetyFactors?.emotionalDistress}
    />
    <InputTextarea
      label="Comments"
      name="commentsEmotionalDistress"
      onChange={value =>
        handleSafetyFactorChange("commentsEmotionalDistress", value)
      }
      value={formState.safetyFactors?.commentsEmotionalDistress}
    />
    <InputRadioGroup
      id="childSexualAbuse"
      label="Child sexual abuse is suspected, AND circumstances suggest that child safety is an immediate concern."
      orientation="row"
      required
      name="childSexualAbuse"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("childSexualAbuse", value)}
      value={formState.safetyFactors?.childSexualAbuse}
    />
    <InputTextarea label="Comments" name="commentsChildSexualAbuse" />

    <InputRadioGroup
      id="pastMaltreatment"
      label="Current circumstances, combined with information that the caretaker has or likely has seriously maltreated a child in the past, suggests that child safety may be an immediate concern."
      orientation="row"
      required
      name="pastMaltreatment"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("pastMaltreatment", value)}
      value={formState.safetyFactors?.pastMaltreatment}
    />
    <InputTextarea label="Comments" name="commentsPastMaltreatment" />

    <InputRadioGroup
      id="otherSafetyFactors"
      label="Other Safety Factors."
      orientation="row"
      required
      name="otherSafetyFactors"
      values={["Yes", "No"]}
      onChange={value => handleSafetyFactorChange("otherSafetyFactors", value)}
      value={formState.safetyFactors?.otherSafetyFactors}
    />
    <InputTextarea
      label="Comments"
      name="commentsOtherSafetyFactors"
      onChange={value =>
        handleSafetyFactorChange("commentsOtherSafetyFactors", value)
      }
      value={formState.safetyFactors?.commentsOtherSafetyFactors}
    />
  </Flex>
);

SafetyFactorInputs.propTypes = {
  formState: PropTypes.object.isRequired,
  handleSafetyFactorChange: PropTypes.func.isRequired,
};

export default SafetyFactorInputs;
