const get = async (url, data, callback) => {
  let fetchedData;
  await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('[name="csrf-token"]')?.content,
    },
    body: JSON.stringify(data),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(responseData => {
      fetchedData = responseData;
      if (responseData.success && responseData.redirect_url) {
        window.location.href = responseData.redirect_url;
        return;
      }

      callback?.(responseData);
    })
    .catch(error => {
      console.error("Error submitting get:", error);
    });

  return fetchedData;
};

export default get;
