import { Surface } from "@heart/components";

import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";
import sharedStyles from "@components/shared/styles/SharedStyles.module.scss";

const links = [
  {
    href: "#court-hearings",
    label: "Court Hearings",
  },
];

const Court = () => (
  <LayoutSidebarSections links={links}>
    <Surface
      title="Court Heartings"
      className={sharedStyles.surfaceContainer}
    ></Surface>
  </LayoutSidebarSections>
);

export default Court;
