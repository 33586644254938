import { Flex, Link } from "@heart/components";
import React, { useEffect, useRef } from "react";
import { personsPath } from "routes";

export const formatFamilyNamesAsLinks = (persons = []) => {
  const displayNames = persons.slice(0, 3);
  const remainingCount = persons.length - 3;

  return (
    <Flex row style={{ whiteSpace: "nowrap", display: "inline-flex" }}>
      {displayNames.map((person, index) => (
        <Link key={index} href={`${personsPath()}/${person.id}`}>
          {`${person.firstName ?? ""} ${person.lastName ?? ""}`}
          {index < displayNames.length - 1 ? ", " : ""}
        </Link>
      ))}
      {remainingCount > 0 && (
        <span>
          and <Link>{remainingCount} more</Link>
        </span>
      )}
    </Flex>
  );
};

export const formatFamilyNames = (familyInstitutionNames = []) => {
  const displayNames = familyInstitutionNames.slice(0, 3);
  const remainingCount = familyInstitutionNames.length - 3;

  return (
    <Flex row style={{ whiteSpace: "nowrap", display: "inline-flex" }}>
      {displayNames.map((name, index) => (
        <span key={index}>
          {name}
          {index < displayNames.length - 1 ? ", " : ""}
        </span>
      ))}
      {remainingCount > 0 && (
        <span>
          and <Link>{remainingCount} more</Link>
        </span>
      )}
    </Flex>
  );
};

export const formatDate = date => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const addMinutes = (date, minutes) =>
  new Date(date.getTime() + minutes * 60000);

export const getCurrentTime = format => {
  const now = new Date();
  const hours = now.getHours() % 12 || 12;
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const ampm = now.getHours() >= 12 ? "PM" : "AM";

  const formattedTime = format
    .replace("hh", hours.toString().padStart(2, "0"))
    .replace("h", hours)
    .replace("mm", minutes)
    .replace("ss", seconds)
    .replace("AM/PM", ampm);

  return formattedTime;
};

// getCurrentTime("hh:mm:ss AM/PM")); // Output: 03:45:12 PM
// getCurrentTime("h:mm AM/PM")); // Output: 3:45 PM
// getCurrentTime("hh:mm:ss")); // Output: 03:45:12
// getCurrentTime("hh:mm")); // Output: 03:45

// Custom hook to get the previous value of a prop or state
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// Questions to ask auto check

export const questionsToAsk = [
  { key: ["danger", "immediate"], label: "Is the child in immediate danger?" },
  { key: ["day"], label: "When did the incident happen?" },
  { key: ["happened at"], label: "Where did the alleged abuse occur?" },
  {
    key: ["close with"],
    label: "What is the child's relationship like with their family members?",
  },
  {
    key: ["signs of", "alcohol", "fracture"],
    label: "What are signs of abuse or neglect?",
  },
  { key: ["environment"], label: "How is the child's living environment?" },
  { key: ["feeling"], label: "How is the child's emotional well-being?" },
];

export const checkMatchWithKeys = (inputText, callback) => {
  const qIndexes = new Array(questionsToAsk.length).fill(false);

  questionsToAsk.forEach((question, i) => {
    const wholeKeyMatch = question.key.some(key => {
      const regex = new RegExp(`${key}`, "i");
      return regex.test(inputText);
    });

    if (wholeKeyMatch) {
      qIndexes[i] = true;
    }
  });

  callback(qIndexes);
};
