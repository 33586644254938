import {
  Surface,
  Text,
  Button,
  Flex,
  If,
  SurfaceForm,
  Actions,
  InputFilterable,
  HeartTable,
  Icons,
  InputDropdown,
  Pill,
  InputTextarea,
  InputRadioGroup,
} from "@heart/components";
import patch from "@utils/patch";
import { hasSingleRole, roles } from "@utils/roles";
import PropTypes from "prop-types";
import { useState } from "react";
import { investigationPath } from "routes";

import { useInvestigation } from "@components/investigations/investigation_review/InvestigationContext";
import SupervisorApprovalModal from "@components/investigations/investigation_review/SupervisorApprovalModal";

const { Table, Thead, Tbody, Tr, Th, Td } = HeartTable;

export const allegationsLabel = {
  physicalAbuse: "Physical Abuse",
  emotionalAbuse: "Emotional Abuse",
  sexualAbuse: "Sexual Abuse",
  neglect: "Neglect",
  medicalNeglect: "Medical Neglect",
};

export const subAllegationLabel = {
  verbalAbuse: "Verbal Abuse",
  publicExposure: "Humiliation or Public Exposure",
  corruptionOrExploitation: "Corruption or Exploitation",
  isolation: "Isolation or Emotional Neglect",
};

export const subOptionsForAllegation = {
  emotionalAbuse: [
    "verbalAbuse",
    "publicExposure",
    "corruptionOrExploitation",
    "isolation",
  ].map(value => ({
    label: subAllegationLabel[value],
    value,
  })),
};

export const findings = {
  "no-findings": {
    label: "No Findings",
    pillVariant: "success",
  },
  unsubstantiated: {
    label: "Unsubstantiated",
    pillVariant: "alert",
  },
  substantiated: {
    label: "Substantiated",
    pillVariant: "warning",
  },
};

const AllegationsAndFindings = ({
  user,
  value,
  onChange,
  abuserOptions = [],
  victimOptions = [],
}) => {
  const { referralId, formState, updateFormState } = useInvestigation();

  const [submitting, setSubmitting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [allegationsAndFindings, setAllegationsAndFindings] = useState(
    value || []
  );
  const [selectedAllegationToEdit, setSelectedAllegationToEdit] =
    useState(null);

  const onSubmit = async e => {
    setSubmitting(true);
    e?.preventDefault();
    const formData = new FormData(e.target);

    const allegationData = {
      allegedAbuser: formData.get("allegedAbuser"),
      allegedVictim: formData.get("allegedVictim"),
      finding: formData.get("finding"),
      allegationMade: formData.get("allegationMade"),
      detailsFinding: formData.get("detailsFinding"),
    };

    if (selectedAllegationToEdit) {
      const newAllegations = [...allegationsAndFindings];

      newAllegations[selectedAllegationToEdit.index] = allegationData;

      onChange?.(newAllegations);
      setAllegationsAndFindings(newAllegations);
      updateFormState({
        ...formState,
        allegationsAndFindings: newAllegations,
      });

      if (user && isSupervisor) {
        setOpenModal(true);
      } else {
        await patch(investigationPath(referralId), {
          ...formState,
          allegationsAndFindings: newAllegations,
          skipRedirect: true,
        });
        setSelectedAllegationToEdit(null);
        setIsAdding(false);
        setSubmitting(false);
      }
    } else {
      onChange?.([...allegationsAndFindings, allegationData]);
      setAllegationsAndFindings(a => [...a, allegationData]);
      updateFormState({
        ...formState,
        allegationsAndFindings: [...allegationsAndFindings, allegationData],
      });

      await patch(investigationPath(referralId), {
        ...formState,
        allegationsAndFindings: [...allegationsAndFindings, allegationData],
        skipRedirect: true,
      });
      setIsAdding(false);
      setSubmitting(false);
    }
  };

  const resetFormStates = () => {
    setIsAdding(false);
    setAllegationsAndFindings(value || []);
    setSelectedAllegationToEdit(null);
  };

  const isSupervisor = hasSingleRole(user?.roles || [], roles.SUPERVISOR);

  return (
    <Surface
      hideTitle
      title="Allegations And Findings"
      sectionId="allegations-and-findings"
    >
      <Flex column gap="300" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Allegations + Findings
        </Text>
        <If condition={allegationsAndFindings.length > 0}>
          <Table>
            <Thead>
              <Tr>
                <Th>Alleged Abuser(s)</Th>
                <Th>Alleged Victim(s)</Th>
                <Th>Allegations</Th>
                <Th>Finding</Th>
                <Th>Controls</Th>
              </Tr>
            </Thead>
            <Tbody>
              {allegationsAndFindings.map((allegation, i) => (
                <Tr
                  key={i}
                  style={{
                    display:
                      selectedAllegationToEdit?.index === i
                        ? "none"
                        : "table-row",
                  }}
                >
                  <Td>{allegation.allegedAbuser}</Td>
                  <Td>{allegation.allegedVictim}</Td>
                  <Td>
                    {allegationsLabel[allegation.allegationMade] ||
                      subAllegationLabel[allegation.allegationMade]}
                  </Td>
                  <Td>
                    {allegation.finding ? (
                      <Pill
                        text={findings[allegation.finding].label}
                        variant={findings[allegation.finding].pillVariant}
                      />
                    ) : null}
                  </Td>
                  <Td alignRight>
                    <Flex justify="end">
                      <Icons.Edit
                        disabled={selectedAllegationToEdit}
                        onClick={() => {
                          setSelectedAllegationToEdit({
                            index: i,
                            ...allegation,
                          });
                        }}
                      />
                      <Icons.Trash
                        disabled={selectedAllegationToEdit}
                        onClick={() => {
                          const newAllegations = [...allegationsAndFindings];
                          newAllegations.splice(i, 1);
                          setAllegationsAndFindings([...newAllegations]);
                          onChange?.([...newAllegations]);
                          updateFormState({
                            ...formState,
                            allegationsAndFindings: newAllegations,
                          });
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </If>
        <If condition={isAdding || selectedAllegationToEdit}>
          <SurfaceForm
            title="New Allegation"
            hideTitle
            onSubmit={onSubmit}
            actions={
              <Actions
                primaryText={
                  user && isSupervisor ? "Review & Approve" : "Send for Review"
                }
                isSubmitting={submitting}
                cancelAction={resetFormStates}
              />
            }
          >
            <Flex column gap="200" fullWidth>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                {selectedAllegationToEdit
                  ? "Edit Allegation"
                  : "New Allegation"}
              </Text>
              <InputFilterable
                id="allegedAbuser"
                label="Alleged Abuser"
                name="allegedAbuser"
                defaultValue={
                  selectedAllegationToEdit?.allegedAbuser
                    ? {
                        label: selectedAllegationToEdit.allegedAbuser,
                        value: selectedAllegationToEdit.allegedAbuser,
                      }
                    : ""
                }
                required
                fullWidth
                values={abuserOptions}
              />
              <InputFilterable
                id="allegedVictim"
                label="Alleged Victim"
                name="allegedVictim"
                defaultValue={
                  selectedAllegationToEdit?.allegedVictim
                    ? {
                        label: selectedAllegationToEdit.allegedVictim,
                        value: selectedAllegationToEdit.allegedVictim,
                      }
                    : ""
                }
                required
                fullWidth
                values={victimOptions}
              />
              <InputRadioGroup
                name="allegationMade"
                value={selectedAllegationToEdit?.allegationMade}
                values={Object.keys(allegationsLabel).map(allegationKey => ({
                  label: allegationsLabel[allegationKey],
                  value: allegationKey,
                  subOptions: subOptionsForAllegation[allegationKey],
                }))}
              />
              <InputDropdown
                id="finding"
                label="Finding"
                name="finding"
                value={selectedAllegationToEdit?.finding ?? ""}
                required
                values={[
                  ["No Findings", "no-findings"],
                  ["Substantiated", "substantiated"],
                  ["Unsubstantiated", "unsubstantiated"],
                ]}
                onChange={event =>
                  selectedAllegationToEdit &&
                  setSelectedAllegationToEdit({
                    ...selectedAllegationToEdit,
                    finding: event,
                  })
                }
              />
              <InputTextarea
                name="detailsFinding"
                label="Provide details to support this finding"
                required
                value={selectedAllegationToEdit?.detailsFinding ?? ""}
                onChange={event =>
                  selectedAllegationToEdit &&
                  setSelectedAllegationToEdit({
                    ...selectedAllegationToEdit,
                    detailsFinding: event,
                  })
                }
              />
            </Flex>
          </SurfaceForm>
        </If>
        <Button
          variant="secondary"
          disabled={isAdding}
          onClick={() => setIsAdding(true)}
        >
          Add Allegation
        </Button>
      </Flex>
      {selectedAllegationToEdit && (
        <SupervisorApprovalModal
          open={openModal}
          onSubmit={onSubmit}
          onCancel={() => setOpenModal(false)}
          currentUser={user}
          allegationMade={selectedAllegationToEdit?.allegationMade}
          finding={selectedAllegationToEdit?.finding}
          detailsFinding={selectedAllegationToEdit?.detailsFinding}
        />
      )}
    </Surface>
  );
};

AllegationsAndFindings.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  abuserOptions: PropTypes.array,
  victimOptions: PropTypes.array,
  user: PropTypes.object,
};

export default AllegationsAndFindings;
