import { ContentTabs, Flex, Text } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import EmailLogin from "../permissions/Login/EmailLogin/EmailLogin";
import SsoLogin from "../permissions/Login/SsoLogin/SsoLogin";
import stylesFakeLogin from "./FakeLogin.module.scss";

const { t } = translationWithRoot("permissions.login");

const FakeLogin = ({ returnUri, displayGoogleSso, googleSsoProps }) => {
  const tabs = [
    {
      title: t("email"),
      contents: (
        <EmailLogin
          formAction="/login"
          displayGoogleSso={displayGoogleSso}
          googleSsoProps={googleSsoProps}
        />
      ),
    },
    {
      title: t("sso"),
      contents: <SsoLogin returnUri={returnUri} />,
    },
  ];

  return (
    <Flex
      column
      align="center"
      data-testid="login"
      className={stylesFakeLogin.fakeLoginContainer}
    >
      <div className={stylesFakeLogin.background}></div>
      <div className={stylesFakeLogin.circles}></div>
      <Flex column align="center" className={stylesFakeLogin.container}>
        <Text as="h1" textStyle="emphasis-300">
          {t("log_in_to_binti")}
        </Text>
        <ContentTabs tabs={tabs}></ContentTabs>
      </Flex>
    </Flex>
  );
};

FakeLogin.propTypes = {
  returnUri: PropTypes.string,
  displayGoogleSso: PropTypes.bool,
  googleSsoProps: PropTypes.shape({
    googleOauth2ClientId: PropTypes.string,
    redirectPath: PropTypes.string,
    callbackPath: PropTypes.string,
  }),
};

export default FakeLogin;
