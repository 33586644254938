import { Breadcrumbs, Flex, Text, Surface } from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import PropTypes from "prop-types";
import React from "react";

import useSectionNavigation from "@components/shared/hooks/useSectionNavigation";

import styles from "./CreateView.module.scss";
import SectionLinks from "./SectionLinks";

const CreateViewLayout = ({
  children,
  sidebar,
  breadcrumb,
  links,
  sidebarTitle,
  sidebarSubtitle,
  hideSidebarTitle = false,
  pageTitle,
  footer,
  responsive = false,
  mobileSidebar,
}) => {
  const { activeSection, sectionRefs, handleSectionClick, handleDivClick } =
    useSectionNavigation();

  return (
    <div
      className={`${styles.containerNew} ${
        responsive ? styles.responsive : ""
      }`}
    >
      {responsive ? (
        <ResolutionOnly medium over>
          <Surface
            title={sidebarTitle}
            subtitle={sidebarSubtitle}
            hideTitle={hideSidebarTitle}
            className={styles.stickiedSurface}
          >
            {sidebar}
          </Surface>
        </ResolutionOnly>
      ) : (
        <Surface
          title={sidebarTitle}
          subtitle={sidebarSubtitle}
          hideTitle={hideSidebarTitle}
          className={styles.stickiedSurface}
        >
          {sidebar}
        </Surface>
      )}
      <Flex column>
        <Flex row gap="300">
          <Breadcrumbs pages={breadcrumb} />
        </Flex>
        <Flex
          row
          justify="space-between"
          align="center"
          className={styles.title}
        >
          <Text textColor="neutral-600" textStyle="emphasis-300">
            {pageTitle}
          </Text>
        </Flex>
        <Flex row gap="300">
          {responsive ? (
            <ResolutionOnly medium over>
              <SectionLinks
                activeSection={activeSection}
                handleSectionClick={handleSectionClick}
                links={links}
                linkSectionTitle={pageTitle}
              />
            </ResolutionOnly>
          ) : (
            <SectionLinks
              activeSection={activeSection}
              handleSectionClick={handleSectionClick}
              links={links}
              linkSectionTitle={pageTitle}
            />
          )}
          <Flex className={styles.mainSurfaces} column gap="300">
            {responsive && (
              <ResolutionOnly medium under>
                {mobileSidebar}
              </ResolutionOnly>
            )}

            {typeof children === "function"
              ? children({
                  sectionRefs,
                  handleDivClick,
                })
              : children}
          </Flex>
        </Flex>
        {footer}
      </Flex>
    </div>
  );
};

CreateViewLayout.propTypes = {
  breadcrumb: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  sidebar: PropTypes.node,
  sidebarTitle: PropTypes.string,
  sidebarSubtitle: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  pageTitle: PropTypes.string.isRequired,
  footer: PropTypes.node,
  hideSidebarTitle: PropTypes.bool,
  responsive: PropTypes.bool,
  mobileSidebar: PropTypes.node,
};

export default CreateViewLayout;
