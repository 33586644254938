/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { InputFilterable, Surface } from "@heart/components";
import get from "@utils/get";
import post from "@utils/post";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { caseActivityPersonsAndCollateralPath } from "routes";

import AutoSaveStickyFooter from "@components/shared/AutoSaveStickyFooter";
import Attachments from "@components/shared/attachments/Attachments";
import CreateViewLayout from "@components/shared/layout/create_view_layout/CreateViewLayout";
import sharedStyles from "@components/shared/styles/SharedStyles.module.scss";

import useSearchParam from "@lib/react-use/useSearchParam";

import ActivityDetails from "./Cards/ActivityDetails";
import ActivityInformation from "./Cards/ActivityInformation";
import PersonsCollateral from "./Cards/PersonsCollateral";
import RecordingCards from "./Cards/RecordingCards";
import { CaseActivityProvider, useCaseActivity } from "./CaseActivityContext";

// a dumb component that just renders the CreateCaseActivityContent with Context Layer
const CreateCaseActivityPage = ({
  persons,
  worker,
  referralId,
  currentUser,
  cases,
  caseActivityData,
}) => (
  <CaseActivityProvider
    persons={persons}
    worker={worker}
    initialData={{
      currentUser,
      referralId,
      caseActivityData,
    }}
    cases={cases}
  >
    <CreateCaseActivityContent />
  </CaseActivityProvider>
);

const CreateCaseActivityContent = () => {
  const redirectToInvestigationsCase = useSearchParam("interviewForCase");
  const {
    formState,
    updateFormState,
    referralId = null,
    cases,
    persons,
    worker,
  } = useCaseActivity();
  const [selectedReferralId, setSelectedReferralId] = useState(referralId);
  const [personsForCase, setPersonsForCase] = useState(persons);
  const [workerForCase, setWorkerForCase] = useState(worker);

  const handleSubmitForm = async () => {
    const body = {
      caseActivityId: formState.caseActivityId,
      caseId: selectedReferralId,
      aboutPersonId: formState.persons?.behalfOf.map(person => person.id),
      personsEngagedIds: formState.persons?.engaged?.map(person => person.id),
      formState,
      redirectToInvestigationsCase,
    };

    await post(`/case-activity/${selectedReferralId}`, body);
  };

  const { recordings } = formState;
  const pushRecording = recording => {
    updateFormState({
      recordings: [...recordings, recording],
    });
  };

  return (
    <CreateViewLayout
      pageTitle={`${
        formState.caseActivityId ? "Edit" : "Create"
      } Case Activity`}
      breadcrumb={[
        {
          href: "/",
          label: "Home",
        },
        {
          href: "/investigations",
          label: "Investigations",
        },
        selectedReferralId && {
          href: `/investigations/review/${selectedReferralId}`,
          label: selectedReferralId,
        },
        {
          href: "#",
          label: `${
            formState.caseActivityId ? "Edit" : "Create"
          } Case Activity`,
        },
      ].filter(Boolean)}
      sidebar={
        <RecordingCards
          referralId={selectedReferralId}
          recordings={recordings}
          onNewTranscription={pushRecording}
        />
      }
      mobileSidebar={
        <RecordingCards
          referralId={selectedReferralId}
          recordings={recordings}
          onNewTranscription={pushRecording}
        />
      }
      responsive
      links={[
        cases && {
          href: "#associated-referral-or-case",
          label: "Associated Referral or Case",
        },
        { href: "#activity-information", label: "Activity Information" },
        { href: "#persons", label: "Persons and Collateral" },
        { href: "#activity-details", label: "Activity Details" },
        { href: "#attachments", label: "Attachments" },
      ].filter(Boolean)}
      footer={
        <AutoSaveStickyFooter
          cancelText="Cancel"
          submitText="Save"
          formState={formState}
          onSubmit={handleSubmitForm}
          referralId={selectedReferralId}
        />
      }
    >
      {({ sectionRefs, handleDivClick }) => (
        <React.Fragment>
          <If condition={cases}>
            <div
              id="associated-referral-or-case"
              ref={el => {
                const currentRefs = sectionRefs.current;
                currentRefs["associated-referral-or-case"] = el;
              }}
              onClick={() => handleDivClick("associated-referral-or-case")}
            >
              <Surface
                title="Associated Referral or Case"
                className={sharedStyles.surfaceContainer}
              >
                <InputFilterable
                  id="case"
                  label="Case"
                  name="case"
                  hideLabel
                  onChange={async ({ value }) => {
                    setSelectedReferralId(value);
                    const {
                      persons: personsForSelectedCase,
                      worker: workerForSelectedCase,
                    } = await get(caseActivityPersonsAndCollateralPath(value));
                    setPersonsForCase(personsForSelectedCase);
                    setWorkerForCase(workerForSelectedCase);
                  }}
                  values={cases.map(
                    ({
                      referralId: caseReferralId,
                      familyInstitutionNames,
                    }) => ({
                      label: `${caseReferralId}: ${familyInstitutionNames.join(
                        ", "
                      )}`,
                      value: caseReferralId,
                    })
                  )}
                />
              </Surface>
            </div>
          </If>
          <div
            id="activity-information"
            ref={el => {
              const currentRefs = sectionRefs.current;
              currentRefs["activity-information"] = el;
            }}
            onClick={() => handleDivClick("activity-information")}
          >
            <ActivityInformation />
          </div>
          <div
            id="persons"
            ref={el => {
              const currentRefs = sectionRefs.current;
              currentRefs.persons = el;
            }}
            onClick={() => handleDivClick("persons")}
          >
            <PersonsCollateral
              personsAssociatedWithCase={personsForCase}
              worker={workerForCase}
            />
          </div>
          <div
            id="activity-details"
            ref={el => {
              const currentRefs = sectionRefs.current;
              currentRefs["activity-details"] = el;
            }}
            onClick={() => handleDivClick("activity-details")}
          >
            <ActivityDetails />
          </div>
          <div
            id="attachments"
            ref={el => {
              const currentRefs = sectionRefs.current;
              currentRefs.attachments = el;
            }}
            onClick={() => handleDivClick("attachments")}
          >
            <Attachments
              formState={formState}
              updateFormState={updateFormState}
            />
          </div>
        </React.Fragment>
      )}
    </CreateViewLayout>
  );
};

CreateCaseActivityPage.propTypes = {
  persons: PropTypes.array,
  worker: PropTypes.object,
  referralId: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  cases: PropTypes.array,
  caseActivityData: PropTypes.object,
};

export default CreateCaseActivityPage;
