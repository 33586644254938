import { Flex, InputCheckbox, If, InputTextarea } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

const ProtectingInterventionsInputs = ({
  formState,
  handleProtectingInterventionChange,
}) => {
  const protectingInterventions = formState.protectingInterventions || {};

  return (
    <Flex column gap="300">
      <InputCheckbox
        id="monitoring"
        name="monitoring"
        label="Monitoring or direct services by Family Services Specialist"
        onChange={value =>
          handleProtectingInterventionChange("monitoring", value)
        }
        value={protectingInterventions.monitoring}
      />

      <InputCheckbox
        id="familyResources"
        name="familyResources"
        label="Use of family resources, neighbors, or other individuals in the community in the development and implementation of a safety plan"
        onChange={value =>
          handleProtectingInterventionChange("familyResources", value)
        }
        value={protectingInterventions.familyResources}
      />

      <InputCheckbox
        id="communityAgencies"
        name="communityAgencies"
        label="Use of community agencies for safety services (specify agency or resource)"
        onChange={value => {
          handleProtectingInterventionChange("communityAgencies", value);
          if (!value) {
            handleProtectingInterventionChange("communityAgenciesSpecify", "");
          }
        }}
        value={protectingInterventions.communityAgencies}
      />
      <If condition={protectingInterventions.communityAgencies}>
        <InputTextarea
          name="communityAgenciesSpecify"
          label="Specify agency or resource"
          onChange={value =>
            handleProtectingInterventionChange(
              "communityAgenciesSpecify",
              value
            )
          }
          value={protectingInterventions.communityAgenciesSpecify}
        />
      </If>

      <InputCheckbox
        id="allegedAbuserLeftHome"
        name="allegedAbuserLeftHome"
        label="Alleged abuser/neglector left the home"
        onChange={value =>
          handleProtectingInterventionChange("allegedAbuserLeftHome", value)
        }
        value={protectingInterventions.allegedAbuserLeftHome}
      />
      <If condition={protectingInterventions.allegedAbuserLeftHome}>
        <Flex column gap="100" style={{ paddingLeft: 35 }}>
          <InputCheckbox
            id="voluntarily"
            name="allegedAbuserLeftHomeType"
            label="Voluntarily"
            onChange={value =>
              handleProtectingInterventionChange("voluntarily", value)
            }
            value={protectingInterventions.voluntarily}
          />
          <InputCheckbox
            id="inResponseToPoliceIntervention"
            name="allegedAbuserLeftHomeType"
            label="In response to police intervention"
            onChange={value =>
              handleProtectingInterventionChange(
                "inResponseToPoliceIntervention",
                value
              )
            }
            value={protectingInterventions.inResponseToPoliceIntervention}
          />
          <InputCheckbox
            id="legalActionAbuser"
            name="allegedAbuserLeftHomeType"
            label="Legal action"
            onChange={value =>
              handleProtectingInterventionChange("legalActionAbuser", value)
            }
            value={protectingInterventions.legalActionAbuser}
          />
          <InputCheckbox
            id="otherAbuserLeft"
            name="allegedAbuserLeftHomeType"
            label="Other"
            onChange={value => {
              handleProtectingInterventionChange("otherAbuserLeft", value);
              if (!value) {
                handleProtectingInterventionChange(
                  "allegedAbuserLeftHomeOther",
                  ""
                );
              }
            }}
            value={protectingInterventions.otherAbuserLeft}
          />
          <If condition={protectingInterventions.otherAbuserLeft}>
            <InputTextarea
              name="allegedAbuserLeftHomeOther"
              label="Specify other reason"
              onChange={value =>
                handleProtectingInterventionChange(
                  "allegedAbuserLeftHomeOther",
                  value
                )
              }
              value={protectingInterventions.allegedAbuserLeftHomeOther}
            />
          </If>
        </Flex>
      </If>

      <InputCheckbox
        id="nonMaltreatingSafe"
        name="nonMaltreatingSafe"
        label="Non-maltreating caretaker moved to a safe environment with child"
        onChange={value =>
          handleProtectingInterventionChange("nonMaltreatingSafe", value)
        }
        value={protectingInterventions.nonMaltreatingSafe}
      />

      <InputCheckbox
        id="alternateCaretaker"
        name="alternateCaretaker"
        label="Caretaker placed child outside the home with an alternate safe caretaker (specify)"
        onChange={value => {
          handleProtectingInterventionChange("alternateCaretaker", value);
          if (!value) {
            handleProtectingInterventionChange("alternateCaretakerSpecify", "");
          }
        }}
        value={protectingInterventions.alternateCaretaker}
      />
      <If condition={protectingInterventions.alternateCaretaker}>
        <InputTextarea
          name="alternateCaretakerSpecify"
          label="Specify alternate caretaker"
          onChange={value =>
            handleProtectingInterventionChange(
              "alternateCaretakerSpecify",
              value
            )
          }
          value={protectingInterventions.alternateCaretakerSpecify}
        />
      </If>

      <InputCheckbox
        id="legalAction"
        name="legalAction"
        label="Legal action initiated; child remains in the home (explain in summary)"
        onChange={value =>
          handleProtectingInterventionChange("legalAction", value)
        }
        value={protectingInterventions.legalAction}
      />
      <If condition={protectingInterventions.legalAction}>
        <Flex column gap="100" style={{ paddingLeft: 35 }}>
          <InputCheckbox
            id="restrainingOrder"
            name="legalActionType"
            label="Restraining order"
            onChange={value =>
              handleProtectingInterventionChange("restrainingOrder", value)
            }
            value={protectingInterventions.restrainingOrder}
          />
          <InputCheckbox
            id="protectiveOrder"
            name="legalActionType"
            label="Protective order"
            onChange={value =>
              handleProtectingInterventionChange("protectiveOrder", value)
            }
            value={protectingInterventions.protectiveOrder}
          />
          <InputCheckbox
            id="emergencyCommittalOrder"
            name="legalActionType"
            label="Emergency committal order"
            onChange={value =>
              handleProtectingInterventionChange(
                "emergencyCommittalOrder",
                value
              )
            }
            value={protectingInterventions.emergencyCommittalOrder}
          />
          <InputCheckbox
            id="custodyChange"
            name="legalActionType"
            label="Change in custody/visitation/guardianship"
            onChange={value =>
              handleProtectingInterventionChange("custodyChange", value)
            }
            value={protectingInterventions.custodyChange}
          />
          <InputCheckbox
            id="otherLegalAction"
            name="legalActionType"
            label="Other, specify"
            onChange={value => {
              handleProtectingInterventionChange("otherLegalAction", value);
              if (!value) {
                handleProtectingInterventionChange("legalActionOther", "");
              }
            }}
            value={protectingInterventions.otherLegalAction}
          />
          <If condition={protectingInterventions.otherLegalAction}>
            <InputTextarea
              name="legalActionOther"
              label="Specify other legal action"
              onChange={value =>
                handleProtectingInterventionChange("legalActionOther", value)
              }
              value={protectingInterventions.legalActionOther}
            />
          </If>
        </Flex>
      </If>

      <InputCheckbox
        id="otherIntervention"
        name="otherIntervention"
        label="Other intervention to allow child to remain in the home"
        onChange={value =>
          handleProtectingInterventionChange("otherIntervention", value)
        }
        value={protectingInterventions.otherIntervention}
      />
      <If condition={protectingInterventions.otherIntervention}>
        <InputTextarea
          name="otherInterventionSpecify"
          label="Specify other intervention"
          onChange={value =>
            handleProtectingInterventionChange(
              "otherInterventionSpecify",
              value
            )
          }
          value={protectingInterventions.otherInterventionSpecify}
        />
      </If>

      <InputCheckbox
        id="emergencyRemoval"
        name="emergencyRemoval"
        label="Emergency removal was conducted to remove child from home due to immediate safety issues"
        onChange={value =>
          handleProtectingInterventionChange("emergencyRemoval", value)
        }
        value={protectingInterventions.emergencyRemoval}
      />
    </Flex>
  );
};

ProtectingInterventionsInputs.propTypes = {
  formState: PropTypes.shape({
    protectingInterventions: PropTypes.object,
  }).isRequired,
  handleProtectingInterventionChange: PropTypes.func.isRequired,
};

export default ProtectingInterventionsInputs;
