import { Flex, Text } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";

import AfcarsRequired from "@components/ccwis_demo/AfcarsRequired";

import styles from "./InputGroupLayout.module.scss";

import {
  useInputProps,
  useInputId,
  useInputLabel,
  useInputDescription,
  useInputError,
  useInputContainerClassName,
  inputGroupCommonPropTypes,
} from ".";

const InputGroupLayout = ({
  inputsComponent,
  orientation = "column",
  spacing,
  label,
  labelStyle = "body-100",
  labelColor = "neutral-600",
  visuallySeparated,
  ...props
}) => {
  const id = useInputId(props);
  const inputLabel = useInputLabel({
    ...props,
    id,
    label: ({ Label, afcarsRequired, ...labelProps }) => (
      <Label {...labelProps} id={id}>
        <Text textStyle={labelStyle} textColor={labelColor}>
          {label}
        </Text>
        <If condition={afcarsRequired}>
          {" "}
          <AfcarsRequired />
        </If>
      </Label>
    ),
  });
  const description = useInputDescription(props);
  const error = useInputError(props);
  const inputProps = useInputProps(props);
  const containerClass = useInputContainerClassName(props);

  return (
    <Flex
      column
      role="group"
      aria-labelledby={id}
      className={classNames(containerClass, {
        [styles.visuallySeparated]: visuallySeparated,
      })}
    >
      {inputLabel}
      {description}
      <ul
        className={classNames(
          styles.optionsContainer,
          styles[`spacing-${spacing}`],
          {
            [styles.row]: orientation === "row",
            [styles.column]: orientation === "column",
          }
        )}
      >
        {inputsComponent(inputProps)}
      </ul>
      {error}
    </Flex>
  );
};

InputGroupLayout.propTypes = {
  ...inputGroupCommonPropTypes,
  /** Checkboxes are slightly more squished than radio options */
  spacing: PropTypes.oneOf(["compact", "roomy"]),
  inputsComponent: PropTypes.func,
};

export default InputGroupLayout;
