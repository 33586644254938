import {
  InputRadioGroup,
  Surface,
  Text,
  If,
  InputTextarea,
} from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

const AssessmentRecommendation = ({
  formState,
  handleAssessmentRecommendationChange,
}) => {
  const assessmentRecommendation = formState.assessmentRecommendation || {};

  return (
    <Surface title="Assessment Recommendation" hideTitle>
      <Text textStyle="body-200" textColor="neutral-600">
        Assessment Recommendation
      </Text>
      <InputRadioGroup
        id="acceptAssessment"
        name="acceptAssessment"
        label="Accept Safety Assessment Recommendation?"
        orientation="row"
        required
        value={assessmentRecommendation.acceptAssessment}
        onChange={value =>
          handleAssessmentRecommendationChange("acceptAssessment", value)
        }
        values={[
          {
            label: "Yes: Unsafe",
            value: true,
          },
          {
            label: "No: Discretionary Override (requires Supervisor Approval)",
            value: false,
          },
        ]}
      />
      <If condition={!assessmentRecommendation.acceptAssessment}>
        <InputRadioGroup
          id="recommendedOutcome"
          name="recommendedOutcome"
          label="What is the recommended outcome?"
          required
          values={["Safe", "Conditionally Safe"]}
          value={assessmentRecommendation.recommendedOutcome}
          onChange={value =>
            handleAssessmentRecommendationChange("recommendedOutcome", value)
          }
        />
      </If>
      <InputTextarea
        label="Summary/Reasoning for Recommendation"
        name="summary"
        value={assessmentRecommendation.summary}
        onChange={value =>
          handleAssessmentRecommendationChange("summary", value)
        }
      />
    </Surface>
  );
};

AssessmentRecommendation.propTypes = {
  formState: PropTypes.shape({
    assessmentRecommendation: PropTypes.object,
  }).isRequired,
  handleAssessmentRecommendationChange: PropTypes.func.isRequired,
};

export default AssessmentRecommendation;
