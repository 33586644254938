import {
  HeartTable,
  Surface,
  Flex,
  Text,
  Link,
  Button,
  FlexItem,
} from "@heart/components";
import { isEmpty } from "lodash";
import React, { Fragment } from "react";
import { caseActivityEditPath, newCaseActivityPath } from "routes";

import ExpandableContent from "@components/reusable_ui/ExpandableContent";

import {
  formatDateTimeAsShortDate,
  formatDateTimeAsShortTime,
} from "@lib/dates";

import { useInvestigation } from "../investigation_review/InvestigationContext";
import styles from "../investigation_review/ReviewInvestigationPage.module.scss";

const { Caption, Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const Interviews = () => {
  const {
    referralId,
    formState: { interviews = [] },
  } = useInvestigation();

  return (
    <Fragment>
      <Surface
        title="Interviews"
        className={styles.surfaceContainer}
        sectionId="interviews"
        hideTitle
      >
        <Flex column gap="300">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Interviews
          </Text>
          <If condition={!isEmpty(interviews)}>
            <Table>
              <Caption hidden>Interviews</Caption>
              <Thead>
                <Tr>
                  <Th>Date/Time</Th>
                  <Th>Details</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {interviews.map(
                  (
                    {
                      id,
                      formState: {
                        activityDate,
                        activityDetails,
                        persons: { engaged, behalfOf },
                      } = { persons: {} },
                    },
                    index
                  ) => (
                    <Tr key={index}>
                      <Td style={{ alignContent: "start" }}>
                        <Flex column>
                          <FlexItem>
                            {formatDateTimeAsShortDate(activityDate)}
                          </FlexItem>
                          <FlexItem>
                            {formatDateTimeAsShortTime(activityDate)}
                          </FlexItem>
                        </Flex>
                      </Td>
                      <Td>
                        <Flex column>
                          <FlexItem>
                            <b>Person(s) Engaged: </b>
                            {engaged.map(({ label }) => label).join(", ")}
                          </FlexItem>
                          <FlexItem>
                            <b>In Reference To/On Behalf Of: </b>
                            {behalfOf.map(({ label }) => label).join(", ")}
                          </FlexItem>
                          <FlexItem>
                            <ExpandableContent collapsedHeight={70}>
                              <b>Summary:</b>
                              <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: activityDetails,
                                }}
                              />
                            </ExpandableContent>
                          </FlexItem>
                        </Flex>
                      </Td>
                      <Td style={{ alignContent: "start" }}>
                        <Flex justify="end">
                          <Link
                            href={caseActivityEditPath(referralId, id, {
                              interviewForCase: true,
                            })}
                          >
                            View/Edit
                          </Link>
                        </Flex>
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
          </If>
          <Flex>
            <Button
              variant="secondary"
              href={newCaseActivityPath(referralId, { interviewForCase: true })}
            >
              Add New Interview
            </Button>
          </Flex>
        </Flex>
      </Surface>
    </Fragment>
  );
};

export default Interviews;
