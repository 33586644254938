import { Surface, Text, Flex } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import Attachments from "@components/shared/attachments/Attachments";
import PersonCard from "@components/shared/person/PersonCard";

const ReviewSubmit = ({
  step,
  userAuthenticated,
  formState,
  updateFormState,
}) => (
  <React.Fragment>
    <If condition={(step === 1 && !userAuthenticated) || step === 2}>
      <Surface title="Review and Submit" hideTitle>
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Step 3: Review and Submit
        </Text>
      </Surface>
    </If>
    <If condition={step === 3}>
      <Surface title="Persons Involved" hideTitle sectionId="persons-involved">
        <Flex gap="200" column align="start">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Persons Involved
          </Text>
          {formState.persons.map((person, index) => (
            <PersonCard key={index} person={person} />
          ))}
        </Flex>
      </Surface>
      <Surface title="Incident Information" hideTitle>
        <Flex gap="300" column>
          <Flex gap="100" column>
            <Text textStyle="emphasis-200" textColor="neutral-600">
              Incident Information
            </Text>
            <Text textStyle="supporting-150" textColor="neutral-500">
              Please fill out the following questions to the best of your
              ability
            </Text>
          </Flex>
          <Flex column gap="100">
            <Text textStyle="supporting-100" textColor="neutral-600">
              Description of Incident(s). What specific incident(s) or
              behavior(s) raised your concern? Can you describe the time, date
              and location of the incident(s)? How often have you observed these
              concerns (on-time occurrence, repeated, ongoing)?{" "}
              <Text textColor="danger-500">*</Text>
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {formState.incidentInfo.childStatus}
            </Text>
          </Flex>
          <Flex column gap="100">
            <Text textStyle="supporting-100" textColor="neutral-600">
              Have you noticed any physical evidence (e.g., bruises, poor
              hygiene, lack of food) or heard any concerning statements from the
              child? What specific behaviors or statements from the child(ren)
              or others have led to your concerns?{" "}
              <Text textColor="danger-500">*</Text>
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {formState.incidentInfo.abuseDescription}
            </Text>
          </Flex>
          <Flex column gap="100">
            <Text textStyle="supporting-100" textColor="neutral-600">
              How long have you known the family and have you had concerns
              before? <Text textColor="danger-500">*</Text>
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {formState.incidentInfo.familyHistory}
            </Text>
          </Flex>
          <Flex column gap="100">
            <Text textStyle="supporting-100" textColor="neutral-600">
              Please describe how the child or children you are reporting are
              related to the people in their life. For example, tell us who
              their parents, siblings, grandparents, or other family members are
              and how they are connected. <Text textColor="danger-500">*</Text>
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {formState.incidentInfo.familyStructure}
            </Text>
          </Flex>
          <Flex column gap="100">
            <Text textStyle="supporting-100" textColor="neutral-600">
              Were there any other children or adults present during the
              incident(s)? If so, who? <Text textColor="danger-500">*</Text>
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {formState.incidentInfo.witnesses}
            </Text>
          </Flex>
          <Flex column gap="100">
            <Text textStyle="supporting-100" textColor="neutral-600">
              Is there anything else you would like to share that might be
              relevant to the child&apos;s or children&apos;s situation?{" "}
              <Text textColor="danger-500">*</Text>
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              {formState.incidentInfo.extraInfo}
            </Text>
          </Flex>
        </Flex>
      </Surface>
      <Attachments
        formState={formState}
        updateFormState={updateFormState}
        review={true}
      />
      <Surface title="Work safety concerns" hideTitle>
        <Flex gap="200" column>
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Worker Safety Concerns
          </Text>
          <Text textStyle="supporting-150" textColor="neutral-500">
            Please indicate safety issues in the event that workers will be on
            site
          </Text>
          <Flex column gap="100">
            <Flex>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Category
              </Text>
              <Text textStyle="body-100" textColor="neutral-600">
                {formState.incidentInfo.concernType?.length > 0
                  ? formState.incidentInfo.concernType
                      .map(conc => conc.label)
                      .join(", ")
                  : "blank"}
              </Text>
            </Flex>
            <Flex>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Comments
              </Text>
              <Text textStyle="body-100" textColor="neutral-600">
                {formState.incidentInfo.safetyComments || "blank"}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Surface>
      <Surface title="Review gratitud" hideTitle>
        <Flex gap="200" column>
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Review & Submit
          </Text>
          <Text textStyle="supporting-150" textColor="neutral-500">
            Thank you for taking the time to ensure the safety, permanency, and
            well-being of a child or children in your community.
            <br /> <br /> On the next page you will receive a Referral ID.
            Please screenshot or copy the number down for your records, you will
            not be able to view this information again.
          </Text>
        </Flex>
      </Surface>
    </If>
  </React.Fragment>
);

ReviewSubmit.propTypes = {
  userAuthenticated: PropTypes.bool,
  step: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  updateFormState: PropTypes.func.isRequired,
};

export default ReviewSubmit;
