import { Flex, Text } from "@heart/components";
import * as HeartTable from "@heart/components/table/Table";
import PropTypes from "prop-types";

import preventDefault from "@lib/preventDefault";

import SortIcon from "./SortIcon";
import styles from "./TableColumnHeader.module.scss";

const { Th } = HeartTable;

const TableColumnHeader = ({
  id,
  name,
  justify = "start",
  isSortable,
  isActiveSortColumn,
  sortDirection,
  onSort,
  colSpan,
}) => {
  if (!isSortable)
    return (
      <Th key={`th-${id}`} scope="col" colSpan={colSpan}>
        <Flex justify={justify}>
          <Text className={styles.paddedHeader} textStyle="supporting-150">
            {name}
          </Text>
        </Flex>
      </Th>
    );

  return (
    <Th
      aria-sort={isActiveSortColumn ? sortDirection : undefined}
      colSpan={colSpan}
    >
      <Flex justify={justify}>
        <Flex
          as="button"
          align="center"
          className={styles.sortButton}
          onClick={preventDefault(onSort)}
        >
          <Text className={styles.paddedHeader} textStyle="supporting-150">
            {name}
          </Text>
          <span aria-hidden="true">
            {
              <SortIcon
                isActiveSortColumn={isActiveSortColumn}
                direction={sortDirection}
              />
            }
          </span>
        </Flex>
      </Flex>
    </Th>
  );
};

TableColumnHeader.propTypes = {
  /** HTML ID for this table header */
  id: PropTypes.string.isRequired,
  /** Content of the header */
  name: PropTypes.string.isRequired,
  /** How to align this header - use a value that can be passed to `<Flex justify={} />` */
  justify: PropTypes.string,
  /** Whether or not this column is sortable */
  isSortable: PropTypes.bool.isRequired,
  /** Whether or not this column is currently the active sort column */
  isActiveSortColumn: PropTypes.bool.isRequired,
  /** Which direction this column is being sorted, if active */
  sortDirection: PropTypes.oneOf(["ASC", "DESC", "NONE"]),
  /** Function to execute if the user clicks this column header, to trigger sorting */
  onSort: PropTypes.func,
  /** Used in tables where column headers need to span more than one column */
  colSpan: PropTypes.number,
};

export default TableColumnHeader;
