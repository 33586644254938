import { ContentTabs } from "@heart/components";
import PropTypes from "prop-types";

import CreateDashboardLayout from "@components/shared/layout/create_dashboard_layout/CreateDashboardLayout";

import styles from "./Investigations.module.scss";
import InvestigationsArray from "./InvestigationsArray";
import {
  columnsTabOne,
  columnsTabTwo,
  dataTabOne,
  dataTabTwo,
} from "./mockData";

const Investigations = ({ newCases, assignedCases }) => (
  <CreateDashboardLayout
    pageTitle={"Investigations"}
    breadcrumb={[
      {
        href: "/",
        label: "Home",
      },
      {
        href: "#",
        label: "Children and Youth",
      },
      {
        href: "/investigations",
        label: "Investigations",
      },
    ]}
  >
    <ContentTabs
      className={styles.customTab}
      tabs={[
        {
          contents: (
            <InvestigationsArray
              columns={columnsTabTwo}
              data={[...dataTabTwo, ...newCases]}
            />
          ),
          count: [...dataTabTwo, ...newCases].length,
          id: "second-tab",
          title: "New",
        },
        {
          contents: (
            <InvestigationsArray
              columns={columnsTabOne}
              data={[...dataTabOne, ...assignedCases]}
            />
          ),
          count: [...dataTabOne, ...assignedCases].length,
          id: "first-tab",
          title: "Assigned",
        },
      ]}
    />
  </CreateDashboardLayout>
);

Investigations.propTypes = {
  newCases: PropTypes.array,
  assignedCases: PropTypes.array,
};

export default Investigations;
