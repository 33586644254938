import PropTypes from "prop-types";
import React, { createContext, useContext, useRef, useState } from "react";

import useFormState from "@components/shared/hooks/useFormState";

const CaseActivityContext = createContext();

export const useCaseActivity = () => useContext(CaseActivityContext);

export const CaseActivityProvider = ({
  children,
  persons,
  worker,
  initialData,
  cases,
}) => {
  const now = useRef(new Date());
  const [currentUser, setCurrentUser] = useState(initialData.currentUser);
  const [referralId, setReferralId] = useState(initialData.referralId);

  const { formState, updateFormState } = useFormState({
    attachments: [],
    medicalAttachments: [],
    activityDate: now.current.toISOString(),
    caseCompleted: true,
    persons: {
      engaged: [],
      behalfOf: [],
      collateral: [],
    },
    methodOfEngagement: "",
    location: "",
    activityType: "",
    activityDetails: "",
    eventInformation: [],
    medicalScreening: {},
    prescriptionForm: {
      address: {
        type: [],
        addressLine1: "",
        countryCode: "US",
        postalCode: "",
        primary: false,
        inactive: false,
        city: "",
        primarySubdivisionCode: "",
        primarySubdivisionName: "",
        secondarySubdivision: "",
      },
    },
    caseActivityId: initialData.caseActivityData?.id,
    recordings: [],
    ...initialData.caseActivityData?.formState,
  });

  return (
    <CaseActivityContext.Provider
      value={{
        formState,
        updateFormState,
        currentUser,
        setCurrentUser,
        referralId,
        setReferralId,
        persons,
        worker,
        cases,
      }}
    >
      {children}
    </CaseActivityContext.Provider>
  );
};

CaseActivityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialData: PropTypes.object,
  persons: PropTypes.array,
  worker: PropTypes.object,
  cases: PropTypes.array,
};
