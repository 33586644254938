import {
  Surface,
  Text,
  HeartTable,
  Flex,
  Button,
  Icons,
  Link,
  InputDropdown,
} from "@heart/components";
import PropTypes from "prop-types";
import React from "react";
import { caseActivityEditPath, newCaseActivityPath } from "routes";

import {
  activityValueMappings,
  eventOptionsMappings,
} from "@components/case_activity/Cards/ActivityOptions";
import {
  formatDate,
  formatFamilyNamesAsLinks,
} from "@components/intake_portal/helper";

import styles from "./ReviewInvestigationPage.module.scss";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const mapToLabel = value => (value ? activityValueMappings[value] : "");
// each item in event is a string
const processEventInformation = (events, mapping) => {
  const result = {};
  events.forEach(event => {
    if (mapping[event] !== undefined) {
      result[event] = [];
    }
  });
  events.forEach(event => {
    // not in result yet therefore not a parent key
    if (result[event] === undefined) {
      const parentKey = Object.keys(mapping).find(key => mapping[key][event]);

      if (parentKey) {
        result[parentKey].push(mapping[parentKey][event]);
      }
    }
  });
  return result;
};

const TableBody = ({ activity, referralId }) => (
  <Tbody>
    {activity.map((item, index) => {
      const dateAndTime = formatDate(
        new Date(item.formState?.activityDate || "")
      );

      const workerAssigned = item.worker?.firstName
        ? `${item.worker?.firstName ?? ""} ${item.worker?.lastName ?? ""}`
        : "Jackson Dirk";

      const eventsSanitized = processEventInformation(
        item.formState?.eventInformation,
        eventOptionsMappings
      );

      return (
        <Tr key={index}>
          <Td>{dateAndTime}</Td>
          <Td>{mapToLabel(item.formState?.methodOfEngagement)}</Td>
          <Td>{mapToLabel(item.formState?.location) || "Non-Physical"}</Td>
          <Td>{workerAssigned}</Td> {/* Need worker */}
          {EventBody(item.formState?.activityType, eventsSanitized)}
          <Td>{formatFamilyNamesAsLinks(item.personsEngaged)}</Td>
          <Td>
            <Link href={caseActivityEditPath(referralId, item.id)}>
              View/Edit
            </Link>
          </Td>
        </Tr>
      );
    })}
  </Tbody>
);

const EventBody = (activityType, hashedEvents) => (
  <Td>
    <Text textStyle="body-100" textColor="neutral-600">
      {mapToLabel(activityType)}
    </Text>

    {Object.keys(hashedEvents).map(event => (
      <Flex key={activityType} gap="100" align="center">
        <Text textStyle="body-100" textColor="neutral-600">
          {event}:
        </Text>
        <Text textStyle="body-100" textColor="neutral-600">
          {hashedEvents[event].join(", ")}
        </Text>
      </Flex>
    ))}
  </Td>
);

const CaseActivityInvestigations = ({ activity, referralId }) => (
  <div className={styles.caseActivityLayout}>
    <Surface title="Attachments" hideTitle className={styles.surfaceContainer}>
      <Flex align="center" justify="space-between">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Case Activity
        </Text>
        <Button
          icon={Icons.Plus}
          variant="primary"
          href={newCaseActivityPath(referralId)}
        >
          New Case Activity
        </Button>
      </Flex>
      <Flex justify="center" align="center">
        <Flex gap="300" align="center">
          <Text textStyle="body-100" textColor="neutral-600">
            Filter by:
          </Text>
          <Flex gap="100">
            <InputDropdown
              id="filter"
              blankOptionText="Select Filter"
              values={[
                ["The Thing", "the-thing-value"],
                ["The Other Thing", "the-other-thing-value"],
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th> Timestamp </Th>
            <Th> Method of Engagement </Th>
            <Th> Location </Th>
            <Th> Caseworker </Th>
            <Th> Activity Type/Details </Th>
            <Th> Person(s) Engaged </Th>
            <Th> Actions </Th>
          </Tr>
        </Thead>
        {activity && <TableBody activity={activity} referralId={referralId} />}
      </Table>
    </Surface>
  </div>
);

TableBody.propTypes = {
  activity: PropTypes.array.isRequired,
  formState: PropTypes.object,
  referralId: PropTypes.string,
};

CaseActivityInvestigations.propTypes = {
  activity: PropTypes.array,
  formState: PropTypes.object,
  referralId: PropTypes.string,
};

export default CaseActivityInvestigations;
