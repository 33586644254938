/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Surface, Switch, InputCheckbox } from "@heart/components";
import post from "@utils/post";
import React, { useEffect, useState } from "react";
import { intakesPath } from "routes";

import AutoSaveStickyFooter from "@components/shared/AutoSaveStickyFooter";
import IntakeRecommendation from "@components/shared/IntakeRecommendation";
import Allegations from "@components/shared/allegations/Allegations";
import Attachments from "@components/shared/attachments/Attachments";
import useFormState from "@components/shared/hooks/useFormState";
import IntakeScreeningTool from "@components/shared/intake_screening_tool/IntakeScreeningTool";
import CreateViewLayout from "@components/shared/layout/create_view_layout/CreateViewLayout";
import NewPersonForm from "@components/shared/person/NewPersonForm";
import RelatedCases from "@components/shared/related/Related";
import Relations from "@components/shared/relations/Relations";

import IncidentInformation from "./Cards/IncidentInformation";
import ReporterInformation from "./Cards/Reporter";
import WorkerSafetyConcern from "./Cards/WorkerSafetyConcern";
import IntakeSubmissionModal from "./IntakeSubmissionModal";
import styles from "./MaltreatmentReport.module.scss";
import { checkMatchWithKeys, questionsToAsk } from "./helper";

const NewMaltreatmentReportPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const { formState, updateFormState } = useFormState({
    reporter: {
      anonymous: false,
      search: "",
    },
    persons: [],
    relations: [],
    incidentInfo: {
      transcription: "",
    },
    workerSafety: {
      concernType: [],
      comments: "",
    },
    allegations: [],
    attachments: [],
    screeningToolResult: null,
    intakeRecommendation: {
      acceptIntakeResponse: true,
      reasoning: "",
      recommendedResponse: "",
      recommendedResponseLevel: null,
    },
  });

  const [questionsMatched, setQuestionsMatched] = useState(
    new Array(questionsToAsk.length).fill(false)
  );

  const handlePersonAdd = ({ personDatabaseId, personId, personData }) => {
    updateFormState({
      ...formState,
      personDatabaseIds: [
        ...(formState.personDatabaseIds || []),
        personDatabaseId,
      ],
      personIds: [...(formState.personIds || []), personId],
      persons: [...(formState.persons || []), personData],
    });
  };

  useEffect(() => {
    checkMatchWithKeys(formState.incidentInfo.transcription, indexesMatched =>
      setQuestionsMatched(indexesMatched)
    );
  }, [formState.incidentInfo.transcription]);

  return (
    <CreateViewLayout
      pageTitle="New Maltreatment Report"
      sidebarTitle="Considerations from Blue Assist"
      breadcrumb={[
        {
          href: "/",
          label: "Home",
        },
        {
          href: "/intake",
          label: "Maltreatment Reports",
        },
        {
          href: "#",
          label: "New Maltreatment Report",
        },
      ]}
      sidebar={
        <React.Fragment>
          {questionsToAsk.map((question, i) => (
            <InputCheckbox
              key={question.key.join("-")}
              id="allow-status-changes"
              label={question.label}
              value={questionsMatched[i]}
              required
            />
          ))}
        </React.Fragment>
      }
      links={[
        { href: "#reporter-information", label: "Reporter Information" },
        { href: "#incident-information", label: "Incident Information" },
        { href: "#attachments", label: "Attachments" },
        { href: "#persons", label: "Persons" },
        { href: "#relations", label: "Relations" },
        {
          href: "#worker-safety-concerns",
          label: "Worker Safety Concerns",
        },
        { href: "#related-cases", label: "Related Cases" },
        { href: "#allegations", label: "Allegations" },
        { href: "#intake-screening-tool", label: "Intake Screening Tool" },
        { href: "#intake-recommendation", label: "Intake Recommendation" },
        { href: "#critical-incident", label: "Critical Incident" },
      ]}
      footer={
        <AutoSaveStickyFooter
          saveText="Save"
          cancelText="Cancel"
          submitText="Save and Review for Submission"
          referralId=""
          formState={formState}
          isDisabledSubmit={
            !formState.screeningToolResult ||
            formState.intakeRecommendation.reasoning.length === 0
          }
          onSubmit={async () => {
            await post(intakesPath(), formState);
            setOpenModal(false);
          }}
        />
      }
    >
      {({ sectionRefs, handleDivClick }) => (
        <React.Fragment>
          <div
            id="reporter-information"
            ref={el => (sectionRefs.current["reporter-information"] = el)}
            onClick={() => handleDivClick("reporter-information")}
          >
            <ReporterInformation
              formState={formState}
              updateFormState={updateFormState}
            />
          </div>
          <div
            id="incident-information"
            ref={el => (sectionRefs.current["incident-information"] = el)}
            onClick={() => handleDivClick("incident-information")}
          >
            <IncidentInformation
              formState={formState}
              updateFormState={updateFormState}
            />
          </div>
          <div
            id="attachments"
            ref={el => (sectionRefs.current.attachments = el)}
            onClick={() => handleDivClick("attachments")}
          >
            <Attachments
              formState={formState}
              updateFormState={updateFormState}
            />
          </div>
          <div
            id="persons"
            ref={el => (sectionRefs.current.relations = el)}
            onClick={() => handleDivClick("relations")}
          >
            <Surface title="Persons" className={styles.surfaceContainer}>
              <NewPersonForm
                onPersonAdd={handlePersonAdd}
                formState={formState}
                buttonClass={styles.newPersonButton}
                hasNotice
                updateWindowsLocation={"#persons"}
                askMilitaryPersonnelInfo
              />
            </Surface>
          </div>
          <div
            id="relations"
            ref={el => (sectionRefs.current.relations = el)}
            onClick={() => handleDivClick("relations")}
          >
            <Surface
              title="Relations"
              hideTitle
              className={styles.surfaceContainer}
            >
              <Relations
                formState={formState}
                updateWindowsLocation="#relations"
                updateFormState={updateFormState}
                buttonClass={styles.newPersonButton}
              />
            </Surface>
          </div>
          <div
            id="worker-safety-concerns"
            ref={el => (sectionRefs.current["worker-safety-concerns"] = el)}
            onClick={() => handleDivClick("worker-safety-concerns")}
          >
            <WorkerSafetyConcern
              formState={formState}
              updateFormState={updateFormState}
            />
          </div>
          <div
            id="related-cases"
            ref={el => (sectionRefs.current["related-cases"] = el)}
            onClick={() => handleDivClick("related-cases")}
          >
            <RelatedCases
              buttonClass={styles.newPersonButton}
              formState={formState}
            />
          </div>
          <div
            id="allegations"
            ref={el => (sectionRefs.current.allegations = el)}
            onClick={() => handleDivClick("allegations")}
          >
            <Allegations
              value={formState.allegations}
              onChange={newAllegations =>
                updateFormState({ allegations: [...newAllegations] })
              }
              abuserOptions={formState.persons
                .filter(person => person.role.includes("alleged-abuser"))
                .map(p => ({ label: p.name, value: p.name }))}
              victimOptions={formState.persons
                .filter(person => person.role.includes("victim"))
                .map(p => ({ label: p.name, value: p.name }))}
            />
          </div>
          <div
            id="intake-screening-tool"
            ref={el => (sectionRefs.current["intake-screening-tool"] = el)}
            onClick={() => handleDivClick("intake-screening-tool")}
          >
            <IntakeScreeningTool
              formState={formState}
              updateFormState={updateFormState}
            />
          </div>
          <div
            id="intake-recommendation"
            ref={el => (sectionRefs.current["intake-recommendation"] = el)}
            onClick={() => handleDivClick("intake-recommendation")}
          >
            <IntakeRecommendation
              formState={formState}
              updateFormState={updateFormState}
            />
          </div>
          <div
            id="critical-incident"
            ref={el => (sectionRefs.current["critical-incident"] = el)}
            onClick={() => handleDivClick("critical-incident")}
          >
            <Surface
              title="Critical Incident"
              className={styles.surfaceContainer}
            >
              <Switch label="Report rises to a Critical Incident" />
            </Surface>
          </div>
          <IntakeSubmissionModal
            open={openModal}
            onCancel={() => setOpenModal(false)}
            intakeRecommendation={
              formState.intakeRecommendation.acceptIntakeResponse
            }
            recommendationReasoning={
              formState.intakeRecommendation.reasoning ?? ""
            }
            response={{
              label: formState.intakeRecommendation.acceptIntakeResponse
                ? formState.screeningToolResult?.label ?? ""
                : formState.intakeRecommendation.recommendedResponse,
              name: formState.intakeRecommendation.acceptIntakeResponse
                ? formState.screeningToolResult?.name ?? ""
                : formState.intakeRecommendation.recommendedResponseLevel,
            }}
          />
        </React.Fragment>
      )}
    </CreateViewLayout>
  );
};

export default NewMaltreatmentReportPage;
