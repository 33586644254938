import { Text, Surface, Link } from "@heart/components";

import styles from "./Report.module.scss";

const HelpBlock = () => (
  <Surface title="Help information" hideTitle gap="200">
    <Text textColor="danger-600" textStyle={"supporting-100"}>
      If the child is in immediate danger call 911
    </Text>
    <Text textColor="neutral-600" className={styles["help-block-title"]} as="p">
      Resources
    </Text>
    <ul className={styles["help-block-list"]}>
      <li>
        <Text style={{ color: "#38393D" }} textStyle="body-100" as="p">
          Want to report your concern over the phone? Call our child abuse hot
          line at &nbsp;
          <Link
            href="tel:(866)363-4276"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <b>(866) 363-4276</b>
          </Link>
        </Text>
      </li>
      <li>
        <Text textStyle="body-100">
          <Link
            href="https://www.dcyf.wa.gov/sites/default/files/pubs/CWP_0040.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Reporting Child Abuse or Neglect
          </Link>
        </Text>
      </li>
      <li>
        <Text textStyle="body-100">
          <Link
            href="https://www.dcyf.wa.gov/sites/default/files/pubs/22-163.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mandatory Reporting Law
          </Link>
        </Text>
      </li>
    </ul>
  </Surface>
);

export default HelpBlock;
