import {
  Actions,
  Button,
  ButtonMenu,
  Flex,
  HeartTable,
  Icons,
  InputRadioGroup,
  Pill,
  Surface,
  SurfaceForm,
  Text,
  If,
} from "@heart/components";
import { useEffect, useState } from "react";

import useFormState from "@components/shared/hooks/useFormState";

import AssessmentRecommendation from "./AssessmentRecommendation";
import styles from "./AssessmentsAndPlans.module.scss";
import ProtectingInterventionsInputs from "./ProtectingInterventionsInputs";
import SafetyFactorInputs from "./SafetyFactorInputs";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const AssesmentsAndPlans = () => {
  const [openForm, setOpenForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showSecondSection, setShowSecondSection] = useState();
  const { formState, updateFormState } = useFormState({
    safetyFactors: {},
    protectingInterventions: {},
    planningCapacities: {},
    assessmentRecommendation: {
      acceptAssessment: true,
    },
  });

  const onSubmit = e => {
    e.preventDefault();
    setOpenForm(false);
    setFormSubmitted(true);
  };

  const resetFormStates = () => {
    setOpenForm(false);
  };

  const handleSafetyFactorChange = (name, value) => {
    updateFormState({
      safetyFactors: {
        ...formState.safetyFactors,
        [name]: value,
      },
    });
  };

  const handleProtectingInterventionChange = (name, value) => {
    updateFormState({
      protectingInterventions: {
        ...formState.protectingInterventions,
        [name]: value,
      },
    });
  };

  const handleAssessmentRecommendationChange = (name, value) => {
    updateFormState({
      assessmentRecommendation: {
        ...formState.assessmentRecommendation,
        [name]: value,
      },
    });
  };

  const handlePlanningCapacityChange = (name, value) => {
    updateFormState({
      planningCapacities: {
        ...formState.planningCapacities,
        [name]: value,
      },
    });
  };

  useEffect(() => {
    // Check if any safety factor has a "Yes" response
    const safetyFactors = formState.safetyFactors || {};
    const hasAnyYesResponse = Object.values(safetyFactors).some(
      value => value === "Yes" && typeof value === "string"
    );
    setShowSecondSection(hasAnyYesResponse);
  }, [formState]);

  return (
    <Surface
      title="Assessments & Plans"
      hideTitle
      sectionId="assessments-and-plans"
    >
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Assessments & Plans
      </Text>
      <Table>
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Progress/Outcome</Th>
            <Th>Due Date</Th>
            <Th>Controls</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Button variant="tertiary" onClick={() => setOpenForm(true)}>
                Safety Assessment
              </Button>
            </Td>
            <Td>
              {formSubmitted ? (
                <Flex align="center" gap="100" className={styles.unsafePill}>
                  <Icons.ExclamationTriangle />
                  <Text textStyle="supporting-150" textColor="white">
                    Unsafe
                  </Text>
                </Flex>
              ) : null}
            </Td>
            <Td>08/20/2024</Td>
            <Td alignRight>
              <Flex gap="100" justify="end">
                <Icons.Edit />
                <Icons.Trash />
              </Flex>
            </Td>
          </Tr>
          {formSubmitted ? (
            <Tr>
              <Td alig>
                <Flex align="center">
                  <Icons.ArrowRight />
                  <Button variant="tertiary" onClick={() => {}}>
                    Safety Plan
                  </Button>
                </Flex>
              </Td>
              <Td></Td>
              <Td>08/20/2024</Td>
              <Td alignRight>
                <Flex gap="100" justify="end">
                  <Icons.Edit />
                  <Icons.Trash />
                </Flex>
              </Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
      <If condition={openForm}>
        <SurfaceForm
          title="New Allegation"
          hideTitle
          onSubmit={onSubmit}
          actions={
            <Actions
              primaryText="Save"
              primaryDisabled={!showResult}
              cancelAction={resetFormStates}
            />
          }
        >
          <Text textStyle="emphasis-200" textColor="neutral-600">
            New Safety Assessment
          </Text>
          <Surface title="Safety Factor Identification" hideTitle>
            <Text textStyle="body-200" textColor="neutral-600">
              Safety Factor Identification
            </Text>
            <SafetyFactorInputs
              formState={formState}
              handleSafetyFactorChange={handleSafetyFactorChange}
            />
          </Surface>
          <If condition={showSecondSection}>
            <Surface
              title="Safety Response - Planning Capacities and Protecting Interventions"
              hideTitle
            >
              <Text textStyle="body-200" textColor="neutral-600">
                Safety Response - Planning Capacities and Protecting
                Interventions
              </Text>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Planning Capacities
              </Text>
              <Flex column gap="200">
                <InputRadioGroup
                  id="caretakerCapable"
                  label="Caretaker is capable of participating in a safety plan"
                  orientation="row"
                  required
                  name="caretakerCapable"
                  values={["Yes", "No"]}
                  onChange={value =>
                    handlePlanningCapacityChange("caretakerCapable", value)
                  }
                  value={formState.planningCapacities?.caretakerCapable}
                />

                <InputRadioGroup
                  id="caretakerWilling"
                  label="Caretaker is willing to participate in a safety plan"
                  orientation="row"
                  required
                  name="caretakerWilling"
                  values={["Yes", "No"]}
                  onChange={value =>
                    handlePlanningCapacityChange("caretakerWilling", value)
                  }
                  value={formState.planningCapacities?.caretakerWilling}
                />

                <InputRadioGroup
                  id="caretakerSupport"
                  label="Caretaker has at least one supporting safe adult who was not involved in the allegation and is willing and able to participate in a safety plan"
                  orientation="row"
                  required
                  name="caretakerSupport"
                  values={["Yes", "No"]}
                  onChange={value =>
                    handlePlanningCapacityChange("caretakerSupport", value)
                  }
                  value={formState.planningCapacities?.caretakerSupport}
                />
              </Flex>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Protecting Interventions
              </Text>
              <ProtectingInterventionsInputs
                formState={formState}
                handleProtectingInterventionChange={
                  handleProtectingInterventionChange
                }
              />
            </Surface>
            <Button onClick={() => setShowResult(true)}>See result</Button>
            <If condition={showResult}>
              <Flex
                column
                align="center"
                gap="300"
                fullWidth
                className={styles.resultContent}
              >
                <Flex justify="center">
                  <Text textStyle="emphasis-300" textColor="neutral-600">
                    Result
                  </Text>
                </Flex>
                <hr />
                <Text textStyle="body-100">
                  Based on your agencies protocols, we have deterimined the
                  following result
                </Text>
                <Flex column align="center" gap="100">
                  <Flex gap="100" align="center" className={styles.resultRow}>
                    <Flex grow justify="start">
                      <Text textStyle="emphasis-100" textColor="neutral-600">
                        Response
                      </Text>
                    </Flex>
                    <Flex grow justify="center">
                      <Pill text="unsafe" variant="warning" />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <AssessmentRecommendation
                formState={formState}
                handleAssessmentRecommendationChange={
                  handleAssessmentRecommendationChange
                }
              />
            </If>
          </If>
        </SurfaceForm>
      </If>

      <ButtonMenu
        buttonProps={{
          children: (
            <Flex align="center" gap="100">
              <span>New Assessment</span>
              <Icons.ChevronDown />
            </Flex>
          ),
          variant: "secondary",
        }}
        linkItems={[
          {
            description: "Safety Assessment",
          },
          {
            description: "Risk Assessment",
          },
        ]}
      />
    </Surface>
  );
};

export default AssesmentsAndPlans;
