import { Surface, Text } from "@heart/components";
import React from "react";

import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";

import Diagnoses from "./tiles/Diagnoses";
import Medications from "./tiles/Medications";

const links = [
  { href: "#diagnoses", label: "Diagnoses" },
  { href: "#medications", label: "Medications" },
  { href: "#medical", label: "Medical" },
  { href: "#dental", label: "Dental" },
  { href: "#behavioral", label: "Behavioral" },
  { href: "#education", label: "Education" },
];

const WellBeing = () => (
  <LayoutSidebarSections links={links}>
    <Diagnoses />
    <Medications />
    <Surface title="Medical" hideTitle sectionId="medical">
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Medical
      </Text>
    </Surface>
    <Surface title="Dental" hideTitle sectionId="dental">
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Dental
      </Text>
    </Surface>
    <Surface title="Behavioral" hideTitle sectionId="behavioral">
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Behavioral
      </Text>
    </Surface>
    <Surface title="Education" hideTitle sectionId="education">
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Education
      </Text>
    </Surface>
  </LayoutSidebarSections>
);

export default WellBeing;
