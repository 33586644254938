import { Button, Flex, Icons, InputHidden } from "@heart/components";
import classNames from "classnames";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";

import HandlesOutsideClicks from "@components/HandlesOutsideClicks";

import preventDefault from "@lib/preventDefault";

import { MIN_ALLOWED_DATE } from "@root/constants";

import styles from "./InputDate.module.scss";
import "./InputDateReactCalendar.scss";
import { inputCommonPropTypes, BasicInputLayout } from "./common";

const parseDate = date => {
  if (typeof date === "string") {
    return moment(date).toDate();
  }

  return date;
};

// E.g. 01/08/2022 for Jan 8th.
export const DATE_PICKER_FORMAT = moment.localeData().longDateFormat("L");

const formatForLocale = date => {
  if (!date || date.length === 0) {
    return "";
  }

  const localizedDate = moment(date).format(DATE_PICKER_FORMAT);
  return localizedDate.length > 0 ? localizedDate : "";
};

const parseDateWithValidFormats = date => {
  const format2Ys = DATE_PICKER_FORMAT.replace("YYYY", "YY");
  return moment(date, [DATE_PICKER_FORMAT, format2Ys]);
};

export const formatInYYYYMMDD = date =>
  parseDateWithValidFormats(date).format("YYYY-MM-DD");

const InputDate = props => {
  const { error, maxDate, minDate, onBlur, onChange, placeholder, value } =
    props;

  useEffect(() => {
    if (!I18n.locale || I18n.locale === "xx") {
      moment.locale("en-US");
    } else {
      moment.locale(`${I18n.locale}-US`);
    }
  }, []);

  const errorTypes = {
    invalidDate: "invalidDate",
    beforeMinDate: "beforeMinDate",
    afterMaxDate: "afterMaxDate",
    none: "none",
  };

  const [localizedDate, setLocalizedDate] = useState(formatForLocale(value));
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(value);
  const [errorType, setErrorType] = useState(errorTypes.none);
  const [showCalendar, setShowCalendar] = useState(false);
  const [activateCallbacks, setActivateCallbacks] = useState(false);

  const parsedMaxDate = parseDate(maxDate);
  const parsedMinDate = parseDate(minDate) || parseDate(MIN_ALLOWED_DATE);

  useEffect(() => {
    setLocalizedDate(formatForLocale(value));
    setCalendarSelectedDate(value);
    setErrorType(errorTypes.none);
  }, [value]);

  useEffect(() => {
    if (isEmpty(localizedDate)) {
      return;
    }

    const parsedDate = parseDateWithValidFormats(localizedDate);
    const formattedDate = formatInYYYYMMDD(localizedDate);

    if (
      parsedDate.isValid() &&
      (!parsedMinDate || parsedDate.toDate() >= parsedMinDate) &&
      (!parsedMaxDate || parsedDate.toDate() <= parsedMaxDate)
    ) {
      setCalendarSelectedDate(formattedDate);
      setErrorType(errorTypes.none);
      setShowCalendar(false);
    } else if (parsedDate.toDate() < parsedMinDate) {
      setCalendarSelectedDate("");
      setErrorType(errorTypes.beforeMinDate);
      setShowCalendar(false);
    } else if (parsedMaxDate && parsedDate.toDate() > parsedMaxDate) {
      setCalendarSelectedDate("");
      setErrorType(errorTypes.afterMaxDate);
      setShowCalendar(false);
    } else if (localizedDate.length > 0) {
      setCalendarSelectedDate("");
      setErrorType(errorTypes.invalidDate);
      setShowCalendar(false);
    } else {
      setCalendarSelectedDate("");
      setLocalizedDate("");
      setErrorType(errorTypes.none);
      setShowCalendar(false);
    }

    if (activateCallbacks) {
      handleDateBlur();
    }
  }, [localizedDate]);

  const handleDateBlur = () => {
    const parsedDate = parseDateWithValidFormats(localizedDate);
    const formattedForLocale = formatForLocale(parsedDate);
    if (formattedForLocale !== localizedDate && !isEmpty(localizedDate))
      setLocalizedDate(formattedForLocale);

    if (errorType === errorTypes.none) {
      const formattedDate = formatInYYYYMMDD(localizedDate);
      if (onBlur) onBlur(formattedDate);
      if (onChange) onChange(isEmpty(localizedDate) ? null : formattedDate);
    }

    setActivateCallbacks(false);
  };

  const handleDateChange = selected => {
    const newDate = selected.target.value;
    setLocalizedDate(newDate);
    setCalendarSelectedDate(formatInYYYYMMDD(newDate));
  };

  const handleCalendarDateChange = async newDate => {
    const formattedDate = formatForLocale(newDate);
    setActivateCallbacks(true);
    setLocalizedDate(formattedDate);
    setShowCalendar(false);
  };

  const handleCalendarClick = preventDefault(() => {
    setShowCalendar(!showCalendar);
  });

  let dateForCalendar = new Date();
  if (isNaN(Date.parse(calendarSelectedDate)) === false) {
    dateForCalendar = moment(calendarSelectedDate).toDate();
  }

  let errorMessage = error;
  if (errorType === errorTypes.beforeMinDate) {
    errorMessage = I18n.t("javascript.components.date_picker.before_min_date", {
      min_date: formatForLocale(parsedMinDate),
    });
  } else if (errorType === errorTypes.afterMaxDate) {
    errorMessage = I18n.t("javascript.components.date_picker.after_max_date", {
      max_date: formatForLocale(parsedMaxDate),
    });
  } else if (errorType === errorTypes.invalidDate) {
    errorMessage = I18n.t("javascript.components.date_picker.error", {
      format: DATE_PICKER_FORMAT,
    });
  }

  return (
    <BasicInputLayout
      {...props}
      error={errorMessage}
      inputComponent={inputProps => (
        <Flex
          column
          gap="100"
          className={classNames(styles.wrapper, "react-datepicker", {
            "has-error": inputProps.error,
          })}
        >
          <Flex row gap="0">
            <input
              {...inputProps}
              name={
                /** The hidden input below is submitted, not this one */
                undefined
              }
              className={styles.input}
              type="text"
              onChange={handleDateChange}
              onBlur={() => {
                setActivateCallbacks(true);
                handleDateBlur();
              }}
              value={localizedDate}
              placeholder={placeholder || DATE_PICKER_FORMAT}
              autoComplete="off"
            />
            <InputHidden
              {...inputProps}
              /** These input props are on the text input */
              id={undefined}
              data-testid={undefined}
              name={inputProps.name}
              value={calendarSelectedDate || ""}
              disabled={inputProps.disabled}
            />
            <Button
              variant="secondary"
              onClick={handleCalendarClick}
              disabled={inputProps.disabled}
              icon={Icons.Calendar}
              description={I18n.t(
                "javascript.components.date_picker.show_calendar_button",
                {
                  format: DATE_PICKER_FORMAT,
                }
              )}
            />
          </Flex>
          <If condition={showCalendar}>
            <div className={styles.calendarContainer}>
              <HandlesOutsideClicks
                onOutsideClick={() => setShowCalendar(false)}
              >
                <Calendar
                  onChange={handleCalendarDateChange}
                  value={dateForCalendar}
                  locale={`${I18n.locale}-US`}
                  minDate={parsedMinDate}
                  maxDate={parsedMaxDate}
                  prev2Label={<Icons.AngleDoubleLeft />}
                  prevLabel={<Icons.AngleLeft />}
                  next2Label={<Icons.AngleDoubleRight />}
                  nextLabel={<Icons.AngleRight />}
                />
              </HandlesOutsideClicks>
            </div>
          </If>
        </Flex>
      )}
    />
  );
};

InputDate.propTypes = {
  ...inputCommonPropTypes,
  /** The current date: anything that `moment(date)` can handle */
  value: PropTypes.string,
  /** id of the visible input into which users may type the date as text. */
  id: PropTypes.string,
  onBlur: PropTypes.func,
  /** Callback that executes with a "YYYY-MM-DD" string when a new valid date is entered */
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

export default InputDate;
