import {
  Flex,
  Text,
  Surface,
  InputFilterable,
  If,
  Actions,
  SurfaceForm,
  InputText,
  Button,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import sharedStyles from "@components/shared/styles/SharedStyles.module.scss";

import { useCaseActivity } from "../CaseActivityContext";
import CollateralCard from "./CollateralCard";

let id = 0;

const PersonsCollateral = ({ personsAssociatedWithCase, worker }) => {
  const { formState, updateFormState } = useCaseActivity();

  const [isAddingNewPerson, setIsAddingNewPerson] = React.useState(false);

  const personsAssociatedWithCaseOptions = personsAssociatedWithCase
    ? personsAssociatedWithCase.map(person => ({
        label: `${person.firstName} ${person.lastName}`,
        value: person.id,
        id: person.id,
      }))
    : [
        {
          id: "liam-brown",
          firstName: "Liam",
          lastName: "Brown",
        },
        {
          id: "rachel-brown",
          firstName: "Rachel",
          lastName: "Brown",
        },
      ];

  const workerOptions = worker || {
    id: "jackson-dirk",
    firstName: "Jackson",
    lastName: "Dirk",
    label: "Jackson Dirk",
    value: "jackson-dirk",
  };

  const handleBehalfOf = persons => {
    updateFormState({
      persons: {
        ...formState.persons,
        behalfOf: persons,
      },
    });
  };

  const handlePersonsChange = persons => {
    updateFormState({
      persons: {
        ...formState.persons,
        engaged: persons,
      },
    });
  };

  const handleAddCollateral = (firstName, middleName, lastName, personId) => {
    updateFormState({
      persons: {
        ...formState.persons,
        collateral: [
          ...formState.persons.collateral,
          { firstName, middleName, lastName, id: personId },
        ],
      },
    });
  };

  const resetFormStates = () => {
    setIsAddingNewPerson(false);
  };

  useEffect(() => {
    if (!isAddingNewPerson) {
      resetFormStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingNewPerson]);

  const handleDeleteCollateral = personId => {
    updateFormState({
      persons: {
        ...formState.persons,
        collateral: formState.persons.collateral.filter(
          person => person.id !== personId
        ),
      },
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const key = id;
    id += 1;

    const firstName = formData.get("firstName");
    const middleName = formData.get("middleName");
    const lastName = formData.get("lastName");

    handleAddCollateral(firstName, middleName, lastName, key);

    resetFormStates();
    // if (updateWindowsLocation) window.location = updateWindowsLocation;
  };

  return (
    <Surface title="Persons and Collateral" sectionId="persons">
      <Flex gap="300" column>
        <InputFilterable
          id="personsEngaged"
          label={
            <React.Fragment>
              <Text textStyle="supporting-100" textColor="neutral-600">
                Persons Engaged
              </Text>
            </React.Fragment>
          }
          name="personsEngaged"
          description={
            <Text textStyle="supporting-50" textColor="neutral-600">
              Please select the persons and workers associated with this case
            </Text>
          }
          required
          isMulti
          showSelectAll
          value={formState.persons.engaged}
          onChange={handlePersonsChange}
          values={[...personsAssociatedWithCaseOptions, workerOptions]}
        />

        <InputFilterable
          id="behalfOf"
          label={
            <React.Fragment>
              <Text textStyle="supporting-100" textColor="neutral-600">
                In reference to / On behalf of
              </Text>
            </React.Fragment>
          }
          required
          name="behalfOf"
          isMulti
          showSelectAll
          value={formState.persons.behalfOf}
          onChange={handleBehalfOf}
          values={personsAssociatedWithCaseOptions}
        />

        <Text textStyle="emphasis-300" textColor="neutral-600">
          Collateral
        </Text>

        {formState.persons.collateral.map(person => (
          <CollateralCard
            key={person.id}
            id={person.id}
            firstName={person.firstName}
            middleName={person.middleName}
            lastName={person.lastName}
            handleDelete={handleDeleteCollateral}
          />
        ))}

        <If condition={isAddingNewPerson}>
          <SurfaceForm
            title="New Collateral"
            hideTitle
            onSubmit={onSubmit}
            actions={
              <Actions cancelAction={resetFormStates} primaryText="Save" />
            }
          >
            <Flex row gap="100">
              <InputText label="First Name" required name="firstName" />
              <InputText label="Middle Name" name="middleName" />
              <InputText label="Last Name" required name="lastName" />
            </Flex>
          </SurfaceForm>
        </If>

        <Button
          variant="secondary"
          disabled={isAddingNewPerson}
          onClick={() => setIsAddingNewPerson(true)}
          className={sharedStyles.addNewButton}
        >
          New Collateral
        </Button>
      </Flex>
    </Surface>
  );
};
PersonsCollateral.propTypes = {
  personsAssociatedWithCase: PropTypes.array,
  worker: PropTypes.object,
};

export default PersonsCollateral;
