import {
  Text,
  Surface,
  Flex,
  InputRadioGroup,
  If,
  Link,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ProfileCard from "./ProfileCard";

const ReporterInformation = ({ currentUser = {}, userAuthenticated, step }) => {
  const [isMandatedReporter, setIsMandatedReporter] = useState();

  return (
    <Surface title="Reporter information" hideTitle>
      <Flex column gap="200">
        <Flex justify="space-between">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Tell us about yourself
          </Text>
        </Flex>
        <If condition={!userAuthenticated}>
          <Flex gap="100" column>
            <InputRadioGroup
              label="Are you a mandated Reporter"
              value={isMandatedReporter}
              onChange={setIsMandatedReporter}
              name="is_mandated_reporter"
              required
              orientation="row"
              values={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
            <If condition={isMandatedReporter}>
              <Text textStyle="body-100" as="p">
                <Link href="/login">Login</Link>
                &nbsp;or&nbsp;
                <Link href="/login">Create Account</Link>
              </Text>
            </If>
          </Flex>
        </If>
        <If condition={userAuthenticated}>
          <ProfileCard person={currentUser} showBanner={step === 1} />
        </If>
      </Flex>
    </Surface>
  );
};

ReporterInformation.propTypes = {
  userAuthenticated: PropTypes.bool,
  currentUser: PropTypes.object,
  step: PropTypes.number.isRequired,
};

export default ReporterInformation;
