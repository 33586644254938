import { Icons } from "@heart/components";
import classNames from "classnames";
import { isNumber } from "lodash";
import PropTypes from "prop-types";

import Flex from "../layout/Flex";
import styles from "./TabPanelButton.module.scss";

const TabPanelButton = ({
  id,
  active,
  count,
  loading,
  onClick,
  tabRef,
  className,
  title,
}) => (
  <button
    id={id}
    role="tab"
    title={title}
    aria-selected={active}
    aria-controls={`${id}-panel`}
    className={classNames(styles.navigationButton, className, {
      [styles.activeTab]: active,
    })}
    onClick={onClick}
    ref={tabRef}
    tabIndex={active ? 0 : -1}
    data-heart-component="TabPanelButton"
  >
    <Flex row align="center">
      {title}
      <If condition={isNumber(count) || loading}>
        <span className={styles.count}>
          {loading ? <Icons.Spinner /> : count}
        </span>
      </If>
    </Flex>
  </button>
);

TabPanelButton.propTypes = {
  /** Unique identifier for the content tab */
  id: PropTypes.string.isRequired,
  /** Whether tab is currently active, determines whether tab panel is hidden */
  active: PropTypes.bool,
  /** Count value to display in button */
  count: PropTypes.number,
  /** Adds a loading spinner to the button if content is loading */
  loading: PropTypes.bool,
  /** What to do when the button is clicked */
  onClick: PropTypes.func.isRequired,
  /** `ref` for the tab */
  tabRef: PropTypes.object.isRequired,
  /** Title for the tab */
  title: PropTypes.string.isRequired,
};

export default TabPanelButton;
