import { Flex, Link } from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import PropTypes from "prop-types";

import styles from "./LayoutSidebarSections.module.scss";

const LayoutSidebarSections = ({ links, children }) => (
  <Flex row gap="300" className={styles.layoutWithSidebar}>
    <ResolutionOnly medium over>
      <Flex column gap="200" className={styles.sidebar}>
        {links.map(link => (
          <Link
            key={link.href}
            href={link.href}
            textStyle="emphasis-100"
            navigation
          >
            {link.label}
          </Link>
        ))}
      </Flex>
    </ResolutionOnly>
    <Flex column grow>
      {children}
    </Flex>
  </Flex>
);

LayoutSidebarSections.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  children: PropTypes.node.isRequired,
};

export default LayoutSidebarSections;
