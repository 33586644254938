import { Surface, Flex, Text, Button, Pill, If } from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import { useMediaQuery } from "@react-hookz/web";
import PropTypes from "prop-types";
import React from "react";

import useAutoSave from "@components/shared/hooks/useAutoSave";

import styles from "./AutoSaveStickyFooter.module.scss";

const AutoSaveStickyFooter = ({
  formState,
  referralId = "INT-232423-003",
  submitText,
  saveText,
  cancelText,
  onSubmit,
  isDisabledSubmit = false,
}) => {
  const { pillInfo } = useAutoSave(formState);

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Surface
      title={"Sticky Footer Save"}
      hideTitle
      className={styles.stickyFooter}
    >
      <Flex justify="space-between" align="center">
        <ResolutionOnly medium over>
          <Text textColor="neutral-600" textStyle="emphasis-100">
            {referralId ? `Referral ID: ${referralId}` : ""}
          </Text>
        </ResolutionOnly>

        <ResolutionOnly medium under>
          <Text textColor="neutral-600" textStyle="emphasis-100">
            {!isMobile && referralId}
          </Text>
        </ResolutionOnly>

        <ResolutionOnly small over>
          <Flex row gap="200" align="center">
            <Pill variant={pillInfo.variant} text={pillInfo.text} />
            <Button variant="tertiary">{cancelText}</Button>
            <If condition={saveText}>
              <Button variant="secondary">{saveText}</Button>
            </If>
            <Button
              onClick={() => {
                onSubmit?.();
              }}
              disabled={isDisabledSubmit}
              variant="primary"
            >
              {submitText}
            </Button>
          </Flex>
        </ResolutionOnly>

        <ResolutionOnly small under>
          <Flex row gap="100" align="center">
            <Button variant="tertiary">{cancelText}</Button>
            <Button variant="secondary">{saveText}</Button>
            <Button
              onClick={() => {
                onSubmit?.();
              }}
              disabled={isDisabledSubmit}
              variant="primary"
            >
              {isMobile ? "Submit" : submitText}
            </Button>
          </Flex>
        </ResolutionOnly>
      </Flex>
    </Surface>
  );
};

AutoSaveStickyFooter.propTypes = {
  formState: PropTypes.object,
  referralId: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  saveText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  isDisabledSubmit: PropTypes.bool,
};

export default AutoSaveStickyFooter;
