import { Surface } from "@heart/components";

import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";
import sharedStyles from "@components/shared/styles/SharedStyles.module.scss";

const links = [
  {
    href: "#placement-history",
    label: "Placement History",
  },
  {
    href: "#respite-and-other-temporary-placement-absences",
    label: "Respite and Other Temporary Placement Absences",
  },
  {
    href: "#placement-searches",
    label: "Placement Searches",
  },
];

const Placements = () => (
  <LayoutSidebarSections links={links}>
    <Surface
      title="Placement History"
      className={sharedStyles.surfaceContainer}
    ></Surface>
    <Surface
      title="Respite and Other Temporary Placement Absences"
      className={sharedStyles.surfaceContainer}
    ></Surface>
    <Surface
      title="Placement Searches"
      className={sharedStyles.surfaceContainer}
    ></Surface>
  </LayoutSidebarSections>
);

export default Placements;
