import React from "react";

const FireworksIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2450_21724)">
      <path
        d="M12 2.25C12 2.3 12 13.5 12 13.5"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.4701C12 13.4701 14.02 7.5791 19.183 6.1001"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.47C11.3712 11.7309 10.3802 10.1451 9.09265 8.81769C7.80505 7.49027 6.25019 6.45146 4.53101 5.77002"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 12.7521C4.45386 11.5441 8.47976 11.801 12 13.4701"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.91199 19.0369C4.91199 19.0369 7.49999 13.1829 12 13.4699"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.25 12.7521C19.5461 11.5441 15.5202 11.801 12 13.4701"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.088 19.0369C19.088 19.0369 16.5 13.1829 12 13.4699"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 0.75V3.75"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 2.25H1.5"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19.5V22.5"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 21H10.5"
        stroke="#D6276D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.875 23.25C1.66789 23.25 1.5 23.0821 1.5 22.875C1.5 22.6679 1.66789 22.5 1.875 22.5"
        stroke="#D6276D"
        strokeWidth="1.5"
      />
      <path
        d="M1.875 23.25C2.08211 23.25 2.25 23.0821 2.25 22.875C2.25 22.6679 2.08211 22.5 1.875 22.5"
        stroke="#D6276D"
        strokeWidth="1.5"
      />
      <path
        d="M22.125 18C21.9179 18 21.75 17.8321 21.75 17.625C21.75 17.4179 21.9179 17.25 22.125 17.25"
        stroke="#D6276D"
        strokeWidth="1.5"
      />
      <path
        d="M22.125 18C22.3321 18 22.5 17.8321 22.5 17.625C22.5 17.4179 22.3321 17.25 22.125 17.25"
        stroke="#D6276D"
        strokeWidth="1.5"
      />
      <path
        d="M18.375 3C18.1679 3 18 2.83211 18 2.625C18 2.41789 18.1679 2.25 18.375 2.25"
        stroke="#D6276D"
        strokeWidth="1.5"
      />
      <path
        d="M18.375 3C18.5821 3 18.75 2.83211 18.75 2.625C18.75 2.41789 18.5821 2.25 18.375 2.25"
        stroke="#D6276D"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_2450_21724">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FireworksIcon;
