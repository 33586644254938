import { Surface, Text, Flex, Button, If, Pill } from "@heart/components";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import styles from "./IntakeScreeningTool.module.scss";
import Questions from "./Questions";
import questions from "./questions.json";

const IntakeScreeningTool = ({ formState, updateFormState }) => {
  const [displayQuestions, setDisplayQuestions] = useState(false);

  useEffect(() => {
    if (formState.allegations.length === 0) {
      setDisplayQuestions(false);
      updateFormState({
        screeningToolResult: null,
        intakeRecommendation: {
          acceptIntakeResponse: true,
          reasoning: "",
          recommendedResponse: "",
          recommendedResponseLevel: null,
        },
      });
    }
  }, [formState.allegations]);

  return (
    <Surface title="Intake Screening Tool" hideTitle>
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Intake screening tool
      </Text>
      <If condition={displayQuestions}>
        <Questions
          question={questions.Q0}
          handleResult={r => updateFormState({ screeningToolResult: r })}
        />
      </If>
      <If condition={!formState.screeningToolResult}>
        <Flex
          align="center"
          justify="center"
          className={styles.screeningEmptyState}
        >
          <img src="/assets/ccwis/screening-empty-state.png" alt="" />
          <Flex column gap="200" className={styles.screeningEmptyStateText}>
            <Text textStyle="emphasis-300" textColor="neutral-600">
              {formState.allegations.length > 0
                ? "Continue answering to see your result"
                : "No Current Recommendation"}
            </Text>
            <Text textStyle="body-100" textColor="neutral-500">
              SDM answer will appear once you have reached the end of the
              question tree.
            </Text>
          </Flex>
        </Flex>
      </If>
      <If condition={formState.screeningToolResult}>
        <Flex column align="center" gap="300" className={styles.resultContent}>
          <Flex justify="center">
            <Text textStyle="emphasis-300" textColor="neutral-600">
              Result
            </Text>
          </Flex>
          <hr />
          <Text textStyle="body-100">
            Based on your agencies protocols, we have deterimined the following
            result
          </Text>
          <Flex column align="center" gap="100">
            <Flex gap="100" align="center" className={styles.resultRow}>
              <Flex grow justify="start">
                <Text textStyle="emphasis-100" textColor="neutral-600">
                  Response
                </Text>
              </Flex>
              <Flex grow justify="center">
                <Pill
                  text={formState.screeningToolResult.label}
                  variant={
                    formState.screeningToolResult.label === "Screened out"
                      ? "info"
                      : "warning"
                  }
                />
              </Flex>
            </Flex>
            <If condition={formState.screeningToolResult.name}>
              <Flex gap="100" align="center" className={styles.resultRow}>
                <Flex grow justify="start">
                  <Text textStyle="emphasis-100" textColor="neutral-600">
                    Response Level
                  </Text>
                </Flex>
                <Flex grow justify="center">
                  <Pill
                    text={formState.screeningToolResult.name}
                    variant={formState.screeningToolResult.pillVariant}
                  />
                </Flex>
              </Flex>
            </If>
          </Flex>
        </Flex>
      </If>
      <If condition={formState.allegations.length > 0 && !displayQuestions}>
        <Flex justify="center">
          <Button
            onClick={() => setDisplayQuestions(true)}
            variant={formState.screeningToolResult ? "secondary" : "primary"}
          >
            {formState.screeningToolResult
              ? "Restart Screening"
              : "Begin Screening"}
          </Button>
        </Flex>
      </If>
    </Surface>
  );
};

IntakeScreeningTool.propTypes = {
  formState: PropTypes.shape.isRequired,
  updateFormState: PropTypes.func.isRequired,
};

export default IntakeScreeningTool;
