export const MANDATED_REPORTER = "mandated_reporter";
export const INTAKE = "intake";
export const WORKER = "worker";
export const SUPERVISOR = "supervisor";
export const INVESTIGATIONS = "investigations";

export const roles = {
  MANDATED_REPORTER,
  INTAKE,
  WORKER,
  SUPERVISOR,
  INVESTIGATIONS,
};

export const hasSingleRole = (rolesOfUser, role) => rolesOfUser.includes(role);

export const hasAllRoles = (rolesOfUser, expectedRoles) =>
  expectedRoles.every(role => rolesOfUser.includes(role));
