import { Button, Flex } from "@heart/components";

import AllegationsAndFindings from "@components/shared/allegations_and_findings/AllegationsAndFindings";
import Attachments from "@components/shared/attachments/Attachments";
import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";

import { setBase64SearchParams } from "@lib/base64SearchParams";

import Interviews from "../InvestigationCards/Interviews";
import AssesmentsAndPlans from "../assessments_and_plans/AssessmentsAndPlans";
import { useInvestigation } from "./InvestigationContext";

const links = [
  {
    href: "#interviews",
    label: "Interviews",
  },
  {
    href: "#assessments-and-plans",
    label: "Assessments & Plans",
  },
  {
    href: "#attachments",
    label: "Attachments",
  },
  {
    href: "#allegations-and-findings",
    label: "Allegations & Findings",
  },
];

const ResearchAndFindings = () => {
  const {
    formState,
    updateFormState,
    allegationsAndFindings,
    setAllegationsAndFindings,
    currentUser,
  } = useInvestigation();

  return (
    <LayoutSidebarSections links={links}>
      <Interviews />
      <AssesmentsAndPlans />
      <Attachments formState={formState} updateFormState={updateFormState} />
      <AllegationsAndFindings
        user={currentUser}
        value={allegationsAndFindings}
        onChange={setAllegationsAndFindings}
        abuserOptions={formState.persons
          .filter(person => (person.role || []).includes("alleged-abuser"))
          .map(p => ({ label: p.name, value: p.name }))}
        victimOptions={formState.persons
          .filter(person => (person.role || []).includes("victim"))
          .map(p => ({ label: p.name, value: p.name }))}
      />
    </LayoutSidebarSections>
  );
};

export default ResearchAndFindings;
