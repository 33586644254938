import { Pill, Button, Flex, Link } from "@heart/components";

const formatFamilyNames = familyInstitutionNames => {
  const displayNames = familyInstitutionNames.slice(0, 3);
  const remainingCount = familyInstitutionNames.length - 3;

  return (
    <Flex row style={{ whiteSpace: "nowrap", display: "inline-flex" }}>
      {displayNames?.map((name, index) => (
        <span key={index}>
          {name}
          {index < displayNames.length - 1 ? ", " : ""}
        </span>
      ))}
      {remainingCount > 0 && (
        <span>
          and <a href="#">{remainingCount} more</a>
        </span>
      )}
    </Flex>
  );
};

export const dataTabTwo = [
  // {
  //   referralId: "INV-56978",
  //   reportTimestamp: "12/04/2024 4:50 AM",
  //   familyInstitutionNames: ["Garcia (5)", "Martinez (9)", "Garcia (29)"],
  //   priorityRecommendation: { priority: "R2" },
  //   workerAssigned: "",
  //   approvals: "",
  // },
];

export const columnsTabOne = [
  {
    cell: ({ referralId }) => (
      <Link href={`/investigations/review/${referralId}`}>{referralId}</Link>
    ),
    columnName: {
      name: "Investigation Id",
    },
    columnSort: function noRefCheck() {},
    id: "referralId",
  },
  {
    cell: "reportTimestamp",
    columnName: {
      name: "Report Timestamp",
    },
    columnSort: function noRefCheck() {},
    id: "reportTimestamp",
  },
  {
    cell: ({ priorityRecommendation: { priority } } = {}) => (
      <Pill
        variant={
          priority === "R1"
            ? "warning"
            : priority === "R2"
            ? "alert"
            : "neutral"
        }
        text={priority}
      />
    ),
    columnName: {
      name: "Response",
    },
    id: "response",
  },
  {
    cell: ({ familyInstitutionNames }) =>
      formatFamilyNames(familyInstitutionNames),
    columnName: {
      name: "Family/Institution Name(s)",
    },
    id: "familyInstitutionNames",
  },
  {
    cell: "workerAssigned",
    columnName: {
      name: "Worker Assigned",
    },
    id: "workerAssigned",
  },
  {
    columnName: { name: "Approvals", justify: "center" },
    id: "approvals",
    cell: "approvals",
  },
  {
    columnName: { name: "Actions", justify: "center" },
    id: "actions",
    cell: ({ referralId }) => (
      <Flex justify="center" align="center">
        <Button variant="secondary" href={`/case-activity/${referralId}/new`}>
          Add Case Activity
        </Button>
      </Flex>
    ),
  },
];

export const columnsTabTwo = [
  {
    cell: ({ referralId }) => (
      <Link href={`/investigations/review/${referralId}`}>{referralId}</Link>
    ),
    columnName: {
      name: "Investigation Id",
    },
    columnSort: function noRefCheck() {},
    id: "referralId",
  },
  {
    cell: "reportTimestamp",
    columnName: {
      name: "Report Timestamp",
    },
    columnSort: function noRefCheck() {},
    id: "reportTimestamp",
  },
  {
    cell: ({ priorityRecommendation: { priority } } = {}) => (
      <Pill
        variant={
          priority === "R1"
            ? "warning"
            : priority === "R2"
            ? "alert"
            : "neutral"
        }
        text={priority}
      />
    ),
    columnName: {
      name: "Response",
    },
    id: "response",
  },
  {
    cell: ({ familyInstitutionNames }) =>
      formatFamilyNames(familyInstitutionNames),
    columnName: {
      name: "Family/Institution Name(s)",
    },
    id: "familyInstitutionNames",
  },
  {
    cell: "workerAssigned",
    columnName: {
      name: "Worker Assigned",
    },
    id: "workerAssigned",
  },
  {
    columnName: { name: "Approvals", justify: "center" },
    id: "approvals",
    cell: "approvals",
  },
  {
    columnName: { name: "Actions", justify: "center" },
    id: "actions",
    cell: ({ referralId }) => (
      <Flex justify="center" align="center">
        <Button variant="secondary" href={`/case-activity/${referralId}/new`}>
          Add Case Activity
        </Button>
      </Flex>
    ),
  },
];

export const dataTabOne = [
  // {
  //   referralId: "INV-57111",
  //   reportTimestamp: "12/10/2024 11:20 AM",
  //   familyInstitutionNames: ["Mitchell (7)", "Brown (10)", "Mitchell (29)"],
  //   priorityRecommendation: { priority: "R1" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57100",
  //   reportTimestamp: "12/09/2024 2:50 PM",
  //   familyInstitutionNames: [
  //     "Thomas (5)",
  //     "Harris (8)",
  //     "Thomas (35)",
  //     "Nguyen (5)",
  //     "Lee (9)",
  //     "Nguyen (28)",
  //   ],
  //   priorityRecommendation: { priority: "R2" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57089",
  //   reportTimestamp: "12/09/2024 7:30 AM",
  //   familyInstitutionNames: ["Lee (9)", "Green (12)", "Lee (30)"],
  //   priorityRecommendation: { priority: "R1" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57078",
  //   reportTimestamp: "12/08/2024 4:00 PM",
  //   familyInstitutionNames: ["Garcia (6)", "Taylor (11)", "Garcia (31)"],
  //   priorityRecommendation: { priority: "R2" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57067",
  //   reportTimestamp: "12/07/2024 10:45 AM",
  //   familyInstitutionNames: ["Hall (8)", "Perez (3)", "Hall (34)"],
  //   priorityRecommendation: { priority: "R3" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57056",
  //   reportTimestamp: "12/06/2024 1:15 PM",
  //   familyInstitutionNames: ["Robinson (7)", "Miller (10)", "Robinson (36)"],
  //   priorityRecommendation: { priority: "R2" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "Findings",
  // },
  // {
  //   referralId: "INV-57045",
  //   reportTimestamp: "12/06/2024 8:00 AM",
  //   familyInstitutionNames: ["White (2)", "Davis (5)", "White (27)"],
  //   priorityRecommendation: { priority: "R1" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57034",
  //   reportTimestamp: "12/05/2024 6:20 PM",
  //   familyInstitutionNames: ["Nguyen (5)", "Lee (9)", "Nguyen (28)"],
  //   priorityRecommendation: { priority: "R2" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57001",
  //   reportTimestamp: "12/05/2024 1:30 PM",
  //   familyInstitutionNames: ["Carter (8)", "Carter (14)", "Brown (35)"],
  //   priorityRecommendation: { priority: "R1" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57012",
  //   reportTimestamp: "12/05/2024 9:45 AM",
  //   familyInstitutionNames: ["Adams (6)", "Williams (10)", "Adams (33)"],
  //   priorityRecommendation: { priority: "R2" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-57023",
  //   reportTimestamp: "12/04/2024 3:10 PM",
  //   familyInstitutionNames: ["Johnson (4)", "Smith (7)", "Johnson (40)"],
  //   priorityRecommendation: { priority: "R1" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
  // {
  //   referralId: "INV-56934",
  //   reportTimestamp: "12/04/2024 2:45 PM",
  //   familyInstitutionNames: ["Martin (4)", "Taylor (6)", "Martin (27)"],
  //   priorityRecommendation: { priority: "R2" },
  //   workerAssigned: "Jackson Dirk",
  //   approvals: "",
  // },
];
