import { Surface, Text } from "@heart/components";
import React from "react";

import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";

import Demographics from "./tiles/Demographics";
import StrengthsAndChallenges from "./tiles/StrengthsAndChallenges";

const links = [
  { label: "Demographics", href: "#demographics" },
  { label: "Contact Information", href: "#contact-information" },
  { label: "Strengths & Challenges", href: "#strengths-challenges" },
  { label: "ICWA", href: "#icwa" },
  {
    label: "Adoption & Guardianship Information",
    href: "#adoption-guardianship",
  },
];

const PersonalInformation = () => (
  <LayoutSidebarSections links={links}>
    <Demographics />
    <Surface
      title="Contact Information"
      hideTitle
      sectionId="contact-information"
    >
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Contact Information
      </Text>
    </Surface>
    <StrengthsAndChallenges />
    <Surface title="ICWA" hideTitle sectionId="icwa">
      <Text textStyle="emphasis-200" textColor="neutral-600">
        ICWA
      </Text>
    </Surface>
    <Surface
      title="Adoption & Guardianship Information"
      hideTitle
      sectionId="adoption-guardianship"
    >
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Adoption & Guardianship Information
      </Text>
    </Surface>
  </LayoutSidebarSections>
);

export default PersonalInformation;
