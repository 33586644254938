import {
  Surface,
  Text,
  HeartTable,
  Flex,
  Icons,
  Button,
  TableCellList,
  LabeledContent,
} from "@heart/components";

import { usePersonInformation } from "../PersonContext";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const Diagnoses = () => {
  const { formState } = usePersonInformation();

  return (
    <Surface title="Diagnoses" hideTitle sectionId="diagnoses">
      <Flex column gap="200" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Diagnoses
        </Text>
        {formState.diagnoses && formState.diagnoses.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>Diagnosis details</Th>
                <Th>Supporting documentation</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {formState.diagnoses.map((diagnose, i) => (
                <Tr key={`medication-${i}`}>
                  <Td>
                    <TableCellList
                      items={[
                        <LabeledContent
                          key="diagnosis"
                          label="Name of Diagnosis"
                          content={diagnose.label}
                        />,
                        <LabeledContent
                          key="dates"
                          label="Start/End Date of Diagnosis"
                          content={`${diagnose.startDate}/${diagnose.endDate}`}
                        />,
                        <LabeledContent
                          key="provider"
                          label="Provider who made diagnosis"
                          content={diagnose.providerName}
                        />,
                      ]}
                    />
                  </Td>
                  <Td>{diagnose.supportingDocuments.join(", ")}</Td>
                  <Td alignRight>
                    <Flex justify="end">
                      <Icons.Edit onClick={() => {}} />
                      <Icons.Trash onClick={() => {}} />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : null}
        <Button>Add Diagnosis</Button>
      </Flex>
    </Surface>
  );
};

export default Diagnoses;
