import {
  Surface,
  Text,
  InputRadioGroup,
  InputTextarea,
  If,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const IntakeRecommendation = ({ formState, updateFormState }) => {
  const [acceptIntake, setAcceptIntake] = useState(
    formState.intakeRecommendation.acceptIntakeResponse
  );
  const [recommendedResponse, setRecommendedResponse] = useState(
    formState.intakeRecommendation.recommendedResponse
  );
  const [recommendedLevel, setRecommendedLevel] = useState(
    formState.intakeRecommendation.recommendedResponseLevel
  );

  useEffect(() => {
    if (acceptIntake) {
      setRecommendedResponse(null);
    }
  }, [acceptIntake]);

  useEffect(() => {
    if (recommendedResponse === "Screened out") {
      setRecommendedLevel(null);
      updateFormState({
        intakeRecommendation: {
          ...formState.intakeRecommendation,
          recommendedResponseLevel: null,
        },
      });
    }
  }, [recommendedResponse]);

  return (
    <Surface title="Intake Recommendation" hideTitle>
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Intake Recommendations
      </Text>
      <InputRadioGroup
        id="acceptIntake"
        name="acceptIntake"
        label="Accept Intake Screening Tool Response Level"
        orientation="row"
        required
        value={acceptIntake}
        onChange={v => {
          setAcceptIntake(v);
          updateFormState({
            intakeRecommendation: {
              ...formState.intakeRecommendation,
              acceptIntakeResponse: v,
            },
          });
        }}
        values={[
          {
            label: formState.screeningToolResult
              ? `Yes: ${formState.screeningToolResult.name || ""} ${
                  formState.screeningToolResult.label
                }`
              : "Yes",
            value: true,
          },
          {
            label: "No: Discretionary Override (requires Supervisor Approval)",
            value: false,
          },
        ]}
        disabled={!formState.screeningToolResult}
      />
      <If condition={!acceptIntake}>
        <InputRadioGroup
          id="recommendedResponse"
          name="recommendedResponse"
          label="What is the recommended response?"
          required
          value={recommendedResponse}
          onChange={v => {
            setRecommendedResponse(v);
            updateFormState({
              intakeRecommendation: {
                ...formState.intakeRecommendation,
                recommendedResponse: v,
              },
            });
          }}
          values={["Screened out", "Differential Response", "Investigation"]}
          disabled={!formState.screeningToolResult}
        />
        <If
          condition={["Differential Response", "Investigation"].includes(
            recommendedResponse
          )}
        >
          <InputRadioGroup
            id="recommendedResponseLevel"
            name="recommendedResponseLevel"
            label="What is the recommended response level?"
            required
            value={recommendedLevel}
            onChange={v => {
              setRecommendedLevel(v);
              updateFormState({
                intakeRecommendation: {
                  ...formState.intakeRecommendation,
                  recommendedResponseLevel: v,
                },
              });
            }}
            values={["R1", "R2"]}
            disabled={!formState.screeningToolResult}
          />
        </If>
      </If>
      <InputTextarea
        label="Summary/Reasoning for Recommendation"
        disabled={!formState.screeningToolResult}
        value={formState.intakeRecommendation.reasoning}
        onChange={value =>
          updateFormState({
            intakeRecommendation: {
              ...formState.intakeRecommendation,
              reasoning: value,
            },
          })
        }
      />
    </Surface>
  );
};

IntakeRecommendation.propTypes = {
  formState: PropTypes.object.isRequired,
  updateFormState: PropTypes.func.isRequired,
};

export default IntakeRecommendation;
