import { useQuery } from "@apollo/client";
import {
  Flex,
  InputHidden,
  InputTextarea,
  LoadingOverlay,
} from "@heart/components";
import {
  useInputContainerClassName,
  useInputDescription,
  useInputError,
} from "@heart/components/inputs/common";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";

import { translationWithRoot } from "@components/T";
import Label from "@components/reusable_ui/forms/Label";

import QuillMergeFields from "@graphql/queries/QuillMergeFields.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import { register } from "@lib/QuillFileUploader";
import QuillLabelledBy from "@lib/QuillLabelledBy";
import QuillTestId from "@lib/QuillTestId";
import QuillToolbarA11y from "@lib/QuillToolbarA11y";
import { isTestEnvironment } from "@lib/environment";
import { useGeneratedIds } from "@lib/generateId";
import prettyJson from "@lib/prettyJson";
import quillMergeFieldHandler from "@lib/quillMergeFieldHandler";

import "./HtmlEditor.module.scss";

const { t } = translationWithRoot("inputs.html_editor");

const ReactQuill = lazy(() =>
  Promise.all([
    import("react-quill-abrarhayat"),
    import("quill-image-uploader"),
    import("quill-magic-url"),
    import("react-quill-abrarhayat/dist/quill.snow.css"),
  ]).then(
    ([ReactQuillModule, { default: ImageUploader }, { default: MagicUrl }]) => {
      const { Quill } = ReactQuillModule;
      register(Quill);
      Quill.register("modules/imageUploader", ImageUploader);
      Quill.register("modules/labelledBy", QuillLabelledBy);
      Quill.register("modules/magicUrl", MagicUrl);
      Quill.register("modules/toolbarA11y", QuillToolbarA11y);
      Quill.register("modules/testId", QuillTestId);

      return ReactQuillModule;
    }
  )
);

const createModules = ({
  printOnly,
  upload,
  labelId,
  quillMergeFields,
  allowBlockQuotes,
}) => {
  const uploadMechanics = printOnly
    ? undefined
    : ["link", "image", "uploadlink"];

  const coreTextFormatOptions = ["bold", "italic", "underline", "strike"];

  const uploadModuleSettings = printOnly
    ? {}
    : {
        fileUploader: {
          acceptedFileTypes: ["application/pdf", "image/jpeg", "image/png"],
          upload,
          testMode: isTestEnvironment(),
        },
        imageUploader: {
          upload,
        },
      };
  const mergeFieldsDropdown = quillMergeFields
    ? [
        {
          mergeFields: quillMergeFields,
        },
      ]
    : undefined;
  return {
    toolbar: {
      // edited for CCWIS-DEMO

      /*
      container: [
        [{ header: [1, 2, false] }],
        allowBlockQuotes
          ? coreTextFormatOptions.concat("blockquote")
          : coreTextFormatOptions,
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        uploadMechanics,
        mergeFieldsDropdown,
        ["clean"],


      */
      container: [
        [],
        ["bold", "italic"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        // uploadMechanics,
        // mergeFieldsDropdown,
        // ["clean"],
      ].filter(Boolean),
      handlers: {
        mergeFields: quillMergeFieldHandler,
      },
    },
    ...uploadModuleSettings,
    testId: {},
    labelledBy: { id: labelId },
    magicUrl: !printOnly,
    toolbarA11y: {
      translations: [
        { key: "bold", translation: t("toolbar_bold") },
        { key: "italic", translation: t("toolbar_italic") },
        { key: "underline", translation: t("toolbar_underline") },
        { key: "strike", translation: t("toolbar_strike") },
        { key: "blockquote", translation: t("toolbar_blockquote") },
        { key: "list", value: "ordered", translation: t("toolbar_list") },
        { key: "list", value: "bullet", translation: t("toolbar_bullet") },
        { key: "indent", value: "-1", translation: t("toolbar_unindent") },
        { key: "indent", value: "+1", translation: t("toolbar_indent") },
        { key: "link", translation: t("toolbar_link") },
        { key: "image", translation: t("toolbar_image") },
        {
          key: "uploadlink",
          translation: t("toolbar_uploadlink"),
        },
        { key: "clean", translation: t("toolbar_clean") },
      ],
    },
  };
};

const formats = ({ printOnly, allowBlockQuotes }) =>
  [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    allowBlockQuotes && "blockquote",
    "list",
    "bullet",
    "indent",
    !printOnly && "link",
    !printOnly && "image",
    !printOnly && "uploadlink",
  ].filter(Boolean);

// Quill uses a contenteditable div, which means we have to go to special
// effort to provide a working label. If we ever use any other contenteditable
// libraries, we might want to extract this label function out into its own
// component. For now, leave it here to avoid confusion.
const ContentEditableLabel = ({ required, id, label, focusEditor }) => (
  <Label required={required} id={id} focusInput={focusEditor}>
    {label}
  </Label>
);

ContentEditableLabel.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  focusEditor: PropTypes.func.isRequired,
};

const HtmlEditor = ({
  id,
  name,
  label,
  uploadedFilesName,
  uploadFile,
  value: initialValue,
  initialAttachmentIds = [],
  debug = false,
  required = false,
  description,
  onChange,
  fullWidth = false,
  error,
  printOnly,
  mergeFieldsClass,
  allowBlockQuotes = true,
}) => {
  const quillRef = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const [value, setValue] = useState(initialValue ?? "");
  const [attachments, setAttachments] = useState(() =>
    initialAttachmentIds.map(attachmentId => ({ id: attachmentId }))
  );

  const [labelId, htmlDebugId, deltaDebugId] = useGeneratedIds(3);

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  const { data: { quillMergeFields } = {}, loading: mergeFieldsLoading } =
    useQuery(QuillMergeFields, {
      variables: { mergeFieldsClass },
      skip: !mergeFieldsClass,
    });

  // we don't want to recompute this every time we render because it would
  // cause ReactQuill to rerender.
  const modules = useMemo(() => {
    const upload = printOnly
      ? undefined
      : async file => {
          setIsUploading(true);

          const {
            data: {
              uploadEmailCampaignFiles: {
                attachments: [attachment],
              },
            },
          } = await uploadFile(file);

          setIsUploading(false);
          setAttachments(a => a.concat([attachment]));

          return attachment.publicAccessUrl;
        };

    return createModules({
      printOnly,
      labelId,
      upload,
      quillMergeFields,
      allowBlockQuotes,
    });

    // Ignore changes to uploadFile. It's common to define uploadFile
    // in the parent which changes the pointer value, but not the
    // actual content which confuses useMemo. labelId and printOnly
    // don't change either, but let's do the most minimal lint exception.
    // quillMergeFields will change if we are running the QuillMergeFields
    // GraphQL query and get results back.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelId, printOnly, quillMergeFields]);

  const delta = quillRef.current?.unprivilegedEditor?.getContents();
  const deltaJson = prettyJson(delta);

  const focusEditor = e => {
    e.preventDefault();
    quillRef.current?.getEditor()?.focus();
  };

  useEffect(() => {
    const applySpellcheck = () => {
      const editorElement = quillRef.current?.getEditor()?.root;
      if (editorElement) {
        editorElement.setAttribute("spellcheck", "true");
      }
    };

    const timeoutId = setTimeout(applySpellcheck, 1000);
    return () => clearTimeout(timeoutId);
  }, [quillRef]);

  return (
    <Suspense fallback="Loading">
      <Flex column className={useInputContainerClassName({ fullWidth })}>
        <ContentEditableLabel
          required={required}
          id={labelId}
          label={label}
          focusEditor={focusEditor}
        />
        <LoadingOverlay active={isUploading || mergeFieldsLoading}>
          <ReactQuill
            formats={formats({ printOnly, allowBlockQuotes })}
            modules={modules}
            onChange={contents => {
              setValue(contents);
              if (onChange) onChange(contents);
            }}
            theme="snow"
            value={value}
            ref={quillRef}
          />
        </LoadingOverlay>
        {useInputDescription({ description })}
        {useInputError({ error })}
      </Flex>
      <If condition={debug}>
        <Flex column style={{ marginTop: "10px" }}>
          <InputTextarea
            label="Raw HTML"
            id={htmlDebugId}
            value={value}
            name={name}
            readOnly
            spellCheck
          />
        </Flex>
        <Flex column style={{ marginTop: "10px" }}>
          <InputTextarea
            label="Delta"
            id={deltaDebugId}
            value={deltaJson}
            readOnly
            spellCheck
          />
        </Flex>
      </If>

      <If condition={!debug}>
        <InputHidden id={id} name={name} value={value} />
      </If>

      <If condition={!isEmpty(uploadedFilesName)}>
        {attachments.map(attachment => (
          <InputHidden
            key={`attachment-${attachment.id}`}
            name={`${uploadedFilesName}[]`}
            value={attachment.id}
          />
        ))}
      </If>
    </Suspense>
  );
};

HtmlEditor.propTypes = {
  /** HTML id attrbute for the hidden input */
  id: PropTypes.string,
  /** HTML name attribute for the hidden input */
  name: PropTypes.string.isRequired,
  /** Label for the input */
  label: PropTypes.string.isRequired,
  /** If the input is required */
  required: PropTypes.bool,
  /** HTML name attribute for uploaded files */
  uploadedFilesName: PropTypes.string,
  /** content, if any */
  value: PropTypes.string,
  /** callback that takes a File to upload */
  uploadFile: PropTypes.func,
  /** ids of attachments already attached, if any */
  initialAttachmentIds: PropTypes.arrayOf(BintiPropTypes.ID.isRequired),
  /** if true, shows the rendered html in a textarea instead of a hidden input */
  debug: PropTypes.bool,
  /** helpful description/hint of the input */
  description: PropTypes.string,
  /** an error to report about the input */
  error: PropTypes.string,
  /** Receives the HTML content of the editor when it changes */
  onChange: PropTypes.func,
  /** Span the full width of the container */
  fullWidth: PropTypes.bool,
  /** When true, removes some of the options that don't play well with printed material */
  printOnly: PropTypes.bool,
  /** When present, will fetch the Merge Fields options relevant to the given Merge Fields class
   * via GraphQL query and populate the options in a dropdown in the toolbar */
  mergeFieldsClass: PropTypes.string,
  /** Determines whether or not we support adding block quotes via the toolbar */
  allowBlockQuotes: PropTypes.bool,
};

export default HtmlEditor;
