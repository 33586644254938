import PropTypes from "prop-types";

const NewPlacementSearch = ({ currentUser }) => (
  <h1>hello {currentUser.fullName}!</h1>
);
NewPlacementSearch.propTypes = {
  currentUser: PropTypes.object,
};

export default NewPlacementSearch;
