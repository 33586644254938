import {
  Surface,
  Flex,
  Text,
  InputTextarea,
  Icons,
  InputDropdown,
  InputText,
  Button,
  InputDateTime,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import {
  formatDateTimeAsShortDate,
  formatDateTimeAsShortTime,
} from "@lib/dates";

import styles from "../MaltreatmentReport.module.scss";

const childEnvironmentOptions = [
  [
    "Married two parent household, with two biological/adoptive parents",
    "married-two-biological-parents",
  ],
  [
    "Married two parent household with one biological/adoptive and one stepparent",
    "married-one-biological-one-stepparent",
  ],
  [
    "Unmarried two parent household with two biological/adoptive parents",
    "unmarried-two-biological-parents",
  ],
  [
    "Unmarried two parent household with one biological/adoptive parent and one cohabitating partner",
    "unmarried-one-biological-one-partner",
  ],
  [
    "Two parent household, marital status unknown",
    "two-parents-marital-unknown",
  ],
  ["Single parent household, mother only", "single-parent-mother-only"],
  ["Single parent household, father only", "single-parent-father-only"],
  [
    "Single parent household mother with other adult (grandparent, uncle, aunt, unrelated adult, etc.)",
    "single-parent-mother-other-adult",
  ],
  [
    "Single parent household, father with other adult (grandparent, uncle, aunt, unrelated adult, etc.)",
    "single-parent-father-other-adult",
  ],
  [
    "Nonparent relative caregiver household (includes relative foster care)",
    "relative-caregiver-household",
  ],
  [
    "Nonrelative caregiver household (includes non-relative foster care)",
    "non-relative-caregiver-household",
  ],
  [
    "Group home or residential treatment setting",
    "group-home-residential-treatment",
  ],
  ["Other setting (hospital, secure facilities, etc.)", "other-setting"],
  ["Unknown", "unknown"],
];

const IncidentInformation = ({ formState, updateFormState }) => {
  const now = useRef(new Date());
  const [editingTimestamp, setEditingTimestamp] = useState(false);
  const [timestamp, setTimestamp] = useState(now.current.toISOString());

  const handleTranscriptionChange = event => {
    updateFormState({
      incidentInfo: {
        ...formState.incidentInfo,
        transcription: event,
      },
    });
  };

  return (
    <React.Fragment>
      <Surface title="Incident Information" className={styles.surfaceContainer}>
        <Flex column gap="300">
          <Flex column gap="300">
            <InputText
              label="Referral Name"
              value={formState.referralName}
              onChange={event => updateFormState({ referralName: event })}
            />
            <Flex column justify="space-between" align="start" gap="100">
              <Text textStyle="supporting-100" textColor="neutral-600">
                Report ID:
              </Text>
              <Text textStyle="body-200">04950-30485</Text>
            </Flex>

            <Flex column justify="space-between" align="start" gap="100">
              <If condition={editingTimestamp}>
                <Flex>
                  <InputDateTime value={timestamp} onChange={setTimestamp} />
                  <Flex column justify="center" align="center">
                    <Button
                      variant="secondary"
                      onClick={() => setEditingTimestamp(false)}
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
              </If>
              <If condition={!editingTimestamp}>
                <Text textStyle="supporting-100" textColor="neutral-600">
                  Report Timestamp:
                </Text>
                <Flex row align="center">
                  <Text textStyle="body-200">
                    {formatDateTimeAsShortDate(timestamp)}
                    {", "}
                    {formatDateTimeAsShortTime(timestamp)}
                  </Text>
                  <Icons.Pencil
                    onClick={() => {
                      setEditingTimestamp(true);
                    }}
                  />
                </Flex>
              </If>
            </Flex>
          </Flex>

          <Flex column>
            <Text textStyle="supporting-100" textColor="neutral-600">
              Incident notes
              <Text textStyle="supporting-100" textColor="danger-500">
                *
              </Text>
            </Text>
            {updateFormState && (
              <InputTextarea
                name="transcription"
                value={formState.incidentInfo.transcription}
                onChange={handleTranscriptionChange}
              />
            )}
            {!updateFormState && (
              <InputTextarea onChange={function noRefCheck() {}} />
            )}
          </Flex>
          <Flex column>
            <Text textStyle="supporting-100" textColor="neutral-600">
              Living Arrangement: What is the environment that the child was
              living in during the time of the alleged maltreatment?
            </Text>
            <InputDropdown
              data-testid="foo"
              id="dropdown-id"
              onChange={function noRefCheck() {}}
              values={childEnvironmentOptions}
            />
          </Flex>
        </Flex>
      </Surface>
    </React.Fragment>
  );
};

IncidentInformation.propTypes = {
  formState: PropTypes.object,
  updateFormState: PropTypes.func,
};

export default IncidentInformation;
