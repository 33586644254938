import {
  Pill,
  Button,
  Flex,
  Text,
  Icons,
  IconMenu,
  Link,
} from "@heart/components";

import { formatFamilyNames } from "../../intake_portal/helper";
import styles from "./Related.module.scss";

// ------------------- Mock Data for Table ------------------- //
export const columnsTabOne = [
  {
    cell: ({ type }) => (
      <Pill
        variant={
          type.includes("Intake")
            ? "alert"
            : type.includes("Screening")
            ? "warning"
            : "neutral"
        }
        text={type}
      />
    ),
    columnName: {
      name: "Type",
    },
    id: "type",
  },
  {
    cell: ({ matchOn, matched }) => (
      <Flex column justify="start" gap="100" align="start">
        {matched && <Link> Persons: </Link>}
        {matched ? <Link>{matchOn}</Link> : <Text>{matchOn}</Text>}
      </Flex>
    ),
    columnName: {
      name: "Match on",
    },
    id: "match_on",
  },
  {
    cell: ({ details }) => (
      <Flex column gap="100">
        {details.map((detail, index) => {
          const [label, value] = detail.split(": ");
          return (
            <Flex key={index} row justify="start" gap="400">
              <Text textStyle="emphasis-100" className={styles.flexBoxWidth100}>
                {label}:
              </Text>
              {label === "Report ID" ? (
                <Link>{value}</Link>
              ) : (
                <Text>{value}</Text>
              )}
            </Flex>
          );
        })}
      </Flex>
    ),
    columnName: {
      name: "Details",
    },
    id: "details",
  },
  {
    cell: () => (
      <Flex row gap="100" align="end" justify="end">
        <Icons.Eye />
        <IconMenu
          icon={Icons.EllipsisVertical}
          linkItems={[
            {
              description: "Edit",
              href: "#",
            },
            {
              description: "Upload",
              href: "#",
            },
            {
              description: "Waive",
              onClick: () => {},
            },
            {
              description: "Delete",
              onClick: () => {},
            },
          ]}
          menuDescription="Show Actions Menu"
          rightAligned
        />
      </Flex>
    ),
    columnName: {
      name: "Controls",
    },
    id: "controls",
  },
];

export const dataTabTwo = [
  {
    type: "Intake",
    matched: true,
    matchOn: "Rachel Lopez",
    details: [
      "Report ID: 0404934",
      "Report Date: 02/30/2021",
      "Role: Caretaker, Abuser",
      "Abuse Type: Neglect",
      "Status / Outcome: Screened Out",
    ],
  },
];
export const dataTabOne = [
  {
    type: "Intake",
    matched: true,
    matchOn: "Rachel Lopez",
    details: [
      "Report ID: 0404934",
      "Report Date: 02/30/2021",
      "Role: Caretaker, Abuser",
      "Abuse Type: Neglect",
      "Status / Outcome: Screened Out",
    ],
  },
  {
    type: "Intake",
    matched: false,
    matchOn: "Duplicate Referral",
    details: [
      "Report ID: 0403439",
      "Report Date: 09/30/2021",
      "Role: Caretaker, Abuser",
      "Abuse Type: Neglect",
      "Status / Outcome: Screened Out",
    ],
  },
];

export const columnsTabThree = [
  {
    cell: ({ referralId }) => <a href="#">{referralId}</a>,
    columnName: {
      name: "Referral ID",
    },
    columnSort: () => {},
    id: "referralId",
  },
  {
    cell: "statusChangeTimestamp",
    columnName: {
      name: "Status Change Timestamp",
    },
    columnSort: () => {},
    id: "status_change_timestamp",
  },
  {
    cell: ({ status }) => (
      <Pill
        variant={
          status.includes("60+")
            ? "alert"
            : status.includes("25")
            ? "warning"
            : "neutral"
        }
        text={status}
      />
    ),
    columnName: {
      name: "Status",
    },
    id: "status",
  },
  {
    cell: ({ workerAssigned }) => <a href="#">{workerAssigned}</a>,
    columnName: {
      name: "Worker Assigned",
    },
    id: "worker_assigned",
  },
  {
    cell: ({ familyInstitutionNames }) =>
      formatFamilyNames(familyInstitutionNames),
    columnName: {
      name: "Family/Institution Name(s)",
    },
    id: "familyInstitutionNames",
  },
  {
    cell: ({ priorityRecommendation }) => (
      <Flex row>
        <Pill
          variant={
            priorityRecommendation.priority === "R1"
              ? "alert"
              : priorityRecommendation.priority === "R2"
              ? "warning"
              : "success"
          }
          text={priorityRecommendation.priority}
        />
        <Pill
          variant={
            priorityRecommendation.type === "Immediate Response"
              ? "alert"
              : priorityRecommendation.type === "Differential Response"
              ? "warning"
              : "success"
          }
          text={priorityRecommendation.type}
        />
      </Flex>
    ),
    columnName: {
      name: "Priority Recommendation",
    },

    id: "priority_recommendation",
  },
  {
    columnName: { name: "Actions", justify: "center" },
    id: "actions",
    cell: () => <Button variant="secondary">Change Status</Button>,
  },
];

export const dataTabThree = [
  {
    referralId: "0399304",
    statusChangeTimestamp: "07/29/20 9:34 AM",
    status: "25 min",
    workerAssigned: "Layla Albright",
    familyInstitutionNames: ["Allen (26)", "Montgomery (4)"],
    priorityRecommendation: {
      priority: "R2",
      type: "Differential Response",
    },
  },
  {
    referralId: "0394012",
    statusChangeTimestamp: "07/29/20 11:10 AM",
    status: "15 min",
    workerAssigned: "Jordan Smith",
    familyInstitutionNames: ["Wright (12)", "Thompson (9)"],
    priorityRecommendation: {
      priority: "R1",
      type: "Immediate Response",
    },
  },
  {
    referralId: "0399321",
    statusChangeTimestamp: "07/30/20 1:45 PM",
    status: "5 min",
    workerAssigned: "Chris Johnson",
    familyInstitutionNames: ["Parker (19)", "Lee (8)"],
    priorityRecommendation: {
      priority: "R3",
      type: "Non-Immediate Response",
    },
  },
];

export const columnsTabFour = [
  {
    cell: ({ referralId }) => <a href="#">{referralId}</a>,
    columnName: {
      name: "Referral ID",
    },
    columnSort: () => {},
    id: "referralId",
  },
  {
    cell: "processedTimestamp",
    columnName: {
      name: "Processed Timestamp",
    },
    columnSort: () => {},
    id: "processed_timestamp",
  },
  {
    cell: ({ workerAssigned }) => <a href="#">{workerAssigned}</a>,
    columnName: {
      name: "Worker Assigned",
    },
    id: "worker_assigned",
  },
  {
    cell: ({ familyInstitutionNames }) =>
      formatFamilyNames(familyInstitutionNames),
    columnName: {
      name: "Family/Institution Name(s)",
    },
    id: "familyInstitutionNames",
  },
  {
    cell: "reportTimestamp",
    columnName: {
      name: "Report Timestamp",
    },
    columnSort: () => {},
    id: "report_timestamp",
  },
  {
    cell: ({ screeningResult }) => (
      <Flex row>
        <Pill
          variant={
            screeningResult.priority === "R1"
              ? "alert"
              : screeningResult.priority === "R2"
              ? "warning"
              : "success"
          }
          text={screeningResult.priority}
        />
        <Pill
          variant={
            screeningResult.type === "Immediate Response"
              ? "alert"
              : screeningResult.type === "Differential Response"
              ? "warning"
              : "success"
          }
          text={screeningResult.type}
        />
      </Flex>
    ),
    columnName: {
      name: "Screening Result",
    },
    id: "screening_result",
  },
  {
    cell: ({ allegations }) => (
      <Flex row>
        <Text>{allegations}</Text>
      </Flex>
    ),
    columnName: {
      name: "Allegations",
      justify: "start",
    },
    id: "allegations",
  },
];

export const dataTabFour = [
  {
    referralId: "0293847",
    processedTimestamp: "07/25/20 8:00 AM",
    workerAssigned: "Jordan Smith",
    familyInstitutionNames: ["Taylor (15)", "Parker (20)", "Johnson (6)"],
    reportTimestamp: "07/26/20 4:30 PM",
    screeningResult: {
      priority: "R1",
      type: "Immediate Response",
    },
    allegations: "Neglect - Lack of Supervision",
  },
  {
    referralId: "0482950",
    processedTimestamp: "07/27/20 10:12 AM",
    workerAssigned: "Emily Carter",
    familyInstitutionNames: ["Harris (40)", "Miller (32)"],
    reportTimestamp: "07/27/20 11:45 AM",
    screeningResult: {
      priority: "R3",
      type: "Non-Immediate Response",
    },
    allegations: "Emotional Abuse - DV",
  },
  {
    referralId: "0583921",
    processedTimestamp: "07/27/20 9:15 AM",
    workerAssigned: "Chris Johnson",
    familyInstitutionNames: ["Garcia (18)", "Lee (10)"],
    reportTimestamp: "07/27/20 8:30 AM",
    screeningResult: {
      priority: "R2",
      type: "Differential Response",
    },
    allegations: "Physical Abuse - Injuries",
  },
  {
    referralId: "0738192",
    processedTimestamp: "07/28/20 7:45 AM",
    workerAssigned: "Sophia White",
    familyInstitutionNames: ["Brown (22)", "Davis (8)", "Martinez (12)"],
    reportTimestamp: "07/28/20 9:34 PM",
    screeningResult: {
      priority: "R1",
      type: "Immediate Response",
    },
    allegations: "Emotional Abuse - DV",
  },
  {
    referralId: "0398320",
    processedTimestamp: "07/29/20 11:20 AM",
    workerAssigned: "Alex Nguyen",
    familyInstitutionNames: ["Clark (14)", "Young (19)"],
    reportTimestamp: "07/29/20 1:00 PM",
    screeningResult: {
      priority: "R3",
      type: "Non-Immediate Response",
    },
    allegations: "Neglect - Medical Needs Not Met",
  },
];
