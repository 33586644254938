import { Modal, Flex, Text, Pill, InputRadioGroup } from "@heart/components";
import patch from "@utils/patch";
import * as PropTypes from "prop-types";
import React, { useState } from "react";

import styles from "./IntakeSubmissionModal.module.scss";

const IntakeSubmissionModal = ({
  currentUser,
  open = false,
  onCancel,
  intakeRecommendation,
  response,
  recommendationReasoning,
  formState,
  referralId,
}) => {
  const isSupervisor = currentUser?.roles?.includes("supervisor") ?? false;

  const [approval, setApproval] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal
      hidden={!open}
      title="Intake Submission"
      onCancel={() => onCancel?.()}
      onSubmit={async () => {
        setSubmitting(true);
        await patch(`/intake/${referralId}`, {
          ...formState,
          intakeRecommendation: {
            acceptIntakeResponse: intakeRecommendation,
            reasoning: recommendationReasoning,
            recommendedResponse: response.label,
            recommendedResponseLevel: response.name,
          },
          supervisorApproved: isSupervisor && approval,
        });
        setSubmitting(false);
      }}
      submitting={submitting}
      submitDisabled={(isSupervisor && approval === null) || submitting}
      cancelText="Cancel"
      submitText="Submit for Review"
      className={styles.modal}
    >
      <Flex column gap="300">
        <Text textStyle="body-100" textColor="neutral-600">
          Referral ID: {referralId}
        </Text>
        <Flex column gap="100">
          <Flex gap="300" align="center">
            <div className={styles.leftContent}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Intake Recommendation
              </Text>
            </div>
            <Text
              textStyle="body-100"
              textColor="neutral-600"
              className={styles.rightContent}
            >
              {intakeRecommendation
                ? "Accept Intake Screening Tool Response"
                : "Discretionary Override"}
            </Text>
          </Flex>
          <Flex gap="300" align="center">
            <div className={styles.leftContent}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Response
              </Text>
            </div>
            <Flex gap="100" className={styles.rightContent}>
              {response.name ? (
                <React.Fragment>
                  <Pill
                    text={response.name}
                    variant={response.name === "R1" ? "warning" : "alert"}
                  />
                  <Pill
                    text={response.label}
                    variant={
                      response.label === "Screened out" ? "info" : "warning"
                    }
                  />
                </React.Fragment>
              ) : null}
            </Flex>
          </Flex>

          <Flex gap="300" align="start">
            <div className={styles.leftContent}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Recommendation Reasoning
              </Text>
            </div>
            <Text
              textStyle="body-100"
              textColor="neutral-600"
              className={styles.rightContent}
            >
              {recommendationReasoning}
            </Text>
          </Flex>
          {isSupervisor && (
            <InputRadioGroup
              id="1"
              label="Supervisor Response"
              labelStyle="emphasis-100"
              labelColor="neutral-600"
              onChange={value => setApproval(value)}
              required
              value={approval}
              values={[
                {
                  label: "I approve the recommendation",
                  value: true,
                },
                {
                  label: "I do not approve the recommendation",
                  value: false,
                },
              ]}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

IntakeSubmissionModal.propTypes = {
  open: PropTypes.bool,
  intakeRecommendation: PropTypes.bool,
  response: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onCancel: PropTypes.func,
  recommendationReasoning: PropTypes.string,
  currentUser: PropTypes.object,
  formState: PropTypes.object,
  referralId: PropTypes.string,
};

export default IntakeSubmissionModal;
