export const links = [
  {
    href: "#",
    text: "Washington DCYF Policy and Practice guide",
  },
  {
    href: "https://www.dcyf.wa.gov/practice/practice-improvement",
    text: "DCYF Practice Improvement",
  },
  {
    href: "https://www.dcyf.wa.gov/tribal-relations/icw",
    text: "DCYF Indian Child Welfare Resources",
  },
  {
    href: "https://risewiththealliance.org/programs/development-for-professionals/development-for-professionals-social-work/development-for-professionals-social-work-mandatory-trainings/",
    text: "The Alliance",
  },
  {
    href: "https://findhelp.org",
    text: "FindHelp.org",
  },
];
