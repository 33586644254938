// hooks/useSectionNavigation.js
import { useEffect, useState, useRef } from "react";

const useSectionNavigation = () => {
  const [activeSection, setActiveSection] = useState("reporter-information");
  const sectionRefs = useRef({});

  const handleSectionClick = sectionId => {
    setActiveSection(sectionId);
  };

  const handleDivClick = sectionId => {
    setActiveSection(sectionId);
    window.history.pushState(null, "", `#${sectionId}`);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
            window.history.pushState(null, "", `#${entry.target.id}`);
          }
        });
      },
      {
        rootMargin: "0px 0px -30% 0px",
        threshold: 0.5,
      }
    );

    Object.values(sectionRefs.current).forEach(section => {
      observer.observe(section);
    });

    return () => {
      Object.values(sectionRefs.current).forEach(section => {
        observer.unobserve(section);
      });
    };
  }, []);

  return {
    activeSection,
    sectionRefs,
    handleDivClick,
    handleSectionClick,
  };
};

export default useSectionNavigation;
