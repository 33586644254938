import { Text, Flex, Button, If } from "@heart/components";
import post from "@utils/post";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import HelpBlock from "./HelpBlock";
import styles from "./Report.module.scss";
import ReportSteps from "./ReportSteps";
import StickyBottomBar from "./StickyBottomBar";
import IncidentInformation from "./incident_information/IncidentInformation";
import ReporterInformation from "./reporter_information/ReporterInformation";
import ReviewSubmit from "./review_submit/ReviewSubmit";

const ReportPage = ({ currentUser, userAuthenticated }) => {
  const [step, setStep] = useState(1);
  const [formState, setFormState] = useState({
    persons: [],
    personIds: [],
    personDatabaseIds: [],
    incidentInfo: {},
    attachments: [],
    // only in here to preserve the data, not used in the form
    reporter: currentUser,
  });

  const updateFormState = newState => {
    setFormState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    if (step === 2) window.location = "#persons-involved";
  }, [step]);

  const validate = () => {
    if (step === 1) {
      return !userAuthenticated;
    }
    return false;
  };

  const handleSubmit = () => {
    post("/reporter-portal/wa", formState);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.background}></div>
      <div className={styles.circles}></div>
      <div className={styles.container}>
        <Flex column gap="200">
          <Flex column gap="50">
            <Text textColor="neutral-0" as="h1" className={styles.title}>
              Submit a Report of Child Maltreatment
            </Text>
          </Flex>
          <Flex gap="200">
            <aside className={styles.stickyLinks}>
              <Flex column gap="300">
                <ReportSteps step={step} />
                <HelpBlock />
              </Flex>
            </aside>
            <main>
              <Flex gap="200" column>
                <ReporterInformation
                  userAuthenticated={userAuthenticated}
                  currentUser={currentUser}
                  step={step}
                />
                <IncidentInformation
                  step={step}
                  userAuthenticated={userAuthenticated}
                  formState={formState}
                  updateFormState={updateFormState}
                />
                <ReviewSubmit
                  step={step}
                  userAuthenticated={userAuthenticated}
                  formState={formState}
                  updateFormState={updateFormState}
                />
              </Flex>
            </main>
          </Flex>
        </Flex>
      </div>
      <StickyBottomBar>
        <Flex justify="end">
          <If condition={step < 3}>
            <Button
              onClick={() => {
                if (step === 3) return;
                setStep(s => s + 1);
              }}
              disabled={validate()}
            >
              <If condition={step === 1}>Next: Incident Information</If>
              <If condition={step === 2}>Next: Review & Submit</If>
            </Button>
          </If>
          <If condition={step === 3}>
            <Button onClick={handleSubmit}>Submit Report</Button>
          </If>
        </Flex>
      </StickyBottomBar>
    </div>
  );
};

ReportPage.propTypes = {
  userAuthenticated: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default ReportPage;
