import { Flex, Link } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./CreateView.module.scss";

const SectionLinks = ({
  links,
  activeSection,
  handleSectionClick,
  linkSectionTitle,
}) => {
  const handleClick = (e, href) => {
    e.preventDefault();
    const sectionId = href.substring(1);
    const section = document.querySelector(href);
    handleSectionClick(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    window.history.pushState(null, "", href);
  };

  return (
    <Flex
      column
      align="start"
      justify="start"
      gap="300"
      className={styles.stickyLinks}
    >
      <Link
        key={"nav-new-maltreatment-report"}
        href="#"
        color="color-blue-700"
        textStyle="headline-100"
        navigation
        activeSelected
      >
        {linkSectionTitle}
      </Link>
      <Flex column align="start" gap="300" className={styles.linkNav}>
        {links.map(link => (
          <Link
            key={link.href}
            href={link.href}
            navigation
            activeSelected={activeSection === link.href.substring(1)}
            onClick={e => handleClick(e, link.href)}
          >
            {link.label}
          </Link>
        ))}
      </Flex>
    </Flex>
  );
};

SectionLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  activeSection: PropTypes.string,
  handleSectionClick: PropTypes.func,
  linkSectionTitle: PropTypes.string.isRequired,
};

export default SectionLinks;
