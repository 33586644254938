import { Flex, Button, If, SurfaceForm, Actions } from "@heart/components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const NewCard = ({
  formState,
  updateWindowsLocation,
  buttonClass,
  updateFormState,
}) => {
  const [isAddingField, setIsAddingField] = useState(false);

  useEffect(() => {
    if (!isAddingField) {
      resetFormStates();
    }
  }, [isAddingField]);

  const onSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (updateWindowsLocation) window.location = updateWindowsLocation;
  };

  const resetFormStates = () => {};

  return (
    <React.Fragment>
      <If condition={!isAddingField}>
        {" "}
        {/* Add condition here && formState.field.length > 0 */}
        <Flex gap="300" column>
          {/* Map through designated data here -- delete this comment */}
          {/* {formState.persons.map(person => (
            <PersonCard
              hasNotice={hasNotice}
              key={person.name + person.lastName}
              person={person}
            />
          ))} */}
        </Flex>
      </If>
      <If condition={isAddingField}>
        <SurfaceForm
          title="" // title here
          hideTitle
          onSubmit={onSubmit}
          actions={
            <Actions cancelAction={resetFormStates} primaryText="Save" />
          }
        ></SurfaceForm>
      </If>
      <Button
        variant="secondary"
        disabled={isAddingField}
        onClick={() => setIsAddingField(true)}
        buttonClass={buttonClass}
      >
        Add Field
      </Button>
    </React.Fragment>
  );
};

NewCard.propTypes = {
  formState: PropTypes.object.isRequired,
  updateWindowsLocation: PropTypes.string,
  updateFormState: PropTypes.func.isRequired,
  buttonClass: PropTypes.object,
};

export default NewCard;
