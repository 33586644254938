import { If, InputCheckboxGroup, InputRadioGroup } from "@heart/components";
import { usePrevious } from "@react-hookz/web";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
  DecisionTreePropTypes,
  isCheckboxNode,
  isResultNode,
  isYesNoNode,
} from "./helper";
import questions from "./questions.json";

const Questions = ({ question, handleResult }) => {
  const [questionValue, setQuestionValue] = useState();
  const prevVal = usePrevious(questionValue);

  useEffect(() => {
    if (!isCheckboxNode(question) && prevVal && questionValue !== prevVal) {
      if (isCheckboxNode(questions[question[questionValue]])) {
        handleResult({ label: "Screened out", pillVariant: "info" });
      } else {
        handleResult(null);
      }
    }

    if (isCheckboxNode(question)) {
      handleResult({ label: "Screened out", pillVariant: "info" });
    }

    if (questionValue && isResultNode(questions[question[questionValue]])) {
      handleResult(questions[question[questionValue]]);
    }
  }, [questionValue, question, prevVal]);

  return (
    <React.Fragment>
      <If condition={isYesNoNode(question)}>
        <InputRadioGroup
          id={question.name}
          label={question.label}
          value={questionValue}
          orientation="row"
          required
          onChange={setQuestionValue}
          name={question.name}
          values={["Yes", "No"]}
        />
      </If>
      <If condition={isCheckboxNode(question)}>
        <InputCheckboxGroup
          id={question.name}
          label={question.label}
          required
          name={question.name}
          values={question.options.map(opt => ({ label: opt, value: opt }))}
        />
      </If>
      <If condition={questionValue}>
        <Questions
          key={questions[question[questionValue]].name}
          question={questions[question[questionValue]]}
          handleResult={handleResult}
        />
      </If>
    </React.Fragment>
  );
};

Questions.propTypes = {
  question: DecisionTreePropTypes,
  handleResult: PropTypes.func.isRequired,
};

export default Questions;
