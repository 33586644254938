import { clickable } from "@heart/core/clickable.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

/** Note: This component is not exported by Heart as it should be
 * used very sparingly!
 *
 * The `href` and `onClick` props are mutually exclusive - `href` will
 * render an `<a>` tag and `onClick` will render the children in a
 * `<button>` that has been given the visual style of a link
 *  _([why?](https://www.digitala11y.com/links-vs-buttons-a-perennial-problem/)_)
 */
const Clickable = forwardRef(
  (
    {
      href,
      onClick,
      anchorClassname,
      buttonClassname,
      children,
      type = "button",
      disabled,
      className,
      ...props
    },
    ref
  ) => {
    if ((href && !disabled) || (href && !onClick)) {
      return (
        <a
          ref={ref}
          href={href}
          className={classNames(anchorClassname, clickable, className)}
          {...props}
        >
          {children}
        </a>
      );
    }

    return (
      <button
        type={type}
        ref={ref}
        onClick={onClick}
        className={classNames(buttonClassname, clickable, className)}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    );
  }
);
Clickable.displayName = "Clickable";

Clickable.propTypes = {
  /** className to put on the `<a/>` tag, should be used with an `href` */
  anchorClassname: PropTypes.string,
  /** className to put on the `<button/>` tag, should be used with an `onClick` */
  buttonClassname: PropTypes.string,
  /** Link contents */
  children: PropTypes.node,
  /** Whether button is clickable */
  disabled: PropTypes.bool,
  /** Link location */
  href: PropTypes.string,
  /** onClick handler, if this link just executes a callback. */
  onClick: PropTypes.func,
  /** Used to indicate when a button submits or resets a form, or is just a clickable button.
   * If you want to use this button to POST the current form (eg to a Rails/AA controller),
   * use `type="submit"`.
   */
  type: PropTypes.oneOf(["button", "submit", "reset"]),
};

export default Clickable;
