import PropTypes from "prop-types";

// Enum for node types
export const NodeType = {
  YESNO: "yesno",
  CHECKBOXES: "checkboxes",
  RESULT: "result",
};

// Prop type for a generic decision tree node
export const DecisionNodePropTypes = {
  type: PropTypes.oneOf(Object.values(NodeType)).isRequired,
  label: PropTypes.string.isRequired,
};

// Specific prop types for different node types
export const YesNoNodePropTypes = {
  ...DecisionNodePropTypes,
  type: PropTypes.oneOf([NodeType.YESNO]).isRequired,
  Yes: PropTypes.string.isRequired,
  No: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const CheckboxNodePropTypes = {
  ...DecisionNodePropTypes,
  type: PropTypes.oneOf([NodeType.CHECKBOXES]).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const ResultNodePropTypes = {
  ...DecisionNodePropTypes,
  type: PropTypes.oneOf([NodeType.RESULT]).isRequired,
  // Optional additional metadata for results
  metadata: PropTypes.object,
};

// Full decision tree prop types
export const DecisionTreePropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.shape(YesNoNodePropTypes),
    PropTypes.shape(CheckboxNodePropTypes),
    PropTypes.shape(ResultNodePropTypes),
  ])
);

// Utility type guard (for TypeScript, though this is in PropTypes)
export const isYesNoNode = node => node.type === NodeType.YESNO;

export const isCheckboxNode = node => node.type === NodeType.CHECKBOXES;

export const isResultNode = node => node.type === NodeType.RESULT;
