import { Text, Flex, Link, Icons } from "@heart/components";
import { useMediaQuery } from "@react-hookz/web";
import { last } from "lodash";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import styles from "./Breadcrumbs.module.scss";

const Breadcrumbs = ({ pages, "data-testid": testId }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const displayedPages = isMobile ? pages.slice(-2) : pages;

  return (
    <Flex
      row
      as="nav"
      aria-label="breadcrumbs"
      align="center"
      className={styles.breadcrumbs}
      data-testid={testId}
      responsive
    >
      {isMobile && pages.length > 2 && (
        <Fragment key="ellipsis">
          <Text as="div" textColor="neutral-600">
            ...
          </Text>
          <div className={styles.divider}>
            <Icons.ChevronRight />
          </div>
        </Fragment>
      )}
      {displayedPages.map(page => {
        const { href, label } = page;
        if (page === last(displayedPages)) {
          return (
            <Text
              as="div"
              aria-current="page"
              textColor="neutral-600"
              key={href}
            >
              {label}
            </Text>
          );
        }
        return (
          <Fragment key={href}>
            <Link href={href}>{label}</Link>
            <div className={styles.divider}>
              <Icons.ChevronRight />
            </div>
          </Fragment>
        );
      })}
    </Flex>
  );
};

Breadcrumbs.propTypes = {
  /**
   * A list of pages to show in the Breadcrumbs. Order matters here, the
   * first page in the list should be the first page in the hierarchy, and
   * the last page in the list should be the current page the user is on.
   */
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ).isRequired,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
  responsive: PropTypes.bool,
};

export default Breadcrumbs;
