import PropTypes from "prop-types";

const EditPlacement = ({ currentUser }) => (
  <h1>hello {currentUser.fullName}!</h1>
);
EditPlacement.propTypes = {
  currentUser: PropTypes.object,
};

export default EditPlacement;
