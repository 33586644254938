import classnames from "classnames";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import { createElement, forwardRef, ReactNode } from "react";

import styles from "./Table.module.scss";

const ElementWrapper = ({
  passthroughRef,
  type,
  children,
  className,
  hidden,
  withHiddenThead,
  alignRight,
  ...props
}: {
  passthroughRef?: any;
  type: string;
  children?: ReactNode;
  className?: string;
  hidden?: boolean;
  withHiddenThead?: boolean;
}) =>
  createElement(
    type,
    {
      className: classnames(styles[type], className, {
        [styles.hidden]: hidden,
        [styles.withHiddenThead]: type === "tbody" && withHiddenThead,
        [styles.alignRight]: type === "td" && alignRight,
      }),
      ref: passthroughRef,
      ...props,
    },
    children
  );

ElementWrapper.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  hidden: PropTypes.bool,
  /** Only for use with Tbody tags */
  withHiddenThead: PropTypes.bool,
  passthroughRef: PropTypes.object,
  alignRight: PropTypes.bool, // Add prop type for alignRight
};

const makeWrappedElement = type => {
  const element = forwardRef((passthrough, ref) => (
    <ElementWrapper passthroughRef={ref} {...{ type, ...passthrough }} />
  ));

  element.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    "data-testid": PropTypes.string,
    alignRight: PropTypes.bool, // Add prop type for alignRight
  };

  element.displayName = capitalize(type);

  return element;
};
/**
 * All of the Table components are exported in Heart under `HeartTable`  so that you can
 * destructure `Table`/`Thead`/... out of it.  See `./Table.stories.js` for an example!
 *
 * **Note:** For accessibility reasons, we must have Caption and a Thead components in each
 * table. If you would like to hide them, use the `hidden` prop and put `withHiddenThead`
 * on the `Tbody` component if needed.
 *
 * ### Cypress
 *
 * Find a table using `cy.tableWithCaption("Caption")` and chain any of the following:
 *   * `cy.countTableRows().should("have.length", 2)`
 *   * `cy.clickRowLink("Content in row", "Link text")`
 *   * `cy.cell({ row: "Content in row"}).should("contain", "Cell Text")`
 *   * `cy.columnValues("Column Header").should("include.members", ["Cell Text 1", ...])`
 *   * `cy.findRowWithContent("Content in row").find(...)  // Do arbitrary Cypress stuff with this row`
 *   * `cy.checkColumnSortOrder("Column Header", "first value", "last value")`
 *
 * (for example, `cy.tableWithCaption("Applicants").countTableRows()`)
 */
export const Docs = () => <div />;
// ^ Storybook doesn't recognize this module's exports as React components so
// we export this specifically for Storybook.

export const Table = makeWrappedElement("table");
export const Thead = makeWrappedElement("thead");
export const Tbody = makeWrappedElement("tbody");
export const Tr = makeWrappedElement("tr");
export const Td = makeWrappedElement("td");
export const Th = makeWrappedElement("th");
export const Caption = makeWrappedElement("caption");
