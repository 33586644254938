import {
  Button,
  Flex,
  Icons,
  InputText,
  Link,
  Surface,
  Text,
} from "@heart/components";
import { startCase } from "lodash";
import PropTypes from "prop-types";

import ProfileCard from "@components/reporter_portal/reporter_information/ProfileCard";
import Attachments from "@components/shared/attachments/Attachments";
import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";
import NewPersonForm from "@components/shared/person/NewPersonForm";
import Relations from "@components/shared/relations/Relations";

import { setBase64SearchParams } from "@lib/base64SearchParams";
import {
  formatDateTimeAsShortDate,
  formatDateTimeAsShortTime,
} from "@lib/dates";

import CaseActivity from "./CaseActivity";
import styles from "./IntakeReview.module.scss";

const links = [
  {
    href: "#worker-assignments",
    label: "Worker Assignments",
  },
  {
    href: "#reporter-information",
    label: "Reporter Information",
  },
  {
    href: "#incident-information",
    label: "Incident Information",
  },
  {
    href: "#attachments",
    label: "Attachments",
  },
  {
    href: "#persons",
    label: "Persons",
  },
  {
    href: "#relations",
    label: "Relations",
  },
  {
    href: "#worker-safety-concerns",
    label: "Worker safety concerns",
  },
  {
    href: "#case-activity",
    label: "Case Activity",
  },
];

const IntakeReport = ({
  referralId,
  caseActivity,
  formState,
  handlePersonAdd,
  updateFormState,
}) => {
  const {
    incidentTimestamp,
    incidentInfo: {
      childStatus,
      abuseDescription,
      familyHistory,
      familyStructure,
      witnesses,
      extraInfo,
      concernType = [],
      safetyComments,
      reportType = [],
    } = {},
  } = formState || {};

  const timestamp = incidentTimestamp || "2023-07-24T22:30:30.000Z";
  const narrative =
    [
      childStatus,
      abuseDescription,
      familyHistory,
      familyStructure,
      witnesses,
      extraInfo,
    ]
      .filter(Boolean)
      .join("\n\n") ||
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do \
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut \
              enim ad minim veniam, quis nostrud exercitation ullamco laboris \
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in \
              reprehenderit in voluptate velit esse cillum dolore eu fugiat \
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, \
              sunt in culpa qui officia deserunt mollit anim id est laborum.Sed \
              ut perspiciatis unde omnis iste natus error sit voluptatem \
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa \
              quae ab illo inventore veritatis et quasi architecto beatae vitae \
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit \
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores \
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam \
              est, qui dolorem ipsum quia dolor sit ametSed ut perspiciatis unde \
              omnis iste natus error sit voluptatem accusantium doloremque \
              laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore \
              veritatis et quasi architecto beatae vitae dicta sunt explicabo. \
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit \
              aut fugit, sed quia consequuntur magni dolores eos qui ratione \
              voluptatem sequi nesciunt.";

  return (
    <LayoutSidebarSections links={links}>
      <Surface
        title="Worker assignments"
        hideTitle
        className={styles.surfaceContainer}
      >
        <Flex justify="space-between" align="center">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Worker assignments
          </Text>
          <Button variant="secondary">Edit</Button>
        </Flex>
        <Flex column gap="100">
          <Flex className={styles.tableRow}>
            <div className={styles.tableRowFirst}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Worker assigned
              </Text>
            </div>
            <div className={styles.tableRowSecond}>
              <Link>{formState.workerAssigned?.fullName}</Link>
            </div>
          </Flex>
          <Flex>
            <Text textStyle="emphasis-100" textColor="neutral-600">
              Reviewer Assigned
            </Text>
          </Flex>
        </Flex>
      </Surface>
      <Surface
        title="Reporter Information"
        hideTitle
        className={styles.surfaceContainer}
      >
        <Flex justify="space-between" align="center">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Reporter Information
          </Text>
          <Button variant="secondary">Edit</Button>
        </Flex>
        <ProfileCard person={formState.reporter} hideMyProfileButton />
      </Surface>
      <Surface
        title="Incident information"
        hideTitle
        className={styles.surfaceContainer}
      >
        <Flex justify="space-between" align="center">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            Incident information
          </Text>
        </Flex>
        <InputText
          name="referralName"
          label="Referral Name"
          value={formState.referralName}
          onChange={text =>
            updateFormState({
              ...formState,
              referralName: text,
              incidentInfo: { ...formState.incidentInfo, referralName: text },
            })
          }
        />
        <Surface title="Incident Description" hideTitle>
          <Flex column gap="100">
            <Flex className={styles.tableRow}>
              <div className={styles.tableRowFirst}>
                <Text textStyle="emphasis-100" textColor="neutral-600">
                  Referral ID
                </Text>
              </div>
              <div className={styles.tableRowSecond}>
                <Text textStyle="body-100" textColor="neutral-600">
                  {referralId}
                </Text>
              </div>
            </Flex>
            <Flex className={styles.tableRow}>
              <div className={styles.tableRowFirst}>
                <Text textStyle="emphasis-100" textColor="neutral-600">
                  Timestamp
                </Text>
              </div>
              <div className={styles.tableRowSecond}>
                <Text textStyle="body-100" textColor="neutral-600">
                  {`${formatDateTimeAsShortDate(
                    timestamp
                  )}, ${formatDateTimeAsShortTime(timestamp)}`}
                </Text>
              </div>
            </Flex>
            <Flex className={styles.tableRow}>
              <div className={styles.tableRowFirst}>
                <Text textStyle="emphasis-100" textColor="neutral-600">
                  Incident Type
                </Text>
              </div>
              <div className={styles.tableRowSecond}>
                <Text textStyle="body-100" textColor="neutral-600">
                  {reportType.map(type => startCase(type)).join(", ") || "N/A"}
                </Text>
              </div>
            </Flex>
            <Flex className={styles.tableRow}>
              <div className={styles.tableRowFirst}>
                <Text textStyle="emphasis-100" textColor="neutral-600">
                  Narrative
                </Text>
              </div>
              <div className={styles.tableRowSecond}>
                <Text
                  textStyle="body-100"
                  textColor="neutral-600"
                  style={{ "white-space": "pre-wrap" }}
                >
                  {narrative}
                </Text>
              </div>
            </Flex>
            <Flex className={styles.tableRow}>
              <div className={styles.tableRowFirst}>
                <Text textStyle="emphasis-100" textColor="neutral-600">
                  Critical Incident?
                </Text>
              </div>
              <div className={styles.tableRowSecond}>
                <Text textStyle="body-100" textColor="neutral-600">
                  No
                </Text>
              </div>
            </Flex>
            <Flex className={styles.tableRow}>
              <div className={styles.tableRowFirst}>
                <Text textStyle="emphasis-100" textColor="neutral-600">
                  Child Environment
                </Text>
              </div>
              <div className={styles.tableRowSecond}>
                <Icons.ExclamationTriangle />
              </div>
            </Flex>
          </Flex>
        </Surface>
      </Surface>
      <Surface title="Persons" hideTitle className={styles.surfaceContainer}>
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Persons
        </Text>
        <Flex column align="start">
          <NewPersonForm
            onPersonAdd={handlePersonAdd}
            formState={formState}
            buttonClass={styles.newPersonButton}
            hasNotice={true}
          />
        </Flex>
      </Surface>
      <Surface title="Relations" hideTitle className={styles.surfaceContainer}>
        <Relations
          formState={formState}
          updateWindowsLocation="#relations"
          updateFormState={updateFormState}
          buttonClass={styles.newPersonButton}
        />
      </Surface>
      <Attachments formState={formState} updateFormState={updateFormState} />
      <Surface
        title="Work Safety Concerns"
        hideTitle
        className={styles.surfaceContainer}
      >
        <Flex justify="space-between" align="center">
          <Flex column gap="200">
            <Text textStyle="emphasis-200" textColor="neutral-600">
              Work Safety Concerns
            </Text>
            <Text textStyle="supporting-100" textColor="neutral-500">
              Please indicate safety issues in the event that workers will be on
              site
            </Text>
          </Flex>
          <Button variant="secondary">Edit</Button>
        </Flex>
        <Flex column gap="100">
          <Flex className={styles.tableRow}>
            <div className={styles.tableRowFirst}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Category
              </Text>
            </div>
            <div className={styles.tableRowSecond}>
              <Text textStyle="body-100" textColor="neutral-600">
                {concernType.map(({ label }) => label).join(", ") || "N/A"}
              </Text>
            </div>
          </Flex>
          <Flex className={styles.tableRow}>
            <div className={styles.tableRowFirst}>
              <Text textStyle="emphasis-100" textColor="neutral-600">
                Comments
              </Text>
            </div>
            <div className={styles.tableRowSecond}>
              <Text textStyle="body-100" textColor="neutral-600">
                {safetyComments || "N/A"}
              </Text>
            </div>
          </Flex>
        </Flex>
      </Surface>
      <CaseActivity activity={caseActivity} referralId={referralId} />,
      <Flex justify="end">
        <Button
          variant="primary"
          onClick={() => {
            setBase64SearchParams([
              { attribute: "tab", value: "Review & Recommendation" },
            ]);
            window.scrollTo(0, 0);
          }}
        >
          Next: Review & Recommendations
        </Button>
      </Flex>
    </LayoutSidebarSections>
  );
};

IntakeReport.propTypes = {
  referralId: PropTypes.string,
  caseActivity: PropTypes.array,
  formState: PropTypes.object.isRequired,
  handlePersonAdd: PropTypes.func.isRequired,
  updateFormState: PropTypes.func.isRequired,
};

export default IntakeReport;
