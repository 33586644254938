import {
  Flex,
  ArrayDataTable,
  Button,
  InputText,
  Icons,
  Text,
  Link,
} from "@heart/components";

import TextEditIcon from "../../components/intake_portal/assets/icon-text-edit.svg";
import styles from "./Investigations.module.scss";

const InvestigationsArray = ({ columns, data }) => {
  return (
    <>
      {data?.length === 0 ? (
        <Flex column justify="center" align="center" gap="400">
          <img src={TextEditIcon} alt="Text Edit Icon" width="48" height="48" />
          <Flex column gap="200" justify="center" align="center">
            <Text textStyle="emphasis-200" textColor="neutral-600">
              You're all caught up!
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              There are currently no unaddressed reports from the public portal.
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex column>
          <Flex column justify="center" align="center" gap="200">
            <ArrayDataTable columns={columns} data={data} />
            <Text textStyle="body-100">
              <Link href="#">Download CSV</Link>
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default InvestigationsArray;
