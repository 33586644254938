import { LiveRegion } from "@heart/components";
import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";
import classNames from "classnames";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, forwardRef } from "react";

import Clickable from "../clickable/Clickable";
import styles from "./Button.module.scss";

/**
 * A button!
 *
 * The `href` and `onClick` props are mutually exclusive - `href` will
 * render an `<a>` tag and `onClick` will render the children in a
 * `<button>` that has been given the visual style of a link
 *  _([why?](https://www.digitala11y.com/links-vs-buttons-a-perennial-problem/)_)
 *
 * Any of the documented `props` are critical and you should check the documentation
 * about them - but this component also takes any other props and sends them through
 * to the underlying `<button>` element.
 *
 * **A note about forms:** When submitting GraphQL-backed forms, please use the
 * `<Button type="submit">` paired with a `<form onSubmit={persist}>` rather than
 * using the `<Button onClick={persist} />` pattern - we get a lot of free HTML5
 * validation when we do so!
 *
 * **Known issue:** When using Buttons in a flex container, they grow on the
 * cross-axis.  See below for an example and a workaround.
 */
const Button = forwardRef(
  (
    {
      children,
      description,
      disabled,
      icon: IconComponent,
      iconOnRight,
      onClick,
      href,
      submitting,
      submittingText = I18n.t("views.common.submitting"),
      type = "button",
      asSpan,
      variant = "primary",
      badge,
      badgeDescription,
      "data-testid": testId,
      round = false,
      className,
      ...props
    },
    ref
  ) => {
    const desc = submitting
      ? submittingText || I18n.t("views.common.submitting")
      : description;
    const buttonContent = (
      <Fragment>
        <If condition={submitting}>
          <div className={styles.logoSpinnerContainer}>
            <LogoSpinner />
          </div>
        </If>
        <If condition={!submitting && IconComponent}>
          <IconComponent />
        </If>
        {submitting ? submittingText : children}
      </Fragment>
    );
    const commonProps = {
      ref: ref,
      "aria-label": desc,
      title: desc,
      "data-heart-component": "button",
      "data-testid": testId,
    };

    if (asSpan)
      return (
        <span
          {...commonProps}
          className={classNames(styles.button, styles[variant])}
          role="button"
        >
          {buttonContent}
        </span>
      );

    return (
      <Clickable
        className={className}
        {...commonProps}
        disabled={disabled || submitting}
        onClick={onClick}
        href={href}
        anchorClassname={classNames(styles.a, styles[variant], {
          [styles.iconOnRight]: iconOnRight,
        })}
        buttonClassname={classNames(styles.button, styles[variant], {
          [styles.iconOnRight]: iconOnRight,
          [styles.round]: round,
        })}
        type={type}
        {...props}
      >
        {buttonContent}
        <If condition={badgeDescription}>
          {/* Assistive tech requires the LiveRegion to always be present when the component is mounted.
          Theoretically if we're dynamically updating the badge, the badgeDescription should be consistenly
          present, so we're nesting a second conditional in here to avoid adding the LiveRegion to buttons it
          isn't needed for, while also conditionally rendering the badge elements */}
          <LiveRegion>
            <If condition={badge}>
              <div className={styles.hiddenButAccessible}>
                {badgeDescription}
              </div>
              <div className={styles.badge}>{badge}</div>
            </If>
          </LiveRegion>
        </If>
      </Clickable>
    );
  }
);
Button.displayName = "Button";

Button.propTypes = {
  /** Button contents */
  children: PropTypes.node,
  /** *Required when using an icon-only button.* Used to indicate to
   * screen readers what the purpose of the button is, and puts useful
   * hover text on the button.  A combination of HTML
   * `title` and `aria-label` attributes - for further reading:
   *   * https://silktide.com/blog/i-thought-title-text-improved-accessibility-i-was-wrong/
   *   * https://dev.opera.com/articles/ux-accessibility-aria-label/#accessible-name-calculation
   */
  description: props => {
    if (props.icon && !props.children && isEmpty(props.description)) {
      return new Error("description prop is required for icon only buttons");
    }
    return undefined;
  },
  /** Whether button is clickable */
  disabled: PropTypes.bool,
  /** Icon: one of the components from `import { Icons } from "@heart/components"` */
  icon: PropTypes.object,
  /** When true, puts the icon to the right of the button contents */
  iconOnRight: PropTypes.bool,
  /** Action to execute when button is clicked */
  onClick: props => {
    if (
      props.type === "button" &&
      typeof props.onClick !== "function" &&
      !props.href
    ) {
      return new Error(
        "onClick or href is required when `props.type` is `button`"
      );
    }
    return undefined;
  },
  /** Link location */
  href: PropTypes.string,
  /** Whether the button is currently being submitted and should show a submitting state.
   * Also disables the button when true.
   */
  submitting: PropTypes.bool,
  /** Custom submitting text, defaults to "Submitting..." */
  submittingText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Used to indicate when a button submits or resets a form, or is just a clickable button.
   * If you want to use this button to POST the current form (eg to a Rails/AA controller),
   * use `type="submit"`.
   */
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  /** Which variant? */
  variant: PropTypes.oneOf(["primary", "secondary", "danger", "tertiary"]),
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
  /** This supports an exotic use case for UploadButton in which some browsers
   * won't tolerate an actual <button>, so this renders the button as a `<span>`.
   * See PR #12035 for details, but you probably won't need this.
   */
  asSpan: PropTypes.bool,
  /** Indicates a number to show in a badge on the button */
  badge: PropTypes.number,
  /** text used to describe what the badge indicates, used for accessibility */
  badgeDescription: props => {
    if (props.badge && !props.badgeDescription)
      return new Error("badgeDescription is required when a badge is provided");
    return undefined;
  },
  /**
   * Make the button round. To be used only for our microphone button (VoiceInput).
   *
   * Implemented here to take advantage of the other Button a11y features,
   * but not meant to be used in other contexts at this time. Only supported
   * for <button> Buttons so as not to tempt people to use it in other ways.
   */
  round: PropTypes.bool,
  lassName: PropTypes.string,
};
export default Button;
