import { Flex, Link, Text } from "@heart/components";
import SurfaceBase from "@heart/components/surface/SurfaceBase";
import * as PropTypes from "prop-types";
import React from "react";

import styles from "../HomePage.module.scss";

const MetricTile = ({ numericString, color, link }) => {
  const isGreen = color === "green";
  return (
    <SurfaceBase
      title="numericString"
      hideTitle
      className={isGreen ? styles.metricTile : styles.metricTileRed}
    >
      <Flex column align="center" justify="center">
        <Text className={isGreen ? styles.greenText : styles.redText}>
          {numericString}
        </Text>
        <Link>{link}</Link>
      </Flex>
    </SurfaceBase>
  );
};

export default MetricTile;

MetricTile.propTypes = {
  numericString: PropTypes.string.isRequired,
  color: PropTypes.string,
  link: PropTypes.string.isRequired,
};
