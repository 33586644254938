import PropTypes from "prop-types";
import React from "react";

import styles from "./styles/SharedStyles.module.scss";

const ButtonIcon = ({
  svg: SvgIcon,
  onClick = () => {},
  width = 24,
  height = 24,
}) => (
  <button className={styles.clickableIconButton} onClick={onClick}>
    <img src={SvgIcon} width={width} height={height} alt="button-with-icon" />
  </button>
);

ButtonIcon.propTypes = {
  svg: PropTypes.elementType.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ButtonIcon;
