import {
  Surface,
  Text,
  Flex,
  Button,
  DetailsTable,
  SurfaceForm,
  Actions,
  InputFilterable,
  InputText,
} from "@heart/components";
import post from "@utils/post";
import React, { useState } from "react";

import { usePersonInformation } from "../PersonContext";

const STRENGTHS_OPTIONS = {
  resilient: "Resilient",
  curious_and_desires_to_learn: "Curious and Desires to Learn",
  empathic: "Empathic",
  creative_and_imaginative: "Creative and Imaginative",
  adaptable: "Adaptable",
  independent: "Independent",
  strong_social_skills: "Strong Social Skills",
  positive_relationships: "Positive Relationships",
  problem_solving_skills: "Problem-Solving Skills",
  self_aware: "Self-Aware",
  hopeful: "Hopeful",
};

const CHALLENGES_OPTIONS = {
  difficulty_with_emotional_regulation: "Difficulty with Emotional Regulation",
  academic_challenges: "Academic Challenges",
  trauma_responses: "Trauma Responses",
  attachment_issues: "Attachment Issues",
  behavioral_challenges: "Behavioral Challenges",
  low_self_esteem: "Low Self-Esteem",
  difficulty_with_boundaries: "Difficulty with Boundaries",
  limited_social_skills: "Limited Social Skills",
  impulsive: "Impulsive",
  poor_coping_mechanisms: "Poor Coping Mechanisms",
};

const StrengthsAndChallenges = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { formState, updateFormState } = usePersonInformation();

  const handleSave = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const data = {
      strengths: formData.getAll("strengths"),
      strengthsOther: formData.get("strengths-other"),
      challenges: formData.getAll("challenges"),
      challengesOther: formData.get("challenges-other"),
    };

    post(
      `/persons/${formState.person.id}`,
      { ...formState.person, ...data },
      response => {
        if (response.success) {
          updateFormState({ person: { ...formState.person, ...data } });
          setIsEditing(false);
        }
      }
    );
  };

  if (isEditing) {
    return (
      <SurfaceForm
        actions={
          <Actions
            primaryText="Save"
            cancelAction={() => setIsEditing(false)}
          />
        }
        title="Strengths & Challenges"
        hideTitle
        sectionId="strengths-challenges"
        onSubmit={handleSave}
      >
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Strengths & Challenges
        </Text>
        <InputFilterable
          id="strengths"
          isMulti
          label="Strengths"
          name="strengths"
          defaultValue={formState.person.strengths?.map(strength => ({
            label: STRENGTHS_OPTIONS[strength],
            value: strength,
          }))}
          values={Object.entries(STRENGTHS_OPTIONS).map(([value, label]) => ({
            label,
            value,
          }))}
          fullWidth
        />
        <InputText
          label="Strengths (other)"
          name="strengths-other"
          fullWidth
          value={formState.person.strengthsOther}
        />

        <InputFilterable
          id="challenges"
          isMulti
          label="Challenges"
          name="challenges"
          defaultValue={formState.person.challenges?.map(challenge => ({
            label: CHALLENGES_OPTIONS[challenge],
            value: challenge,
          }))}
          values={Object.entries(CHALLENGES_OPTIONS).map(([value, label]) => ({
            label,
            value,
          }))}
          fullWidth
        />
        <InputText
          label="Challenges (other)"
          name="challenges-other"
          fullWidth
          value={formState.person.challengesOther}
        />
      </SurfaceForm>
    );
  }

  return (
    <Surface
      title="Strengths & Challenges"
      hideTitle
      sectionId="strengths-challenges"
    >
      <Flex justify="space-between" align="center">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Strengths & Challenges
        </Text>
        <Button variant="secondary" onClick={() => setIsEditing(true)}>
          Edit
        </Button>
      </Flex>
      <DetailsTable
        details={[
          {
            label: "Strengths",
            value: formState?.person.strengths
              ?.map(strength => STRENGTHS_OPTIONS[strength] || strength)
              .join(", "),
          },
          {
            label: "Strengths (other)",
            value: formState?.person.strengthsOther,
          },
          {
            label: "Challenges",
            value: formState?.person.challenges
              ?.map(challenge => CHALLENGES_OPTIONS[challenge] || challenge)
              .join(", "),
          },
          {
            label: "Challenges (other)",
            value: formState?.person.challengesOther,
          },
        ]}
      />
    </Surface>
  );
};

export default StrengthsAndChallenges;
